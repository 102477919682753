@import "styles/vars.scss";

.headline {
	margin-bottom: 4px;

	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: var(--main-black);
	opacity: 0.5;

	white-space: nowrap;
}

.buttonsContainer {
	padding: 10px 8px;
	width: 40px;

	background: #fff;
	border-radius: 10px;
}

.over {
	min-width: 72px;
}

.buttonsContainer .button:first-child {
	svg {
		margin: 0 0 4px 0;
	}
}
.buttonsContainer .button:last-child {
	& > div {
		margin-bottom: 0;
	}
	svg {
		margin: 4px 0 0 0;
	}
}

.button {
	padding: 0;
	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
	border: none;

	cursor: pointer;

	svg {
		margin: 4px 0;
	}

	svg,
	path {
		fill: var(--main-blue-secondary);
	}

	&:not(.selected):hover {
		svg,
		path {
			fill: #bfc6f7;
		}
	}
}

.numberButton {
	margin-bottom: 10px;
	&:first-child {
		svg {
			margin: 0;
		}
		margin-bottom: 5px;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.withUpDivider {
	margin-top: 4px;
	padding-top: 8px;

	border-top: 1px solid #e3e9f7;
}

.roundedNumberButton {
	background: var(--main-blue-secondary);
	color: white;
	font-family: $ubuntu-font;
	font-size: 14px;
	font-weight: 500;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	padding: 2px;
	@include flex-align-items-center;
	justify-content: center;
}

.numberButton.buttonAll {
	svg {
		margin-bottom: 0 !important;
	}
}

.buttonAll {
	circle {
		fill: var(--main-blue-secondary);
	}

	path {
		fill: #fff;
	}

	&:hover {
		circle {
			fill: #bfc6f7;
		}

		path {
			fill: #fff !important;
		}
	}
}

.button.allSelected,
.button.allSelected:hover {
	circle {
		fill: var(--main-blue);
	}

	path {
		fill: #fff;
	}
}

.buttonSuccess {
	svg {
		width: 17px;
		height: 17px;
	}
}

.buttonPoop {
	svg {
		width: 24px;
		height: 24px;
	}
}

.buttonFailure {
	svg {
		width: 18px;
		height: 16px;
	}
}

.selected {
	svg,
	path {
		fill: var(--main-blue);
	}
}

.selectedNumber {
	background: var(--main-blue);
}

.applicationsStages {
	width: 100px;
	display: flex;

	padding-top: 10px;

	justify-content: center;
}

.applicationsTypes {
	position: relative;
	top: -10px;
}

.popoverOver {
	min-width: 250px !important;
}

.stageCircle {
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;

	width: 18px;
	min-width: 18px;
	height: 18px;
}

.applicationOverContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;

	span {
		text-align: left;
	}
}
