@import "styles/vars.scss";

.container {
	display: flex;
	flex-direction: row;

	h3 {
		margin: 0 0 20px;

		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		color: var(--main-black);
	}
}

.containerMobile {
	flex-direction: column!important;
	gap: 20px;
	margin-bottom: -30px;
}

.form {
	flex: 11 1;
}

.list {
	flex: 14 1;
}

.listContainer {
	padding: 0px 8px 20px 12px;
	width: 500px;
	border-radius: 10px;
	background: var(--BW-4, #F2F2F2);
	height: 362px;
	margin-left: -20px;

	@include mbDesktopBreakpoint {
		height: 300px!important;
		border-radius: 10px 10px 0 0;
	}
}

.noContacts {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	height: 100%;

	p {
		margin: 0 auto;
		max-width: 376px;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		color: #ADB4D6;
		font-style: normal;
		letter-spacing: 0.2px;
	}
}

.btnContainer {
	margin-top: 36px;
	border-radius: 0 0 20px 20px ;
	display: flex;
	justify-content: flex-end;
	height: 64px;
	padding: 12px 28px 12px 823px;
	margin-bottom: -24px;
	width: 1000px;
	position: relative;
	left: -32px;
	box-shadow: 0 10px 40px 0 rgba(0, 64, 229, 0.30);
}

.btnContainerMobile {
	height: 64px!important;
	width: 500px!important;
	padding: 12px 28px 12px 400px!important;
	margin-bottom: 0!important;
	left: -20px!important;
	bottom: -30px!important;
	border-radius: 0!important;
	margin-top: unset!important;
	background: white;
}
