@import "styles/vars.scss";

.wrapper {
	padding: 40px 20px 0;

	@include mbDesktopBreakpoint() {
		padding: 40px 0 0 0 !important;
	}
}

.creator {
	margin-top: 80px;
}

.pre_applications {
	padding: 0;
}
// обращение к  cardPerson
.pre_applications ul li div div:first-child{
	max-width: 100%;
}