.container {
	position: absolute;
	top: 0;
	left: -45px;
	z-index: 99;

	width: 45px;
}

.buttonsContainer {
	display: flex;
	flex-direction: column;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.buttonItem {
	padding: 0 14px;
	margin: -14px 0;

	background: linear-gradient(270deg, #fcfcfc -13.33%, #ffffff 100%);

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	&:first-child {
		border-top-left-radius: 20px;
	}
	&:last-child {
		border-bottom-left-radius: 20px;
	}

	font-family: Cera Pro;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: var(--main-blue-secondary);

	svg {
		fill: var(--main-blue-secondary);
	}
}

.buttonItemActive {
	z-index: 11;
	background: #fff;
	color: #1E1E1F;

	box-shadow: 0px 0px 35px 0px #124BF659;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;


	svg {
		path {
			fill: #1E1E1F;
		}
		fill: #1E1E1F;
	}
}

.buttonItemDisabled {
	background: #f1f1f1;
	color: #ddd;
	cursor: not-allowed;
}

.buttonText {
	transform: rotate(-90deg);
	white-space: nowrap;

	svg {
		transform: rotate(90deg) translate(8px, -3px);
	}
}
