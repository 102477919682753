@import "styles/vars.scss";

.root {
	background-color: #fff;
	border-radius: 10px;
	padding: 12px 18px 10px 24px;

	font-size: 14px;
	line-height: 20px;

	&:hover {
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	}

	&.clickable {
		cursor: pointer;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	color: var(--main-black);
	font-weight: 500;
}

.statusIcon {
	width: 24px;
}

.date,
.contacts {
	font-family: $ubuntu-font;
	color: var(--main-grey-disabled);
}
