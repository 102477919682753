@import "styles/vars.scss";

.errors {
	color: var(--accent-red);
	font-size: 14px;

	ol {
		margin-left: 4px;
	}
}

.title {
	font-family: $ubuntu-font;
	font-weight: 500;
	font-size: 18px;

	display: flex;
	align-items: flex-end;
}

.icon {
	margin-right: 8px;

	path {
		fill: var(--accent-red);
	}
}
