.generatedLink {
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		margin-right: 24px;

		font-weight: 300;
	}

	button {
		margin-left: 16px;
	}
}

.rootP {
	margin-top: 32px !important;
	margin-bottom: 12px !important;
}
