.root {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.fio {
	display: flex;
	flex-direction: column;

	font-weight: bold;
	color: var(--main-black);

	.lastName {
		display: flex;

		font-size: 30px;
		line-height: 36px;
	}

	.initials {
		font-size: 20px;
		line-height: 24px;
	}
}

.edit {
	padding: 4px;

	display: flex;
	align-items: center;

	opacity: 0.5;
	transition: 0.2s;

	cursor: pointer;

	svg {
		margin-left: 16px;

		fill: var(--main-blue);
	}

	&:hover {
		opacity: 1;
	}
}

.id {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
	opacity: 0.5;
	white-space: nowrap;
}

.left {
	display: flex;
	align-items: center;
	column-gap: 16px;
}
