.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 20px 20px 40px 20px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    path {
      fill: #FFFFFF!important;
    }
  }
}

.heading {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}


.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 354px;

  form.buttonsContainer {
    row-gap: unset!important;
  }

}
.wrap h1 {
  margin-bottom: 8px;
}
.errorMessage {
  width:100%;
  margin: 4px 0 0;
  text-align: right;;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  color:#FF575F;
}
.message {
  color:#2D2C46;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  margin-bottom: 40px;
  margin-top: 60px;
}