@import "styles/vars.scss";

button.root {
	padding: 20px 114px !important;


	border-radius: 4px !important;
	background: #F2F2F2 !important;
	color: #BFBFC0 !important;
	height: 96px !important;

	svg {
		margin-right: 8px;
		fill: #BFBFC0 !important;
		opacity: 0.5;

		path {
			fill: #BFBFC0 !important;
		}
	}

	&:hover {
		background-color: #BFBFC0 !important;
		color: #fff !important;

		svg {
			path {
				fill: #fff !important;
			}
		}
	}
}
