.input {
	&::placeholder {
		color: var(--main-blue-secondary) !important;
		font-weight: 500 !important;
		font-family: Cera Pro !important;
	}
}

.notSelectedOption {
	white-space: unset !important;
}
