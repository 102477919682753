@import "styles/vars.scss";

.root {
	position: relative;

	padding-left: $sidebar-closed-width;
	display: flex;
	flex-direction: column;
}

.expanded.root {
	padding-left: $sidebar-opened-width;
}

.firstLine {
	display: flex;

	height: 50px;

	background: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.filterWrapper {
	padding-right: 20px;
	padding-left: 17px;

	height: 100%;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	border-right: 1px solid var(--main-blue-inactive);
}

.filterBtn {
	color: var(--main-black);
	display: flex;
	align-items: center;

	svg {
		width: 25px !important;
		height: 25px !important;
		margin-right: 14px !important;
	}

	path {
		fill: var(--main-black) !important;
	}

	.filterBtnText {
		color: var(--main-blue);
		font-size: 12px;
	}
}

.submitWrapper {
	position: absolute;
	right: 412px;
	bottom: -58px;
}

.responsibleWrapper,
.statusWrapper {
	width: 255px;
}

.sourceWrapper {
	width: 300px;

	z-index: 1;
}

.periodWrapper {
	width: 250px;
}

.periodWrapper {
	padding-top: 8px;
	padding-left: 0;
	padding-right: 0;
}

.periodLabelWrapper {
	white-space: nowrap;
}

.periodPopupWrapper {
	top: 6px;
	position: relative;

	height: 100%;
	width: 100%;
}

.resetBtn {
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}
