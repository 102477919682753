@import "styles/vars.scss";

.root {
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 480px !important;
  }
}

.title {
  margin-top: 36px;
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.subtitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.submitBtns {
  margin: 16px 30px 24px;

  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
}

.inforowContent {
  width: 200px;
}

.selected {
  border: none !important;
  width: 200px;
}


.calendar {
  position: relative;
  margin-left: 25px;
  z-index: 10;
  transform: translateX(-24px);
}

