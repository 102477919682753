@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	flex-direction: column;

	height: 100%;
	width: 100%;

	max-height: 294px;
}

.title {
	font-weight: 400;
	font-size: 14px;
	color: var(--main-black);
}

.wrapper {
	margin-top: 8px;
	padding: 12px 8px 12px 0;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	height: calc(100% - 26px); // marginTop + title height
	width: 100%;
}

.body {
	margin: 0;
	padding: 0 10px 0 12px;

	list-style-type: none;
	overflow: auto;

	width: 100%;
	height: 100%;
}

.task {
	margin-top: 9px;
	&:first-child {
		margin-top: 0;
	}
	display: flex;
	align-items: baseline;

	padding-bottom: 11px;

	border-bottom: 1px solid rgba(var(--main-blue-secondary-rgb), 0.2);
}

.date {
	margin-right: 18px;

	font-size: 14px;
	color: var(--main-grey-disabled);
}

.info {
	& > div {
		color: var(--main-black);

		&:first-child {
			font-family: $ubuntu-font;
			font-size: 14px;
			line-height: 20px;
		}

		&:last-child {
			opacity: 0.7;
			font-size: 12px;
			line-height: 16px;
		}
	}
}

.noTasks {
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: $ubuntu-font;
	font-size: 12px;
	color: var(--main-grey-disabled);
}

.loading {
	display: flex;
	align-items: center;
	// justify-content: center;
}

.loaderContainer {
	width: 20px;

	svg {
		width: 15px !important;
		height: 15px !important;
	}
}
