@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 14px;
	color: var(--main-black);
	& > div {
		margin-bottom: 16px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.title {
	font-weight: 500;
	opacity: 0.5;
}

.info {
	@include flex-align-items-center;
	font-family: $ubuntu-font;
}

.manager {
	margin-left: 8px;
	& > div {
		&:first-child {
			font-size: 16px;
		}
	}
}
