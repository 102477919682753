.customInfoRow {
	padding: 10px 0 5px 12px;
}

.priceWithCurrency {
	display: flex;
	justify-content: space-between;
}

.priceInput {
	width: 50%;
}

.priceForUnit input {
	cursor: default;
}
