@import "styles/vars.scss";

.root {
  width: 720px;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 0px #2224281A;
  cursor: pointer;
  border-radius: 4px;
  display: flex;

  @include mbDesktopBreakpoint {
    width: 476px !important;
    height: 96px !important;
  }
}

.row {
  display: flex;
  align-items: center;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 100px;
  background: #F2F2F2;

  @include mbDesktopBreakpoint {
    width: 96px !important;
    height: 96px !important;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 586px;
  margin-left: 12px;
  margin-right: 12px;
  @include mbDesktopBreakpoint {
    width: 332px !important;
  }
}

.stage {
  width: 26px;
  height: 100px;
  border-left: 1px solid #BFBFC0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mbDesktopBreakpoint {
    height: 96px !important;
  }
}

.text {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;

}

.tooltipRoot {
  margin: 0 0 0 4px !important;
}

.textTooltip {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #FFFFFF;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  background: #3F3F40 !important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 6px !important;
  }
}