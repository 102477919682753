@import "styles/vars.scss";

.buttonBlock {
  height: 64px;
  position: sticky;
  align-items: center;
  width: 716px;
  border-radius: 0 0 12px 12px;
  margin-bottom: -20px;
  padding-right: 40px;
  //box-shadow: 0 -8px 10px 0 rgba(0, 64, 229, 0.10);
  box-shadow: 0px -8px 10px 0px #2224281A;
  background: #F2F2F2;
  margin-top: -32px;

  display: flex;
  justify-content: end;
  gap: 12px;

  @include mbDesktopBreakpoint {
    height: 84px!important;
    width: 460px!important;
  }

  svg {
    opacity: 1;
  }
}

.actions {
  width: 143px;

  @include mbDesktopBreakpoint {
    width: 63px;
  }
}

.deleteActionItem {
  color: #FF575F !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;

  svg {
    margin-right: 12px;
    fill: #FF575F !important;
  }
}

.actionItem {
  margin: 12px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  color: var(--main-blue);

  svg {
    margin-right: 12px;
    fill: var(--main-blue);
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.arrow {
  fill: var(--main-blue) !important;
  transform: rotate(180deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;

  }
}

.popupCard {
  min-width: 300px !important;
}

.openTrigger {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--main-blue);
}