@import "styles/vars.scss";

.root {
	display: flex;
	height: 100px;
}

.img {
	@include flex-align-items-center;

	border-radius: 20px;
	background: #fff;
}

.body {
	@extend .img;

	align-items: flex-start;
}

.img {
	padding: 20px 37px 32px 24px;
	min-width: 210px;

	position: relative;
}

.body {
	margin-left: 10px;
	padding: 14px 12px 14px 56px;

	justify-content: space-between;

	width: 100%;

	@include mbDesktopBreakpoint {
		flex-flow: column;
		margin-left: 0!important;
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: flex-start;

	& > button {
		margin-bottom: 4px;
	}
}

.link {
	position: absolute;
	top: 16px;
	right: 20px;

	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;
		path {
			fill: var(--main-blue);
		}
	}
}

.editButton {
	svg {
		opacity: 1 !important;
		width: 16px !important;
		height: 16px !important;
	}
}

.popupWrapperContainer {
	padding: 20px !important;
	width: fit-content !important;
}

.iconWrapper {
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	margin-right: 8px;
	position: relative;

	> svg {
		position: absolute; /* set position property to absolute */
		top: 50%; /* align the top of the child div to the middle of the parent div */
		left: 50%; /* align the left of the child div to the middle of the parent div */
		transform: translate(-50%, -50%); /* move the child div up and left by half of its own width and height respectively */
	}
	//display: flex;
	//align-items: center;
	//justify-content: center;
}

.cianButton {
	white-space: normal !important;
	text-align: left !important;
	width: 205px;
	align-items: flex-start !important;

	& > div > svg {
		min-width: 20px !important;
		min-height: 20px !important;
		width: 20px !important;
		height: 20px !important;
		opacity: 1 !important;
	}

	&.enabled {
		color: var(--accent-green);

		> div > svg * {
			stroke: var(--accent-green);
		}
	}

	&.disabled {
		color: #9696A2;
	}
}
