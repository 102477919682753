.customInfoRow {
	padding: 10px 0 5px 12px;
	position: relative;
}

.calendarContainer {
	position: relative;
	z-index: 100;
	transform: translateX(-24px);
}
