@import "styles/vars.scss";

.heading {
	margin: 20px 0 10px !important;
}

.modalClose {
	background: #1E1E1F!important;
	border: 0!important;
	svg {
		path {
			fill: #FFFFFF!important;
		}

		@include mbDesktopBreakpoint {
			left: -3px!important;
		}
	}
}

.contentWrapperClassNameMobile {
	position: absolute;
	bottom: 0;
	max-height: 100vh!important;
	width: 500px!important;
	padding: 20px 20px 40px 20px!important;
	border-radius: 20px 20px 0px 0px!important;
}
