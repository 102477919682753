@import "styles/vars.scss";

.container {
	width: 100%;

	display: flex;
	flex-direction: row;
}

.contentContainer {
	width: 100%;
	position: relative;
}

.content {
	padding: 30px 40px;
	width: 100%;

	@include mbDesktopBreakpoint {
padding: 30px!important;
	}
}
