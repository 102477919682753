@import "styles/vars.scss";

.root {
	padding: 25px 40px 0;

	min-height: 100vh;

	@include mbDesktopBreakpoint {
		padding: 25px 10px 0!important;
	}

	h2 {
		margin: 0 0 28px;

		font-weight: bold;
		font-size: 26px;
		line-height: 31px;
		color: var(--main-black);
	}
}
