@import "styles/vars.scss";

.wrap {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.block {
	display: flex;
	flex-direction: row;
	gap: 47px;
}
h1 {
	color: #2D2C46;
	font-family: Cera Pro;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
	letter-spacing: 0.2px;
	padding: 0;
	margin: 0;
	margin-bottom:4px;
}
p {
	padding: 0;
	margin: 0;
	color: #2D2C46;
	font-family: Ubuntu;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.2px;
}
.button {
	align-self: flex-end;
}

.error {
	top: 10px
}

.row {
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 8px;
	font-family: Cera Pro;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;

}

.addressSelect {
	border: 0!important;
}


.modal {
	position: absolute;
	bottom: 0;
	width: 500px!important;
	padding: 20px 20px 40px 20px!important;
	border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
	background: #1E1E1F!important;
	border: 0!important;
	svg {
		path {
			fill: #FFFFFF!important;
		}
	}
}