@import "styles/vars.scss";

.root {
	@include flex-align-items-center;

	color: var(--main-grey-light);
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	svg {
		margin-right: 8px;

		width: 20px;
		height: 20px;
		fill: var(--main-grey-light);
	}
}
