@import "styles/vars.scss";

.title {
	display: inline-flex;
	align-items: center;
}

.noResponsible {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
}

.openTrigger {
	color: var(--main-blue);
	cursor: pointer;
}

.popupContainer {
	position: relative;
	z-index: 100;
}

.noResponsible {
	color: var(--main-black);
	opacity: 0.7;
}

.block {
	padding: 12px 16px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 10px;
	background: var(--main-blue-disabled);
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.responsiblePhone {
	font-family: $ubuntu-font;
	color: var(--main-black);
	font-weight: 400;
}

.trashIcon {
	position: relative;
	left: 10px;

	path {
		opacity: 0.5;
		transition: 0.2s;
	}
}

.trashIcon:hover {
	cursor: pointer;

	path {
		opacity: 1;
		fill: var(--main-blue);
	}
}

.popover {
	background: white;
	border-radius: 50%;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	max-width: 10px;
	max-height: 10px;
	font-size: 14px;
	line-height: 16px;
}

.overElement {
	display: flex;
	flex-direction: column;

	span {
		margin-bottom: 16px;
	}

	:last-child {
		margin-bottom: 0px;
	}
}

.departmentsTooltipCard {
	top: 100% !important;
	left: 50% !important;
	transform: translate(-35%, 5px) !important;

	padding: 8px !important;

	min-width: 180px;
}

.departmentsTooltipContent {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.departmentsTooltipLabelHovered {
	display: none;
}
