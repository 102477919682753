.root {
	padding: 16px 24px;
	border-radius: 10px;

	label {
		padding: 6px 16px !important;
		line-height: 20px;
	}
}

.question {
	margin-bottom: 12px;

	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
}

.content {
	margin-top: 16px;
}
