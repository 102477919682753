
.modalClose {
  background: #1E1E1F !important;
  border: 0 !important;

  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF !important;
    }
  }
}

.contentWrapperClassNameMobile {
  position: absolute;
  bottom: 0;
  max-height: 100vh !important;
  width: 500px !important;
  padding: 20px 20px 40px 20px !important;
  border-radius: 20px 20px 0px 0px !important;
  overflow-y: unset!important;
}

.wrapper {
  overflow-y: unset!important;
}
