.optionsWrapper {
	position: absolute;
	z-index: 100;
	left: -16px;
	top: calc(100% + 4px);

	padding: 12px 8px 12px 16px;

	animation-duration: 0.1s !important;

	border-radius: 20px;
	background-color: #fff;
	box-shadow: 0 10px 30px rgb(0 64 230 / 30%);

	div {
		word-wrap: break-word;
		font-family: "Ubuntu", sans-serif;
	}
}

.optionsWrapper.size__small {
	padding: 8px 6px 8px 12px;

	left: -12px;
}

.openToTop {
	top: unset;
	bottom: calc(100% + 4px);
}

.noChoice {
	font-size: 14px;
	color: var(--main-black);
	opacity: 0.5;
}
