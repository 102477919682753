.root {
  width: 720px;
  height: 400px;
  border-radius: 20px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;

}

.content {
  max-height: 340px;
  background: #F2F5FB;
  display: flex;
  flex-flow: column;
  gap: 12px;
  padding: 0 10px 10px;
  overflow: auto;

}

.wrapper {
  padding: 5px 10px;
}

.messageIn {
  background: #E3E9F6;
  max-width: 360px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;
padding: 8px 20px;
  border-radius: 10px;
}

.messageOut {
  background: #FFFFFF!important;
  max-width: 360px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;
  padding: 8px 20px;
  border-radius: 10px;
}

.time {
  text-align: end;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  color: #AFAFAF;
}

.textArea {
  width: 720px;
  min-height: 48px;
  border-radius: 0px 0px 20px 20px!important;
  border: 1px solid #CCCCCC!important;
  box-shadow: 0px 10px 30px 0px #124BF64D;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}


.saveText {

  position: absolute;
  right: 17px;
  bottom: 15px;
  cursor: pointer;
  transition: 0.1s;
  fill: #336BEB;

}

.avatar {
  height: 24px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

}

.contact {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
margin-top: 4px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
}