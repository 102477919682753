.contentWrapperClassName {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.modal {
	position: absolute;
	bottom: 0px;
	width: 500px!important;
	padding: 10px 10px 0px 10px!important;
	border-radius: 20px 20px 0px 0px!important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.modalClose {
	background: #1E1E1F!important;
	border: 0!important;
	svg {
		left: -3px!important;
		path {
			fill: #FFFFFF!important;
		}
	}
}
