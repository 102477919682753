@import "styles/vars.scss";

.root {

}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 60px;
  margin-bottom: -30px;

  @include mbDesktopBreakpoint {
    margin-top: 40px!important;
  }
}