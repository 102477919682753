.phoneItem {
	display: flex;

	column-gap: 0.5rem;

	justify-content: space-between;
	align-items: center;

	p {
		white-space: nowrap;
	}
}

.eyeBtn {
	width: 25px;
	height: 18px;

	cursor: pointer;

	path,
	line {
		fill: var(--main-blue-secondary);
		stroke: var(--main-blue-secondary);
	}
}
