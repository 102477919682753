.button {
	padding: 3px;

	display: flex;
	align-items: center;

	background: var(--main-blue);

	border-radius: 100px;
	border: none;

	cursor: pointer;
}

.buttonContent {
	margin: 0 24px 0 16px;

	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	color: #fff;
}
