@import "styles/vars.scss";

.root {
	color: var(--main-blue);
	cursor: pointer;
}

.coworking {
	color: black!important;
	cursor: initial;
}
