@import "styles/vars.scss";

.tabs {
	display: flex;
	align-items: center;
	justify-content: center;

	@include mbDesktopBreakpoint {
		width: 490px;
	}
}

.tabsUnset {
	@include mbDesktopBreakpoint {
		width: unset!important;
	}
}

.root {
	display: flex;
	justify-content: center;
}

.bottomLine {
	span {
		height: 6px;
		background: #FFF!important;
	}
}


.tabsRoot {
	overflow-x: auto!important;

	&::-webkit-scrollbar {
		height: 7px;
	}

	&::-webkit-scrollbar-thumb {
		background: #E5E5E5;
	}

	&::-webkit-scrollbar-button:end:increment {
		width: 0%;
		display: block;
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		background:  linear-gradient(
										to top,
										rgba(0, 0, 0, 0) 0%,
										rgba(0, 0, 0, 0) 30%,
										#E5E5E5 50%,
										rgba(0, 0, 0, 0) 70%,
										rgba(0, 0, 0, 0) 100%
		);;
		border-radius: 0;
		margin-left: 40px;
		margin-right: 40px;
	}
}

