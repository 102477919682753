@import "styles/vars.scss";

.root {
  height: 50px;
  align-items: center;
  display: flex;
  gap: 32px;
  background: #FF575F;
  color: #FFFFFF;
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  position: fixed;
  z-index: 102;
  left: 0;
  right: 0;
  justify-content: center;

  @include mbDesktopBreakpoint {
    padding: 0 10px!important;
  }
}

.button {
  height: 34px;
  width: 262px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 12px 5px 12px;
  border-radius: 841px;
  background: #FFFFFF;
  color: black;
  cursor: pointer;

  @include mbDesktopBreakpoint {
    width: 250px!important;
    padding: 5px 0 5px 12px!important;
  }

  svg {
    path {
      fill: black;
    }
  }
}