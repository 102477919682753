@import "styles/vars.scss";

$padding: 18px;

.header {
	margin-top: 24px;
}

.blockRoot {
	position: relative;
}

.block {
	margin-top: 8px;
	padding: $padding;

	border-radius: 10px;

	background: rgba(198, 203, 217, 0.4);
	backdrop-filter: blur(5px);

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
}

.notification {
	opacity: 60%;
}

.fakeContact {
	position: absolute;

	display: flex;
	align-items: center;

	padding: $padding;

	width: 100%;
	height: 100%;

	color: var(--main-blue);
	background: var(--main-blue-disabled);

	border-radius: 10px;

	font-size: 14px;
	line-height: 16px;
}
