@import "styles/vars.scss";

.root {
	margin-top: 24px !important;

	h5 {
		margin: 0 !important;
	}
}

.block {
	margin-bottom: 12px;

	padding: 12px 16px;
	display: flex;

	background: var(--main-blue-disabled);
	border-radius: 10px;

	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);

	p {
		margin: 0;
		opacity: 0.9;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.fio {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.phone {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
}

.tooltipIcon {
	margin: 0 !important;
}

.tooltipCard {
	top: unset !important;
	left: 50% !important;
	bottom: calc(100% + 8px);

	transform: translateX(-50%);

	svg {
		display: none;
	}
}

.pinRoot {
	margin-top: -12px;
}

.phoneBlockContainer {
	.phoneBlockButtonContainer {
		display: flex;
		justify-content: flex-end;

		button {
			padding: 4px 0;
		}
	}
}

.phoneBlock {
	flex-direction: column;

	cursor: pointer;

	transition: 0.2s;

	&:hover {
		background: #fff;
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
	}
}

.telegramBlock,
.instagramBlock {
	color: var(--main-blue);

	svg {
		margin-right: 8px;
	}
}

.telegramBlock {
	path {
		fill: var(--main-blue);
	}
}

.hidden {
	position: relative;
}

.blur {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;

	background: rgba(198, 203, 217, 0.4);
	backdrop-filter: blur(5px);
	border-radius: 10px;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	color: #757689;
}
