@import "styles/vars.scss";

.wrapper {
  width: 720px;
}

.isMobile {
  width: 440px !important;
}

.empty {
  text-align: center;
  margin-bottom: 8px;
  width: 720px;
  height: 100px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #BFBFC0;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;

  @include mbDesktopBreakpoint {
    width: 440px !important;
    height: 96px !important;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--main-black);

  display: flex;
  align-items: center;

  svg,
  path {
    margin-right: 12px;
    fill: var(--main-blue-secondary);
  }
}

.row {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;

  >:hover {
    box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
  }
}

.col {
  border-radius: 10px;
}

.cart {
  display: flex;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #7F8DEF !important;

  svg,
  path {
    margin-right: 4px;
  }
}

.row {
  padding: 0 !important;
}

.cancelIcon,
.successIcon {
  width: 24px;
  height: 24px;
}

.timerIcon {
  width: 24px;
  height: 29px;
}

.alertIcon {
  width: 26px;
  height: 23px;
}

.inWorkIcon {
  width: 23px;
  height: 25px;
}

.poopIcon {
  width: 23px;
  height: 27px;
  transform: scale(1.5);
}
