@import "styles/vars.scss";

.root {
	margin-top: 80px;
}

.rootWithMarginBottom {
	margin-bottom: 60px;
}

.wrapper {
	margin-top: 20px;
	padding: 0;

	form {
		h2 {
			display: none;
		}
	}
}

.buttons {
	@include flex-align-items-center;
	justify-content: space-between;
	column-gap: 16px;

	button {
		flex: 1;
		justify-content: center;

		padding: 20px 0 !important;

		width: 352px;

		svg {
			margin-right: 8px;
		}
	}
}

.object {
	@include flex-align-items-center;

	@include mbDesktopBreakpoint {
		margin-right: 13px!important;
	}

}

.close {
	margin-left: 12px;
	padding: 5px;

	cursor: pointer;
	svg {
		fill: var(--main-blue-secondary);

		&:hover {
			fill: var(--accent-red);
		}
	}
}

.owner {
	padding-right: 0!important;
}