.list li {
  margin-bottom: 8px;
}
.list li div {

}
.selected {
  border-radius: 4px;
  outline: 2px solid #7F8DEF;
  background-color: white;
}

.listContainer {
  overflow-y: auto;
  height: 362px;
  padding: 20px 10px 2px 20px;
}