@import "styles/vars.scss";

.wrapper {
	width: 720px;
}

.mobile {
	width: 440px!important;
}

.empty {
	text-align: center;
	margin-bottom: 8px;
	width: 720px;
	height: 100px;
	background: #FFFFFF;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #BFBFC0;
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.20000000298023224px;

	@include mbDesktopBreakpoint {
		width: 440px!important;
		height: 96px!important;
	}
}

.counts {
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
	opacity: 0.5;
}
