@import "styles/vars.scss";

.root {
  padding: 0 9px !important;

  @include mbDesktopBreakpoint {
    padding: 0 2px !important;
  }
}

.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 10px 10px 0px 10px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;

  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF!important;
    }
  }
}

.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  margin-top: 32px;

  @include mbDesktopBreakpoint {
    margin-bottom: 32px;
  }
}

.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-bottom: 32px;
  margin-top: 4px;
}

.title {
  color: #2D2C46;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}

.infoRow {
  padding: 0 10px 4px;

  min-height: 0;
}

.calendarChild {
  & > div {
    &:last-child {
      margin-left: -46px;
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarLabel {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 20px;

    path {
      fill: var(--main-blue-secondary);
    }
  }
}

.timeError {
  margin-left: -25px!important;
}

.calendar {
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popupTrigger {
  padding: 12px 24px !important;
}

.timeRoot {
  margin-left: 21px;
}

.timeWrapper {
  border: none;
  padding: 0;
}