.objectType {
	display: flex;
	flex-direction: column;

	width: auto;
}

.popupRoot {
	min-height: 27px;
	align-items: center;
	display: flex;
}

.typeWrapper {
	max-width: 170px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--BW-0, #1E1E1F);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 16.8px */
	letter-spacing: 0.2px;
}

.objectTypeCheckboxes {
	padding: 13px 0 5px 0;
	width: 100%;
}

.light {
	color: var(--main-blue-secondary);
}

.popupCard {
	left: -24px !important;
	max-width: 260px !important;
	min-width: 260px !important;
}

.popupCardMobile {
	left: -90px!important;
	max-width: 260px !important;
	min-width: 260px !important;
}
