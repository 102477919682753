.root {
	margin-top: 24px;

	font-size: 12px;
	line-height: 20px;

	width: 0;
	overflow: visible;
}

.content {
	position: sticky;
	top: 0;

	display: flex;
	flex-direction: column;
	row-gap: 4px;

	padding-top: 100px;
}

.checkbox {
	display: none;
}

.checkboxLabel {
	margin-left: 0;
	font-weight: 500;
	font-size: inherit;
}
