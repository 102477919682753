@import "styles/vars.scss";

.wrapper {
	@include flex-align-items-center;
	justify-content: space-between;

	padding: 8px 15px;

	border: 1px solid var(--main-blue-secondary);
	border-radius: 100px;
	background-color: #fff;
}

.dateInputField {
	input {
		font-weight: 400;
		&::placeholder {
			font-weight: 400;
		}
	}
}

.dateInputIcon {
	display: none;
	width: 25px;
}

.time {
	& > div {
		border: none;
		padding: 0;
	}
}

.timeInput {
	margin-top: 2px;
}

.error {
	margin-top: 8px;

	color: var(--accent-red);
	font-size: 12px;
}
