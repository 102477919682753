.cian {
	width: 48px;
	height: 16px;

	transform: translateY(-2px);
}

.avito {
	width: 43px;
	height: 12px;
}

.yandex {
	width: 69px;
	height: 13px;
}

.source {
	opacity: 0.7;
}
