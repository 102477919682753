.root {
	h1 {
		text-align: center;
		margin-bottom: 6px;
	}

	p {
		margin: 0 0 26px;
		text-align: center;
	}
}

.form {
	margin: 0 auto 78px;
	max-width: 205px;
}

.customPrepend {
	padding-left: 50%;

	&::placeholder {
		font-size: 16px;
	}
}

.codePrepend {
	font-weight: bold;
	font-size: 20px;
	color: var(--main-black);
	margin-left: 35px;

	.codePrependEmpty {
		font-weight: normal;
		color: var(--main-grey-light);
	}
}
