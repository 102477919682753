.publicLink {
	display: flex;

	cursor: pointer;

	svg {
		margin-left: 8px;
	}

	path {
		fill: var(--main-blue);
	}
}
