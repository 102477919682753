.groupRadio {
	display: inline-flex;
	flex-wrap: wrap;
	//width: auto;
	border-radius: 15px;
}

.groupRadioItem {
	margin: 0 4px;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

.background_white {
	&:not(.noBgColor) {
		background-color: #fff;
	}

	&.groupRadio label {
		color: var(--main-grey-disabled);

		&.checked {
			color: #fff;
		}

		&.unchecked {
			color: var(--main-black);
			background-color: #fff;
		}
	}
}
.background_gray {
	&:not(.noBgColor) {
		background-color: #F2F2F2;
	}

	&.groupRadio label {
		color: var(--main-grey-disabled);

		&.checked {
			color: #fff;
		}

		&.unchecked {
			color: var(--main-black);
			background-color: #fff;
		}
	}
}
.background_blue {
	&:not(.noBgColor) {
		background-color: var(--main-blue-disabled);
	}

	&.groupRadio label {
		color: var(--main-grey-sub);

		&.checked {
			color: #fff;
		}

		&.unchecked {
			background-color: var(--main-blue-disabled);
		}
	}
}

.background_white,
.background_blue {
	&.groupRadio label {
		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}

.text_blue {
	&.groupRadio label {
		color: var(--main-blue);
	}
}

.groupRadio input[type="radio"] {
	display: none;
}
.groupRadio label {
	padding: 6px 12px;
	transition: background-color 0.2s ease, color 0.2s ease;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 14px;
	line-height: 17px;
	font-weight: 500;
	border-radius: 15px;

	&.checked {
		background-color: var(--Primary-Basic, #336BEB);
		color: var(--BW-5, #FFF);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		letter-spacing: 0.2px;
	}
	&.notActive {
		color: var(--BW-0, #1E1E1F);
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		letter-spacing: 0.2px;
	}
}

label.radioError {
	padding: 5px 11px;
	border: 1px solid var(--accent-red);
}

.size_small {
	label {
		padding: 2px 8px;
		line-height: 20px;
	}
}

.br {
	margin: 0 100% 0 auto !important;

	height: 0;
	visibility: hidden;
}

.disabled {
	label {
		&:not(.checked) {
			background-color: #E3E9F7 !important;
			pointer-events: visiblePainted;
			color: white !important;
		}
	}
}

.disabledOptional {
	label {
		&:not(.checked) {
			background-color: #FFFFFF !important;
			pointer-events: visiblePainted;
			color: #BFBFC0 !important;
		}
	}
}

.checkedOptional {
	background-color: #ABC2F7 !important;
	pointer-events: visiblePainted;
	color: #FFFFFF !important;

	svg {
		path {
			fill: #F2F2F2!important;
		}
	}
}

.icon {
	svg {
		width: 18px;
		height: 18px;
	}
}
.buttons {
	background: white!important;
}
.buttonsBlue {
	background: #F2F2F2!important;
}
