@import "styles/vars.scss";

.root {
	width: 40px;
	height: 40px;

	.noImg,
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	img {
		object-fit: cover;
	}

	.noImg {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $ubuntu-font;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: #ffffff;

		&.bg__primary {
			background: var(--main-blue);
		}
		&.bg__secondary {
			background: var(--main-grey-disabled);
		}
	}
}

.mini {
	width: 24px;
	height: 24px;

	.noImg {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: $ubuntu-font;
		font-weight: 400;
		font-size: 12px;
		color: #ffffff;

		&.bg__primary {
			background: var(--main-blue);
		}
		&.bg__secondary {
			background: var(--main-grey-disabled);
		}
	}
}

.initialOneLetter {
	padding-left: 1px;
}

.premium {
	border: 2px var(--main-blue) solid;
	border-radius: 50%;

	svg {
		z-index: 2;
	}
}

.logo {
	position: absolute;
	top: 27px;
	left: -3px;

}

.loading {
	.noImg {
		background: var(--main-blue-inactive);
	}
}

.noInitialsIcon path {
	fill: var(--main-blue-disabled);
}
