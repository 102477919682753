@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;

	width: 400px;
	height: 45px;

	@include mbDesktopBreakpoint {
		width: 250px!important;
	}
}
