@import "styles/vars.scss";

.root {
	margin-top: 60px;

	@include mbDesktopBreakpoint {
		margin-top: 40px!important;
	}
}

.content {
	position: relative;
}

.menu {
	margin-top: 16px;
	margin-bottom: 24px;
}

.buttons {
	@include flex-align-items-center;
	justify-content: flex-end;

	margin-top: 8px;

	& > button {
		&:last-child {
			margin-left: 24px;
		}
	}
}

.task {
	margin-top: 12px;

	border-radius: 10px !important;

	& > div {
		background-color: transparent;
		&:first-child {
			margin-top: 0;
		}
	}
}

.noTasks {
	margin-top: 20px;

	font-family: $ubuntu-font;
	font-size: 16px;
	font-weight: normal;
	line-height: 24px;
	color: var(--main-grey-disabled);
}


.rowBetween {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.row {
	display: flex;
	align-items: center;
}

.title {
	color: var(--BW-0, #1E1E1F);
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: 36px; /* 138.462% */
	letter-spacing: 0.2px;
}

.link {
	color: var(--Primary-Basic, #336BEB);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
	letter-spacing: 0.2px;
	cursor: pointer;
}