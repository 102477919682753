@import "./keyframes";

// Default values
$default-font: "Cera Pro", sans-serif;
$ubuntu-font: "Ubuntu", sans-serif;
$default-transition-duration: 0.1s;
$default-box-shadow: 0 10px 30px rgba(0, 64, 230, 0.3);

// Sidebar
$sidebar-opened-width: 220px;
$sidebar-closed-width: 70px;

@mixin button-no-style {
	text-align: left;
	background-color: inherit;
	border: none;
	outline: none;
	user-select: none;
}

@mixin flex-align-items-center {
	display: flex;
	align-items: center;
}

@mixin flex-justify-content-center {
	display: flex;
	justify-content: center;
}

@mixin mdDesktopBreakpoint() {
	@media (max-width: 1600px) {
		@content;
	}
}

@mixin mbDesktopBreakpoint() {
	@media (max-width: 500px) {
		@content;
	}
}

@mixin mbVerticalDesktopBreakpoint() {
	@media (max-height: 600px) {
		@content
	}
}

@mixin lgDesktopBreakpoint() {
	@media (max-width: 1920px) {
		@content;
	}
}

@mixin skeleton() {
	border-radius: 10px;
	background: linear-gradient(90deg, #FFF 28.73%, rgba(255, 255, 255, 0.40) 70.8%, #FFF 99.89%);

	animation: skeletonAnimation 1.5s ease-in-out 0.5s infinite;
}
