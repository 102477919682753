.buttons {
	padding: 0 0 30px 0;

	display: flex;
}

.button {
	margin-right: 12px;
	padding: 5px 16px !important;

	height: 30px !important;

	font-weight: normal !important;
}
