@import "styles/vars.scss";

.root {
	display: inline-flex;
	align-items: center;

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-grey-inactive);
	}
}

.icon {
	margin: 0px 7px;

	svg {
		fill: var(--main-blue);
	}

	path {
		fill: var(--main-blue);
	}
}

.timer {
	width: 17px;
	height: 20px;
}

.poop {
	width: 19px;
	height: 19px;

	transform: scale(1.4);
}
