.root {
	position: relative;

	width: 32px;
	height: 32px;

	border-radius: 20px;
	background: var(--main-black);
	cursor: pointer;

	svg {
		position: absolute;
		top: 53%;
		left: 52%;
		transform: translate(-50%, -50%);
		width: 11px;
		height: 11px;

		fill: #fff;
	}

	&:hover {
		box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
	}
}
