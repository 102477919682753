.root {
	position: relative;

	min-height: 100vh;

	background: var(--main-blue-disabled);
}

.content {
	padding: 70px 20px 100px;
	margin: 0 auto;

	max-width: 1080px;
}
