@import "styles/vars.scss";

.container {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 7px 16px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  gap: 12px;

  @include mbDesktopBreakpoint {
    margin-left: -48px!important;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }

  .arrowContainer {
    &:first-child {
      svg {
        transform: rotate(90deg);
      }
    }
    &:last-child {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
}

.arrowContainer {
  background-color: var(--main-blue-disabled);
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 200ms ease-in-out;
  user-select: none;

  border: none;
  outline: none;
  padding: 0;

  &:hover {
    background-color: var(--main-blue-inactive);
  }

  svg {

    * {
      fill: #7F8DEF;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}