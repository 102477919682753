@import "styles/vars.scss";

.title {
	margin-bottom: 16px;

	h1 {
		margin: 0;
		font-size: 26px;
	}
}

.body {
	@include flex-align-items-center;
	justify-content: space-between;

	margin-top: 32px;

	width: 100%;

	@include mbDesktopBreakpoint {
		justify-content: center!important;
	}
}

.stats {
	padding: 20px 40px;
}

.statsContainer,
.statsField,
.statsAll {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.statsContainer {
	& > div {
		flex-grow: 1;
		&:last-child {
			margin-left: 109px;
		}
	}
}

.statsField {
	margin-top: 4px;

	span {
		color: var(--main-black);

		&:first-child {
			font-family: $ubuntu-font;
			font-size: 14px;
			opacity: 0.5;
			line-height: 24px;
		}

		&:last-child {
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;
		}
	}

	&:first-child {
		margin-top: 0;
	}
}

.statsAll {
	margin-top: 12px;

	span:first-child {
		opacity: 1;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		font-family: $default-font;
	}
}

.red {
	color: var(--accent-red) !important;
}
