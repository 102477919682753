@import "styles/vars.scss";

.root {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: 9px 40px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #fff;
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
  font-family: "Ubuntu", sans-serif;
  color: #2D2C46;

  @include mbDesktopBreakpoint() {
    height: 84px !important;
    padding: 12px 12px 12px 12px !important;
  }
}

.openTrigger {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--main-blue);
}

.arrow {
  fill: var(--main-blue) !important;
  transform: rotate(180deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;

  }
}

.clicked {
  position: relative;
  z-index: 11;

  .icon {
    transform: rotate(0deg) !important;
  }
}

.actions {
  width: 143px;

  @include mbDesktopBreakpoint() {
    width: 73px !important;

  }
}

.actionsContacts {
  width: 230px;

  @include mbDesktopBreakpoint() {
    width: 200px !important;

  }
}

.actionItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;


  span {
    flex: 10 1;
    margin: 0;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: var(--main-blue);
  }

  svg {
    margin-right: 12px;
    flex: 1 1;
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    //fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.actionItem:first-child {
  margin-top: 0;
}

.actionItem:last-child {
  margin-bottom: 0;
}

.arrows {
  width: 20px;
  height: 15px;
}

.plus {
  width: 12px;
  height: 12px;
}

.clip {
  width: 9px;
  height: 18px;
}

.poop {
  width: 15px;
  height: 19px;
  transform: scale(1.3);
}

.timer {
  width: 17px;
  height: 20px;
}

.createColdCallButton {
  padding: 10px 40px !important;
  width: 204px;
}

.createColdCallButtonMobile {
  justify-content: center;
  width: 40px;
  padding: 0!important;

  svg {
    width: 36px!important;
    height: 36px!important;
    margin-right: 0!important;
  }
}

.createLead {
  justify-content: center;
  padding: 10px 40px !important;
  width: 204px;
}

.cancel {
  path {
    fill: #FF575F !important;
  }
}

.cancelText {
  span {
    color: #FF575F !important;
  }

  svg {
    fill: #FF575F !important;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;

  @include mbDesktopBreakpoint() {
    padding-left: 0 !important;

  }
}

.takeLoader {
  margin-left: 20px;
}

.popupCard {
  width: 300px !important;
}

.popupCardContacts {
  width: 300px !important;
  left: 0;
  padding: 18px !important;

  .actionItem {
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }

    svg {
      margin: 0 !important;
      height: 30px !important;
      width: 30px !important;
      fill: none !important;
    }

    span {
      max-width: 170px !important;
      margin-left: 12px;
      color: black !important;
    }

  }
}

.footerContact {
  display: flex;
  flex-flow: column;
  margin-right: 20px;
  width: 100%;
}

.flexRow {
  display: flex;
  flex-flow: row;
  align-items: center;

  .renewal {
    margin-right: 4px;
  }
}

.flexRowBetween {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.contact {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.iconBlock {
  padding-left: 8px;
}

.contactPhoneClosed {
  color: #336BEB;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  cursor: pointer;
}

.iconBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 24px;
  max-width: 390px;

  .svgIcon {
    display: flex;
    margin-right: 5px;
  }

  .other {
    background-color: #EDEDED;
    width: 24px;
    height: 24px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;

    span {
      color: #9595A3;
    }
  }
}

