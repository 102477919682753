.root {
	position: relative;
	margin-top: 30px;
	padding: 30px 40px 14px 40px;
	background: var(--main-blue-disabled);
	border-radius: 20px;
	overflow: hidden;

	&:hover .deleteIcon {
		display: flex;
	}
}

.deleteIcon {
	position: absolute;
	top: 12px;
	right: 14px;
	cursor: pointer;

	display: none;

	path {
		transition: 0.3s;
	}

	&:hover {
		path {
			fill: var(--accent-red);
		}
	}
}

.address {
	font-weight: 300;
	font-size: 14px;
	margin-bottom: 5px;
	color: var(--main-black);
	word-break: break-all;
	margin-top: 3px;
}

.alertWrapper {
	margin: 16px 0;
	max-width: 335px;

	box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.2);
}
