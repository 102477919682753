.root {
	display: flex;
	align-items: center;
}

.timeRoot {
	margin-left: 40px;
}

.timeWrapper {
	border: none;
	padding: 0;
	background-color: unset;
}
