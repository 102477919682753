@import "styles/vars.scss";

.description {
	margin-bottom: 80px;

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
}

.noDescription {
	opacity: 0.5;
}
