@import "styles/vars.scss";

.root {
	margin-top: 40px;
}

.wrapper {
	padding: 16px 78px 16px 40px;

	border-radius: 10px;
}

.total {
	display: flex;
	flex-direction: column;

	.totalLabel,
	.totalNumber {
		font-weight: bold;
		color: var(--main-black);
	}

	.totalLabel {
		font-size: 18px;
		line-height: 24px;
	}

	.totalNumber {
		margin-top: 8px;

		font-size: 36px;
		line-height: 24px;
	}
}

.button {
	margin-top: 16px;
}
