@import "styles/vars.scss";

.inputContainer {
	margin-top: 12px;
	padding: 0 12px;

	display: flex;
	align-items: center;

	border-bottom: 1px solid var(--main-blue-secondary);
}

.inputLabel {
	padding-left: 9px;

	display: flex;
	align-items: center;

	flex: 3 1;

	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-grey-sub);

	span {
		white-space: nowrap;
	}
}

.inputWrapper {
	flex: 5 1;
}

.tooltipRoot {
	margin: 4px 8px !important;
}

.tooltipCard {
	max-width: 362px !important;
	background: #3F3F40!important;
}
