.root {
	height: 50px !important;

	border-radius: 0 !important;

	min-width: 200px;

	display: flex;
	justify-content: center;

	box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
}
