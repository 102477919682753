.button {
	position: relative;

	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
}

.icon {
	fill: var(--main-black);

	display: flex;
	align-items: center;
	justify-content: center;
}

.line {
	position: absolute;
	top: 11px;
	left: -4px;
	transform: rotate(-45deg);

	width: 32.5px;
	height: 2px;
	background: var(--main-black);
}
