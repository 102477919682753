.checkboxesWrapper {
	display: flex;

	.checkboxWrapper {
		margin-right: 12px;

		&.small {
			margin-right: 6px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&.column {
		flex-direction: column;

		.checkboxWrapper {
			margin-top: 12px;
		}
	}

	&.grid {
		flex-wrap: wrap;

		.checkboxWrapper {
			margin-bottom: 12px;
			width: calc(50% - 22px);
			margin-right: 22px;

			&:nth-child(even) {
				margin-right: 0;
				width: calc(50% - 10px);
				margin-left: 10px;
			}
		}
	}
}

.radioButton {
	.checkboxWrapper {
		margin-right: 8px;
	}
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;

	label {
		pointer-events: none;
	}
}
