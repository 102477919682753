@import "styles/vars.scss";

.root {
	margin: 0 -40px 28px -40px;
	padding: 28px 0 0;

	@include mbDesktopBreakpoint {
		padding: 28px 28px 0!important;
	}

	background: #1E1E1F;

	h3 {
		margin: 0 0 20px 40px;

		font-weight: bold;
		font-size: 16px;
		line-height: 19px;
		color: #fff;
	}
}
