.root {
	padding: 42px 38px 45px 44px;

	height: 100%;
	width: 100%;
	min-width: 377px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.title {
	margin-bottom: 21px;

	font-weight: bold;
	font-size: 16px;
}

.fieldsContainer {
	margin-bottom: 52px;
}

.flagIconContainer {
	svg {
		transform: translateY(-5px);
		width: 25px;
		height: 25px;
	}
}
