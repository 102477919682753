@import "styles/vars.scss";

.root {
  :hover {
    background: #F2F2F2;
  }

}

.content {
  display: flex;
  flex-flow: row;

  position: relative;
  border-radius: 4px;

  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;

  img {
    width: 86px;
    height: 80px;
    border-radius: 4px 0px 0px 4px;
  }
}

.open {
  width: 440px;
}

.image {
  justify-content: start !important;
  gap: 8px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.imageOpen {
  img {
    border-radius: 4px !important;
  }
}

.select {
  width: 80px;
  display: flex;
  align-items: center;
 justify-content: center;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.available {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: #336BEB;
}

.applicationTitle {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
}

.created {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;

}

.tableRow {
  display: flex;
  flex-flow: row;
  align-items: start;
  width: 100%;
  justify-content: space-between;
}

.rowMain {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.cart {
  position: absolute;
  border-radius: 0 10px 10px 0;
  top: 0;
  width: 27px;
  height: 100px;
  right: 0;
  z-index: 1;
  cursor: pointer;
  border-left: 1px solid #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    fill: white;
  }
}

.cartMini {
  height: 80px!important;

  @include mbDesktopBreakpoint {
    height: 96px!important;
  }
}

.purchased {
  background: #336BEB;

  svg {
    fill: none !important;
  }
}

.optionsRow {
  display: flex;
  gap: 10px;
  margin-top: 4px;
}

.option {
  border: 1px solid #336BEB;
  border-radius: 30px;
  padding: 4px 8px 6px 8px;
  color: #336BEB;
  display: flex;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  min-width: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}

.priceWithoutDiscount {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: #8B8B8D;
  text-decoration: line-through;
}

.selectWrapper {
  left: -50%!important;
  width: max-content!important;
  max-width: 300px!important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.discount {
  position: absolute;
  border-radius: 30px;
  padding: 2px 6px 2px 6px;
  left: 10px;
  bottom: 10px;
  background: #FF575F;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
}

.tableColWithOutCard {
  padding: 8px 30px 4px 0 !important;
}

.countExtra {
  border: 1px solid #336BEB;
  border-radius: 50%;
  padding: 4px 8px 6px 8px;
  color: #336BEB;
  display: flex;
  align-items: center;
  font-family: Ubuntu, sans-serif;
  justify-content: center;
  max-width: 30px;
}

.priceWhenIsDiscount {
  color: #FF575F !important;
}

.status {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: #336BEB;
}

.statusSuccess {
  color: #21BC5F;
}

.tableCol {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  padding: 8px 56px 4px 0;
}

.tableColMini {
  padding-top: 6px!important;
}

.tableContent {
  height: 100%;
  display: flex;
  flex-flow: row;
  position: relative;
  border-radius: 10px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  box-shadow: 0 0 30px 0 #2224281A;

  img {
    margin-right: 12px;
    width: 106px;
    height: 100px;
    border-radius: 10px 0px 0px 10px;
  }
}

.mini {
  height: 80px;

  img {
    width: 82px;
    height: 80px;
  }

  @include mbDesktopBreakpoint {
    height: 96px!important;
    img {
      width: 96px!important;
      height: 96px!important;
    }
  }
}

.tableContentSuccess {
  background: #EEFCF4;
}

.title {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
}

.description {
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.amount {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;


  svg {
    cursor: pointer;

    path {
      fill: #336BEB;
    }
  }
}

.price {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.20000000298023224px;
  color: #1E1E1F;
}

.delete {
  position: absolute;
  right: -29px;
  top: 28px;
  cursor: pointer;
  height: 24px;

  :hover {
    background: white !important;
  }

  svg {
    :hover {
      background: white !important;
    }

    path {
      fill: #FF575F;
    }
  }
}