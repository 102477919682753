@import "styles/vars.scss";

.root {
  width: 1096px;

  @include mbDesktopBreakpoint {
    width: 480px!important;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;

  @include mbDesktopBreakpoint {
    margin-bottom: 60px;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.openTrigger {
  font-family: Cera Pro;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 31.2px !important;
  letter-spacing: 0.20000000298023224px !important;
  text-align: left;
  color: #336BEB !important;
}

.table {
  width: 100%;
  position: relative;
  //background: #FFFFFF;
}

.skeleton {
  margin-bottom: 24px;

  width: 100%;
  height: 40px;

  @include skeleton();
}

.buttonContainer {
  margin-top: 64px;

  svg {
    width: 20px!important;
    height: 20px!important;
  }
}

.bodySkeleton {
  height: 1000px;
}

.arrow {
  margin-left: 10px;
  fill: var(--main-blue) !important;
  transform: rotate(0deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;
  }
}

.popupCard {
  width: 300px;
  left: -40px!important;
}

.actionItem {
  margin: 16px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  span {
    flex: 10 1;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: var(--main-blue);
  }

  svg {
    margin-right: 12px;
    flex: 1 1;
    fill: var(--main-blue);
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}
