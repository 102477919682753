@import "styles/vars.scss";

.root {

}

.logo {
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 476px!important;
  }
}

.content {
  padding: 16px 40px;

  @include mbDesktopBreakpoint {
    padding: 16px 10px!important;
  }
}

.office {
  margin-top: 20px;
}

.plug {
  background: #F2F2F2;
  color: #BFBFC0;
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 476px!important;
  }
}

.description {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

  p {
    margin: 12px 0;
  }

  img {
    max-height: 400px;
  }
}

.subtitle {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 60px;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.footer {
  width: 100%;
  height: 92px;
  padding: 24px 40px 24px 40px;
  gap: 12px;
  border-top: 1px solid #F2F2F2;
  margin-top: 36px;
}

.footerLabel {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.footerShortId {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.footerDescription {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.stickyFooter {
  box-shadow: 0px 0px 30px 0px #2224281A;
  height: 64px;
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: white;

  @include mbDesktopBreakpoint {
    padding-left: 20px!important;
  }
}

.withoutTariff {
  justify-content: end!important;
}

.tariff {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: 0.20000000298023224px;
}