@import "styles/vars.scss";

.root {
	padding-right: 7px;

	position: sticky;
	top: 20px;
}

.rootRelative {
	position: relative;
}

.menuContainer {
	position: relative;

	display: flex;
	flex-direction: column;

	.menuItem:last-child {
		border-bottom: none;
	}
}

.callCenterMenu {
	margin-top: 24px;
}

.menuItem {
	position: relative;
	padding: 14px 5px 14px 39px;
	min-height: 60px;

	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;

	border: none;
	background: transparent;
	border-bottom: 1px solid #e5e5e5;

	cursor: pointer;
	transition: 0.2s;

	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 14px;
	line-height: 15px;
	color: var(--main-blue);

	svg {
		position: absolute;
		left: 0;
		top: 50%;

		width: 26px;
		height: 26px;

		transform: translateY(-50%);
	}

	svg,
	path {
		transition: 0.1s;
		fill: var(--main-blue);
	}

	&:hover {
		color: var(--main-black);

		svg,
		path {
			fill: var(--main-black);
		}
	}
}

.menuItemHide {
	display: none;
}

.disabled {
	cursor: default;
	opacity: 0.5;

	&:hover {
		color: var(--main-blue);

		svg,
		path {
			fill: var(--main-blue);
		}
	}
}

.callMobile {
	transform: translateY(-50%) scale(1.3, 1.3) !important;
}

.loader {
	position: initial !important;
	width: 100%;

	cursor: wait;
}

.loading {
	cursor: wait;
}
