.root {
	position: relative;

	background: #3a3a3b;
	display: flex;
	border-radius: 10px;
	height: 100px;
	padding-right: 20px;
}

.info {
	position: relative;
	z-index: 2;

	font-weight: 900;
	font-size: 18px;
	color: #fff;
	padding: 28px 0px 28px 31px;
	text-transform: uppercase;
	white-space: pre-wrap;

	span {
		color: #9ff612;
	}
}

.img {
	position: absolute;
	top: 0;
	right: 20px;
	height: 100%;
	z-index: 1;
}
