@import "styles/vars.scss";

.root {
    background: #E5E5E5;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include mbDesktopBreakpoint {
        overflow-y: auto;
        height: 100vh;
    }
}

.title {
    font-family: Cera Pro;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #1E1E1F;
    margin-bottom: 24px;
}

.subtitle {
    font-family: Cera Pro;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
}

.subtitleCount {
    font-family: Cera Pro;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #336BEB;
}

.label {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    opacity: 0.5;
    margin-bottom: 12px;
}

.text {
    font-family: Cera Pro;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
}

.leadMonth {
    width: 724px;
    height: 232px;
    padding: 20px;
    border-radius: 8px;
    background: #FFFFFF;
}

.block {
    width: 352px;
    height: 232px;
    border-radius: 8px;
    background: #FFFFFF;
    padding: 20px;
}

.blockContent {
    height: 140px;
    overflow-y: scroll;
    width: 320px;
    display: flex;
    flex-wrap: wrap;

      &::-webkit-scrollbar-thumb {
        background: #BFBFC0;
        border-radius: 100px;
    }

    :nth-child(1) {
        margin-top: unset!important;
    }
    :nth-child(2) {
        margin-top: unset!important;
    }
}

.rowBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: 136px!important;
}

.firstLine {
    width: 156px;
    height: 56px;
    display: flex;
    flex-flow: column;
}

.secondLine {
    width: 214px;
    height: 56px;
    display: flex;
    flex-flow: column;
}

.thirdLine {
    width: 146px;
    margin-top: 20px;
}

.row {
    display: flex;
    align-items: center;
    gap: 20px;
}

.rowText {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 32px;
}