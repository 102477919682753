@import "styles/vars.scss";

.save {
  width: 801px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 0 30px 0 #2224281A;
  margin-left: -40px;
  align-items: center;
  position: sticky;
  bottom: 0;
  height: 64px;
  padding-right: 40px;
  background: white;
  margin-bottom: 0 !important;

  @include mbDesktopBreakpoint {
    margin-left: -10px !important;
    width: 496px !important;
    padding-right: 10px !important;
    margin-bottom: -50px!important;
  }
}
