@import "styles/vars.scss";

.root {
	max-width: 434px;
	min-width: 434px;
	background: var(--BW-5, #FFF);

	padding: 50px 36px 12px 18px;
	height: 100vh;

	overflow-y: scroll;
}

.card {
	padding-bottom: 12px;
}

.skeleton {
	display: flex;
	flex-wrap: nowrap;

	width: 100%;
	height: 100px;

	margin-bottom: 10px;
	@include skeleton();

	background: white;
}

.p {
	color: var(--main-grey-disabled) !important;
}
