.root {
	padding: 17px;

	display: flex;
	align-items: center;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	height: 60px;
	width: 100%;

	:first-child {
		margin-right: 22px;
	}

	:nth-child(2) {
		margin-right: 40px;
	}
}

.type {
	width: 30px;
	position: relative;
}

.arrow {
	position: absolute;
	top: 0px;
	left: 15px;
}

.red {
	path {
		fill: var(--accent-red);
	}
}

.direction {
	font-family: Ubuntu;
	font-size: 14px;
}

.time {
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey-disabled);
}

.arrowOut {
	transform: rotate(180deg);
}
