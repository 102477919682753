@import "styles/vars.scss";

.wrapper {
  width: 720px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 100px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #BFBFC0;

  @include mbDesktopBreakpoint {
    width: 440px!important;
    height: 96px!important;
  }
}

.mini {
  width: 460px!important;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: var(--main-black);

  display: flex;
  align-items: center;

  svg,
  path {
    margin-right: 12px;
    fill: var(--main-blue-secondary);
  }
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: var(--main-black);

  display: flex;
  align-items: center;

  svg,
  path {
    margin-right: 12px;
    fill: var(--main-blue-secondary);
  }
}
.row {
  display: flex;
  justify-content: space-between;
  padding: 0!important;

  @include mbDesktopBreakpoint {
    height: 96px;
  }
}
.cart {
  display: flex;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #7F8DEF!important;
  cursor: pointer;

  svg,
  path {
    margin-right: 4px;
  }
}

