@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	justify-content: space-between;

	padding-bottom: 12px;

	border-bottom: 1px solid rgba(var(--main-blue-secondary-rgb), 0.3);
}

.date {
	color: var(--main-grey-disabled);
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.status {
	@include flex-align-items-center;

	& > div:last-child {
		margin-left: 10px;

		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

.success,
.preSuccess {
	color: var(--accent-green);
	svg {
		path {
			fill: var(--accent-green);
		}
	}
}

.waiting {
	color: var(--accent-orange);

	svg {
		fill: var(--accent-orange);
	}
}

.canceled,
.notFullInfo,
.viewMissed,
.newDate,
.cardIsReady {
	color: var(--accent-red);

	svg {
		fill: var(--accent-red);

		path {
			fill: var(--accent-red);
		}
	}
}

.canceled {
	svg {
		width: 24px;
		height: 24px;
	}
}
