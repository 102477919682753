@import "styles/vars.scss";

.root {
	@include flex-align-items-center;

	padding: 16px 28px;

	&.active {
		.date {
			& > div {
				color: black;
			}
		}

		.dayOfWeek {
			color: black;
		}
	}
}

.icon {
	margin-right: 20px;
}

.date {
	@include flex-align-items-center;
	& > div {
		&:first-child {
			color: white;
			font-weight: 700;
			font-size: 20px;
			line-height: 20px;
			white-space: nowrap;
		}

		&:last-child {
			margin-left: 10px;
		}
	}
}

.dayOfWeek {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	opacity: 0.5;
	color: white;
}

.soon {
	padding: 2px 6px;
	margin-left: 10px;

	font-size: 10px;
	color: white;
	font-weight: 700;
	background: var(--main-blue);
}
