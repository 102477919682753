.root {

}

.content {
  background: #F2F2F2;
  max-width: 720px;
  padding: 20px 20px 20px 20px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.mini {
  zoom: 0.9;

  .row {
    margin-right: 10px!important;
  }
}

.label {
  width: 254px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  margin-top: 60px;
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 12px;
}

.popupSelectedOption {
  color: var(--main-blue);
}

.popupBody {
  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #9696A2;
    cursor: pointer;

    &:first-child {
      margin-bottom: 6px;
    }
  }
}

.arrow {

  display: none;
}

.popupArrow {
  * {
    fill: var(--main-blue);
  }
}

.rootPopup {
  p {
    margin: 0;
  }

  > div > div > span {
    margin-right: 12px !important;
  }
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 20px;
}

.description {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.text {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
  color: #1E1E1F;
}

.info {
  margin-top: 20px;
  margin-bottom: 20px;
}