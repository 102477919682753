@import "styles/vars.scss";

// Details section
.detailsWrapper {
  display: flex;
  justify-content: space-between;

  > div {
    flex-basis: 50%;

    &:last-child {
      margin-left: 56px;
    }
  }

  p {
    margin: 0;
  }
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  > p {
    color: var(--main-black);
    font-size: 14px;

    &:first-child {
      opacity: 0.5;
    }

    &:last-child {
      font-weight: 500;
    }
  }
}

.radioButton {
  margin-top: 28px;
  margin-bottom: 12px;
  label {
    border-radius: 100px;
    font-family: $ubuntu-font;
    font-weight: 400;
    height: 28px;
  }
  label[class^="RadioButton_checked"] {
    font-weight: 500;
    color: white !important;
  }
}

.root {
  p {
    margin: 0;
  }

  > div > div > span {
    margin-right: 12px !important;
  }
}

.label {
  display: flex;
  > p:first-child {
    margin-right: 8px;
  }
}

.arrow {
  * {
    fill: var(--main-blue);
  }
}

.popupSelectedOption {
  color: var(--main-blue);
}

.popupBody {
  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #9696A2;
    cursor: pointer;

    &:first-child {
      margin-bottom: 6px;
    }
  }
}

.detailsContainer {
  display: flex;
}

.unknownInfo {
  color: #9696A2;
  font-weight: 500;
  font-size: 14px;
}

.tooltipContainer {
  background: #F2F5FB;
  ////border: 1px solid #CCCCCC;
  //border-radius: 10px;
  //outline: none;
  //border: none;
  //padding: 5px;
  padding: 12px 16px;

  p {
    margin: 0;
    font-family: $ubuntu-font;
  }
}

.tooltipHeader {
  p {
    opacity: 0.5;
    font-size: 12px;
  }
  margin-bottom: 8px;
}

.tooltipMarker {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: red;
  margin-right: 6px;
}

.tooltipBody {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    font-size: 14px;
  }

  > p {
    opacity: 0.5;
  }

  > p:last-child {
    margin-left: 28px;
    color: #2D2C46;
    opacity: 1;
  }
}

.chartWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-top: 20px;
}

.loadingContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease-in-out;
}

.showLoading {
  opacity: 1;
}