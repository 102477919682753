@import "styles/vars.scss";

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(77, 77, 104, 0.3);
  backdrop-filter: blur(4px);
}

.cardBody {
  background: #FFF;
}

.countBlock {
  background-color: #F2F5FB;
}

.noFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--BW-3, #BFBFC0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;

}

.collections {
  width: 750px;
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  gap: 12px;
  padding-left: 20px;
  margin-left: -20px;
  padding-top: 20px;

  @include mbDesktopBreakpoint {
    width: 470px!important;
  }
}

.search {
  border: 1px solid #BFBFC0;
  width: 720px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 50px;

  @include mbDesktopBreakpoint {
    width: 440px;
  }
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: end;
  align-items: center;
  height: 64px;
  width: 800px;
  border-radius: 0 0 20px 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: (0px 10px 30px rgba(0, 64, 229, 0.25));
  z-index: 1;
  padding-right: 40px;
  background: #FFFFFF;

  @include mbDesktopBreakpoint {
    width: 500px!important;
    border-radius: 0!important;
    height: 84px!important;
  }
}

.selected {
  border-radius: 10px;
  border: 2px solid #7F8DEF;
  background-color: white;
  img {
    height: 96px!important;
    margin-top: 2px;
  }
}