@import "styles/vars.scss";

.root {
	margin-bottom: 80px;

	.h3 {
		margin-top: 60px;
	}
}

.applicationsTableRow {
	height: 64px;
	align-items: flex-end !important;
	background: var(--main-blue-disabled) !important;
}

.applicationsTableCol {
	&:first-child {
		max-width: 245px;
	}
}

.underlay {
	padding: 16px 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px !important;

	p {
		margin: 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: var(--main-black);
	}
}

.skeleton {
	width: 100%;
	height: 72px;
	@include skeleton();
}
