@import "styles/vars.scss";

.root {
	@include flex-align-items-center;

	background: white;
	border-radius: 10px;
}

.card {
	max-height: 100%;
	height: 280px;
	width: 100%;

	border-radius: 0;
	position: relative;

	img {
		width: 311px !important;
	}

	&:after {
		content: "";
		position: absolute;
		top: 8px;
		right: 0;
		height: calc(100% - 16px);
		width: 1px;
		background: rgba(0, 0, 0, 0.1);
	}
}

.form {
	width: 40%;
	form {
		max-width: 100%;
	}
}
