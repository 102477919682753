@import "styles/vars.scss";

.redLine {
	position: fixed;
	z-index: 102;
	left: 0;
	right: 0;

	height: 20px;

	display: flex;
	align-items: center;
	justify-content: center;

	background: var(--accent-red);

	span {
		font-family: $ubuntu-font;
		font-size: 10px;
		line-height: 16px;
		color: #fff;
	}

	svg {
		margin-right: 6px;

		width: 10px;
		height: 9px;
	}

	path {
		fill: #fff;
	}
}

.buttonContainer {
	position: absolute;
	right: 24px;

	display: flex;
	align-items: center;
}

.button {
	font-size: 10px !important;
	line-height: 16px !important;
	color: #fff !important;
}

.closeSvg {
	margin-left: 4px;

	width: 12px;
	height: 12px;

	opacity: 1 !important;

	path {
		opacity: 1 !important;
		fill: #fff !important;
	}
}
