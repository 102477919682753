.root {
	padding-top: 7px;
}

.wrapper {
	position: relative;

	transition: 0.3s;
	width: calc(100% + 26px);

	height: 103px;
}

.wrapper:hover {
	.close {
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.card {
	display: block;
	width: calc(100% - 30px);
}

.icon {
	position: relative;
	left: calc(100% - 20px);
	bottom: 60px;
	display: block;

	width: 20px;
}

.close {
	cursor: pointer;

	width: 12px;
	height: 12px;
}
