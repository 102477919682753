@import "styles/vars.scss";

.nav,
.pinnedRealEstate,
.stages,
.header,
.blockItem {
	@include skeleton();
}

.blocks {
	.block:first-child {
		.header {
			margin-top: 30px !important;
		}
	}
}

.root {
	margin-bottom: 80px;
}

.nav {
	width: calc(100% - 80px);
	height: 49px;
	border-radius: 4px;
	background: #F2F2F2;
}

.pinnedRealEstate {
	margin: 40px auto 0;
	width: calc(100% - 80px);
	height: 92px;

	border-radius: 4px;
	background: #F2F2F2;
}

.stages {
	margin: 40px auto 0;
	border-radius: 4px;
	width: calc(100% - 80px);
	height: 320px;
	background: #F2F2F2;
}

.block {
	margin: 40px auto 0;
	padding: 0 40px;

	.header {
		background: #F2F2F2;
		margin-bottom: 12px;
		border-radius: 4px;
		width: 100%;
		height: 32px;
	}

	.blockItem {
		width: 100%;
		height: 80px;
		background: #F2F2F2;
		border-radius: 4px;
	}
}
