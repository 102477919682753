.root {
	padding-bottom: 1px;

	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: var(--main-blue-link);
	text-decoration: none;
	border-bottom: 1px dotted var(--main-blue-link);
}

.inner {
	padding-bottom: 0;

	color: var(--main-blue);
	border-bottom: none;
}
