@import "styles/vars.scss";

.root {
  margin-top: 60px;
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
    margin-top: 40px!important;
  }
}

.card {
  width: 720px;
  height: 100px;
  border-radius: 10px;
  background: var(--BW-5, #FFF);
  padding: 8px 20px 4px;
  position: relative;
  /* Default object */
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);

  @include mbDesktopBreakpoint {
    width: 440px!important;
    height: 96px!important;
    padding: 0!important;
  }
}

.archive {
  opacity: 0.1;
}

.list {
  display: flex;
  flex-flow: column;
  gap: 12px;
}


.title {
  color: var(--, #2D2C46);
  font-family: "Cera Pro";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}