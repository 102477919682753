.root {
  margin-top: 26px;
}

.fields {
  margin-bottom: 55px;
}

.button {
  min-width: 222px;
}

.buttonNewCompany {
  margin-top: 34px;
}

.buttonRealtorInCompany {
  margin-top: 108px;
}

.buttonSingleRealtor {
  margin-top: 9px;
}

.tooltip {
  margin: 0 !important;
}

.addressSelect {
  padding: 10px 8px;
}

.realtorInfo {
  margin-bottom: 14px;
}

.searchSelectWrapper {
  width: 100.5%;
  margin-top: 4px;
}
