.root {

}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.container {
  margin-top: 15px;
  padding: 10px 20px;
}

.buttonContainer {
  margin-top: 20px;
  padding: 0 24px;
  gap: 10px;

  display: flex;
  justify-content: flex-end;
}

.option {
  font-size: 14px;
}