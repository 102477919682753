@import "styles/vars.scss";

.root {
	h1 {
		margin: 0;
		padding-left: 40px;

		font-size: 26px;
		color: var(--main-black);
	}
}

.container {
	margin-top: 15px;
	padding: 10px 20px;

	background: var(--main-blue-disabled);
}

.buttonContainer {
	margin-top: 20px;
	padding: 0 24px;

	display: flex;
	justify-content: flex-end;
}
