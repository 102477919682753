.root {
	margin: 0;
	padding: 0;

	max-height: 50px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;

	font-size: 13px ;
	font-style: normal;
	font-weight: 300;
	line-height: 16.8px !important;

}
