$line-height: 14px;

.tooltipCard {
	padding: 12px 16px !important;
	top: calc(100% + 5px) !important;
	left: -5px !important;
}

.labelRoot {
	display: block !important;
	margin: 0 !important;
}

.label {
	height: $line-height;

	svg {
		width: 100%;
		height: 100%;
	}
}

.labelHovered {
	display: none;
}
