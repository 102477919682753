@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
}

.block {
	height: 64px;
	width: 100%;

	@include skeleton();
}
