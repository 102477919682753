@import "styles/vars.scss";

.wrapper {
	background: white;
	padding: 3px 7px;
	border-radius: 375px;

	font-size: 12px;
	color: #878787;

	font-family: $ubuntu-font;
}
