@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 44px;
	width: 100%;
	background: var(--main-blue-disabled);

	@include mbDesktopBreakpoint {
		justify-content: left;
	}

	&:first-child div div {
		border-radius: 0 15px 0 0!important;
	};
}
