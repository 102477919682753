@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  justify-content: center;
}

.filters {
  padding: 15px 15px 15px 235px;
  background: #fff;
  @include mdDesktopBreakpoint() {
    padding: 15px 15px 15px 85px;
  }
}

.list {
  padding: 20px 40px;
  overflow: auto;
  width: 100%;

  @include mbDesktopBreakpoint {
    padding: 20px 0 !important;
  }
}

.bottomLine {
  span {
    height: 6px;
    background: #FFF !important;
  }
}

.sideContainer {
  width: 500px;
  min-width: 500px;
  background: var(--main-blue-inactive);
}

.tabContent {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tableWithPagination {
  width: 100%;
  @include flex-align-items-center;
  flex-direction: column;

  margin-bottom: 100px;
}

.pagination {
  margin-top: 38px;
  justify-content: center;
}

.tabTitle {
  min-width: 130px;
}

.tabs {
  margin-bottom: 30px;
  margin-top: 20px;
  @include flex-justify-content-center;
}

.tabLabel {
  @include flex-justify-content-center;
}


.cart {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 150px;
  right: 15px;
  background: rgb(51, 107, 235);
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 36px;
    height: 36px;

    :first-child {
      fill: none;
    }

    path {
      stroke: white;
    }
  }
}