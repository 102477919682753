.input {
	margin-bottom: 5px;
	padding: 0 0 5px 0;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.row {
	height: 45px;
}

.calendar {
	position: relative;
	z-index: 10;
	transform: translateX(-24px);
}

.actions {
	display: flex;
	justify-content: flex-end;
	margin-top: 32px;
}

.submitBtn {
	margin-left: 60px;
}

.placeholder {
	&::placeholder {
		font-family: Cera Pro;
		font-style: normal;
		font-weight: 500;

		color: var(--main-blue-secondary);
	}
}
