.root {
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 12px;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	padding: 17px;

	width: 100%;

	div {
		font-size: 16px;
		font-weight: 400;
	}
}
