@import "styles/vars.scss";

.root {
  height: 92px;
  padding: 24px 0 24px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 12px;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 136px;
}

.lightGrey {
  color: #CCCCCC;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.darkGrey {
  color: #2D2C46;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.blue {
  color: #336BEB;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  font-family: Ubuntu;
}
