@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh);
  max-height: calc(100vh);
  justify-content: end;
}

.rootNotPremium {
  //margin-top: 50px;
  min-height: calc(100vh) !important;
  max-height: calc(100vh) !important;
}

.filters {
  padding: 15px 15px 15px 235px;
  background: #fff;
  @include mdDesktopBreakpoint() {
    padding: 15px 15px 15px 85px;
  }
}

.list {
  padding: 20px 40px;
  overflow: auto;
  width: 100%;

  @include mbDesktopBreakpoint {
    padding: 20px 0 !important;
  }
}

.listWrapper {
  width: 100%;
}

.bottomLine {
  span {
    height: 6px;
    background: #FFF !important;
  }
}

.sideContainer {
  width: 500px;
  min-width: 500px;
  background: var(--main-blue-inactive);
}

.tabContent {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tableWithPagination {
  width: 100%;
  @include flex-align-items-center;
  flex-direction: column;
}

.pagination {
  margin-top: 38px;
  justify-content: center;
}

.tabTitle {
  min-width: 130px;
}

.map {
  min-width: 500px;
}

.tabs {
  margin-bottom: 30px;
  margin-top: 20px;
  @include flex-justify-content-center;
}

.tabLabel {
  @include flex-justify-content-center;
}

.mapButton {
  background: #336BEB;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 150px;
  border-radius: 50%;
  right: 15px;

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.mapButtonObject {
  background: #336BEB;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  bottom: 80px;
  border-radius: 50%;
  left: 440px;
  z-index: 2;

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.mapMobile {
  position: fixed;
  z-index: 2;
  display: flex;
  height: 100vh;
  width: 100%;
  background: white;
}

.nav {
  position: sticky;
  width: 100%;
  top: 0;
  background: white;
  z-index: 1;
}
