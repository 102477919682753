@import "styles/vars.scss";

.number {
	font-size: 16px;
	line-height: 24px;
	color: #000;
}

.date {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-black);
	opacity: 0.5;
}

.stage {
	display: flex;
	flex-direction: row;
	align-items: center;

	font-size: 14px;
	line-height: 20px;

	svg {
		margin-right: 4px;

		width: 16px;
		height: 14px;
	}
	path {
		fill: var(--accent-red);
	}
}

.stageFree {
	color: var(--accent-red);
}

.type {
	display: flex;
	align-items: center;

	transform: translateY(1px);

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: #686779;
	white-space: nowrap;

	svg {
		margin-left: 8px;
	}
}

.siteIcon {
	margin: 0 4px 0 0 !important;
}

.noResponsible {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: (--main-black);
}
