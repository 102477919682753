@import "styles/vars.scss";
@import "styles/keyframes";

$icon-width: 18px;
$padding-left: calc(#{$icon-width} + 26px);

@mixin text-field-icons-styles {
	position: absolute;
	top: 50%;
	display: flex;
	align-items: center;
	transform: translateY(-50%);
}

.root {
	position: relative;
}

.textField {
	position: relative;
}

.input {
	width: 100%;
	padding: 9px 10px;
	color: var(--main-black);
	font-weight: bold;
	font-size: 20px;
	font-family: $default-font;
	line-height: 120%;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	transition: border-bottom-color 0.25s ease;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: var(--main-blue-secondary);
		font-size: 14px;
		letter-spacing: 0.2px;
		font-weight: 500;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:-webkit-autofill,
	&:-webkit-autofill::first-line {
		font-family: $default-font;
		color: var(--main-black);
		font-weight: bold;
		font-size: 20px;
	}
}

.inputSmall,
.inputSmallNoBorder {
	padding: 3px 15px;

	font-size: 14px;
	font-weight: 500;
}

.inputSmall {
	border-width: 1px;
}

.inputSmallNoBorder {
	border: none;
}

.inputWithPrepend {
	padding-left: $padding-left;
}

.inputWithApend {
	padding-right: calc(#{$icon-width} + 20px);
}

.inputWithApendAndError {
	padding-right: calc(#{$icon-width} * 2 + 20px);
}

.inputFocused {
	border-bottom-color: var(--main-blue);
}

.inputWithError {
	color: var(--accent-red);
	animation: blinkErrorBorder 1s linear 3;
	border-bottom: 1px solid var(--accent-red);
}

.label {
	pointer-events: none;
	position: relative;
	bottom: -2px;
	z-index: 1;
	padding-left: 10px;
	color: var(--main-grey-disabled);
	font-size: 10px;
	line-height: 120%;
	transition: bottom 0.2s;
	font-family: $ubuntu-font;

	&__withSmallInput {
		font-weight: 400;
		padding-left: 15px;
	}

	&__paddingLeft {
		padding-left: $padding-left;
	}

	&__noValueNoActive {
		bottom: -30px;
		font-size: 14px;

		&.label__withSmallInput {
			bottom: -13px;
		}
	}
}

.prepend {
	@include text-field-icons-styles;

	left: 0;
	padding-left: 8px;
}

.append {
	@include text-field-icons-styles;

	right: 0;
	padding-right: 8px;
}

.doubleAppend {
	display: flex;
	align-items: center;

	svg {
		margin-right: 7px;
	}
}

.hintContainer {
	position: absolute;
	margin: 0;
	padding: 0;
	left: 0;
}

.errorContainer {
	position: absolute;
	margin: 0;
	padding: 0;
	right: 0;
}

.hint {
	margin-top: 4px;
	font-size: 12px;
	color: var(--main-grey-disabled);
}

.error {
	margin-top: 2px;
	color: var(--accent-red);
	font-size: 12px;
	text-align: end;
	font-family: $ubuntu-font;
}
