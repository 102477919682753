@import "styles/vars.scss";

.root {
  padding: 12px 20px 12px 40px;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  width: 803px;
  overflow: hidden;
}

.skeleton {
  margin-bottom: 24px;

  width: 100%;
  height: 40px;

  @include skeleton();
}

.header {
  height: 160px;
  border-radius: 4px;
  background: var(--main-blue-inactive);
}

.gallery {
  height: 214px;
  border-radius: 4px;
  background: var(--main-blue-inactive);
}

.body {
  height: 1000px;
  border-radius: 4px;
  background: var(--main-blue-inactive);
}
