@import "styles/vars.scss";

.singlePerson {
	font-family: $ubuntu-font;
	font-size: 14px;
	color: var(--main-black);
	opacity: 0.7;
	font-weight: 400;
	max-width: 90px;
	white-space: nowrap;
}

.avatar {
	display: flex;
	padding: 1px 0 1px 1px;
}

.avatarNoImg {
	width: 28px !important;
	height: 28px !important;
	font-size: 12px !important;
}

.responsibleSingleAvatar {
	margin-right: 10;
}

.person {
	display: flex;
	align-items: center;
	margin: 0 !important;

	* {
		width: fit-content;
		height: fit-content;
	}
}

.personCard {
	flex-direction: row !important;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;

	border-radius: 648px;

	padding: 0px !important;
	border-radius: 648px !important;

	margin: 4px 0px 4px 12px;

	& > div {
		font-family: $ubuntu-font;
		font-weight: 400;
		font-size: 14px;
		&:last-child {
			padding-left: 6px;
			padding-right: 20px;
		}
	}
}

.rest {
	margin: 0 !important;
}

.restCard {
	top: 0 !important;
	left: 0 !important;

	padding-top: 4px !important;
	padding-left: 4px !important;

	max-width: 186px;
	height: 100%;

	& > div {
		&:first-child {
			display: none;
		}
	}
}

.restContent {
	&:first-child {
		margin-top: 0;
	}

	& > div {
		&:first-child {
			margin-right: 0;
		}
	}
}

.restPerson {
	font-family: $ubuntu-font;
	font-weight: 400;
	margin-left: 6px;

	color: white;
}

.restQuantity {
	padding: 6px;
	background-color: var(--main-blue-disabled);
	color: var(--main-black);
	font-size: 14px;
	border-radius: 351px;
}

.people {
	display: flex;

	width: 130px;
	text-overflow: ellipsis;
	@include flex-align-items-center;

	& > div {
		margin-right: 0px;
		margin-bottom: 0px;
		img {
			width: 28px !important;
			height: 28px !important;
			font-size: 12px !important;
		}
	}

	> div:first-child {
		margin-left: 0px;
	}
}
