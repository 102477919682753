@import "styles/vars";

.inputWrapper {
	margin-bottom: 5px;

	display: flex;
	flex-direction: row;
	align-items: center;

	padding-left: 10px;
	border-bottom: 1px solid var(--main-blue-inactive);
	min-height: 46px;
}

.input {
	padding: 10px 12px;
	background-color: inherit;

	border: none;

	color: var(--main-black);
	font-family: $default-font;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: var(--main-blue-secondary);
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.label {
	justify-content: flex-start;

	font-size: 14px;
	line-height: 20px;
	color: var(--main-grey-sub);
}

.addDelete {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex: 2 1;
}

.add,
.delete {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	cursor: pointer;

	svg,
	svg path {
		fill: var(--main-blue-secondary);
		transition: all 0.1s ease;
	}
}

.add {
	display: none;
}

.delete {
	margin-right: 20px;
}

.add svg {
	width: 10px;
	height: 10px;
}

.delete svg {
	width: 10.5px;
	height: 13.5px;
}

.delete:hover {
	svg,
	svg path {
		fill: var(--accent-red);
	}
}

.add:hover {
	svg,
	svg path {
		fill: var(--main-grey-disabled);
	}
}

.root .inputWrapper:last-child {
	.add {
		display: flex;
	}

	.delete {
		margin-right: 0;
	}
}
