@import "styles/vars.scss";

.root {
  padding: 16px 40px;
  min-height: calc(100vh - 64px);
  width: 803px;

  @include mbDesktopBreakpoint {
    padding: 16px 10px !important;
    width: 500px!important;
  }
}

.cardCreation {
  margin-top: 16px;
}

.cardWrapper {
  & > div {
    margin-top: 16px;
  }
}

.plug {
  width: 720px !important;
  background: #F2F2F2;
  div {
    color: #8B8B8D !important;
  }

  @include mbDesktopBreakpoint {
    width: 480px!important;
  }

}

.noDataText {
  margin-top: 20px !important;

  color: var(--main-grey-disabled) !important;
}
