.root {
	display: flex;
	width: 100%;
	min-height: 100vh;
}

.leftColumn {
	width: 50%;
	max-width: 850px;
}

.leftColumnLogo {
	position: absolute;
	top: 27px;
	left: 25px;
	z-index: 3;
}

.rightColumn {
	flex-grow: 1;
}
