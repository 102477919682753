.root {
	position: relative;
	height: 100%;
	overflow: hidden;
	background: radial-gradient(50% 50% at 50% 50%, rgba(255, 212, 3, 0.3) 0%, var(--accent-yellow) 100%);
}

.foreground {
	position: relative;
	z-index: 2;
	height: 100%;
}
