.textarea {
	padding: 16px 20px;

	resize: none;
	display: block;

	width: 100%;

	border: 1px solid var(--main-blue-secondary);
	border-radius: 10px;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: var(--main-blue-secondary);
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.textareaError {
	border: 1px solid var(--accent-red) !important;
}

.loading {
	width: 100%;
	height: 94px;

	border-radius: 10px;
	background: var(--main-blue-inactive);
}
