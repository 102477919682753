.groupName {
	font-weight: 500;
	opacity: 50%;

	cursor: pointer;
	user-select: none;

	&.active {
		opacity: 100%;
	}
}

.group {
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	margin-top: 6px;

	margin-left: 20px;
}
