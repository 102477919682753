.applicationListWrapper {
	padding-right: 28px;
	margin-top: 20px;

	max-height: 312px;
	height: 312px;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar-track {
		background-color: #fff;
	}
}

.requestsTableRow {
	height: 100px;
	padding: 22px;

	& > div {
		flex: auto;
		&:first-child {
			flex-grow: 1;
			min-width: 380px;
		}
	}
}

.search {
	padding-right: 30px;
}

.pinApplication {
	display: flex;
	flex-direction: column;

	button {
		align-self: flex-end;

		margin-top: 20px;
	}
	& > div {
		&:first-child {
			margin-top: 20px;
			padding: 12px 18px;
		}
	}
}
