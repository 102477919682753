.root {
	justify-content: space-between;
	font-size: 12px;
}

.root,
.priceChange {
	display: flex;
	align-items: center;
}

.priceChange {
	div:nth-child(2) {
		margin: 0 4px;
	}
}

.negative path {
	fill: var(--accent-red);
}

.date {
	opacity: 0.5;
}
