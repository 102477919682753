@import "styles/vars.scss";

.root {
	padding: 0 !important;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px !important;

	border: 2px solid #F2F2F2 !important;

	svg {
		position: absolute;
		left: -5px;
		transform: rotate(45deg);

		height: 30px!important;
		width: 30px!important;
		path {
			fill: #1E1E1F!important;
		}

		@include mbDesktopBreakpoint {
			left: -5px!important;
			path {
				fill: #FFFFFF!important;
			}
		}
	}

	path {
		//fill: var(--main-grey-disabled) !important;
	}

	&:hover {
		border-color: var(--accent-red) !important;

		box-shadow: none !important;

		path {
			fill: var(--accent-red) !important;
		}
	}
}
