.menuItems {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	justify-content: center;

	button {
		font-size: 16px;
		max-height: 35px;
		padding: 7px 18px;

		svg {
			height: 100%;
			width: auto;
		}
	}
}
