@import "styles/vars.scss";

.root {
	width: 100%;

	position: absolute;
	bottom: 0;
	background: #1E1E1F;
	transition: 0.1s;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 115px;

	&:hover {
		&.showAccountSwitcher {
			height: 151px;
		}

		.toggle {
			display: flex;
			opacity: 1;
		}

		.toggleContent {
			opacity: 1;
			height: 35px;
		}
	}
}

.rootOpened {
	height: 100% !important;

	.toggleContent {
		opacity: 1 !important;
		height: 90% !important;
	}

	.toggleIcon {
		transform: rotate(0);
	}

	.toggle {
		display: flex;
		opacity: 1;
	}
}

.toggle {
	@include button-no-style;

	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding: 5px 10px 4px 17px;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	display: none;
	opacity: 0;
	color: var(--main-blue);
	cursor: pointer;
	transition: 0.2s;
}

.premium {
	background: #336BEB;
	display: flex;
	gap: 8px;
	color: #FFFFFF;
	height: 44px!important;
	align-items: center;
	padding-left: 28px;
	transition: 0.2s;
	cursor: pointer;
}

.tooltipDescription {
	margin: 0 0 0 -4px !important;
}

.tooltipCard {
	max-height: 260px;
	max-width: 360px !important;
	min-height: 40px;
	padding: 8px 8px 8px 36px !important;
	left: -7px !important;
	top: -40px !important;
	background: #3F3F40!important;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 15;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-wrap: break-word;
		font-size: 13px;
		font-family: "Ubuntu", sans-serif;
	}

	svg {
		left: 8px !important;
		top: 8px !important;
	}
}

.toggleText {
	font-size: 14px;
	line-height: 17px;
	font-family: $ubuntu-font;

	transition: 0.2s;
}

.hideToggleText {
	opacity: 0;
}

.toggleContent {
	opacity: 0;
	transition: 0.1s;
	overflow: hidden;
	height: 0;
}

.toggleIcon {
	fill: #fff;
	transform: rotate(180deg);
	transition: 0.2s;
}

.toggleVisible {
	display: flex;
	flex-direction: column;
	height: 95%;
	justify-content: space-between;

	&.toggleVisibleIsAsEmployee {
		background: var(--accent-red);
	}
}

.toggleVisibleBottom {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.changeBtn {
	@include button-no-style;
	padding: 12px 16px;
	margin: 0 8px 12px;

	width: 90%;
	text-align: center;
	white-space: nowrap;

	color: var(--main-blue);
	font-size: 14px;
	cursor: pointer;
	border: 2px solid rgba(149, 149, 163, 0.2);
	border-radius: 100px;
	transition: 0.1s;
	font-family: $ubuntu-font;

	&:hover,
	&:focus,
	&:active {
		border: 2px solid var(--main-blue);
	}

	&:active {
		background-color: var(--main-blue);
		color: #fff;
	}

	&.changeBtnIsAsEmployee {
		color: #fff;
		border: 2px solid #fff;
	}
}

.otherAccounts {
	padding-bottom: 12px;
}

.userWrapper {
	@include button-no-style;

	width: 100%;
	cursor: pointer;
	padding: 0;
}
