@import "styles/vars.scss";

.root {
	position: relative;
	width: 100%;

	padding: 7px 13px;

	border-radius: 10px;

	display: flex;
	flex-direction: row;

	transition: filter 0.2s;

	&:hover {
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
		background: #fff;
		cursor: pointer;
	}
}

.background_blue {
	background: var(--main-blue-disabled);
}
.background_white {
	background: #fff;
}

.img {
	margin: -7px 20px -7px -13px;

	position: relative;
	width: 108px;
	min-width: 108px;
	height: 100px;

	img {
		width: 100%;
		height: 100%;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		object-fit: cover;
	}
}

.noImg {
	display: flex;
	align-items: center;
	justify-content: center;

	border-right: 1px solid rgba(0, 0, 0, 0.1);

	svg {
		width: 48px;
		height: 55px;
	}
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	color: var(--main-black);

	width: calc(100% - 108px);
}

.title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.address {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey);

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.currentPrice {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.pricePerUnit {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	color: #878787;
}
