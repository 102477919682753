@import "styles/vars.scss";

.adContainer {
	& > div {
		&:first-child {
			display: flex;
			align-items: center;
		}
	}
}

.adIcon {
	position: relative;
	svg {
		position: absolute;
		left: -30px;
		top: 2px;
		width: 20px;
		height: 20px;
	}
}

.adIconSecondLayout {
	position: relative;

	> svg {
		margin-right: 4px;
	}
}

.tooltip {
	margin: 0 0 0 8px;
}

.adDateContainer {
	margin-left: 8px;
	font-family: $ubuntu-font;
	font-size: 14px;
	opacity: 0.5;
}

.errorStatusContainer {
	display: flex;
	align-items: center;

	> div {
		margin-left: 8px;
	}
}

.alertIcon {
	width: 20px !important;
	height: 20px !important;
	margin-right: 8px !important;
}

.adDate {
	//margin-left: 28px;
	font-family: $ubuntu-font;
	font-size: 14px;
	opacity: 0.5;
}

.adTitle {
	margin-bottom: 5px;
}

.adWaiting {
	svg {
		fill: var(--accent-orange);
	}
}

.adFinished {
	svg {
		fill: var(--accent-red);
	}
}

.adError {
	span {
		color: var(--accent-red);
	}
	svg {
		margin-right: 8px;

		width: 20px !important;
		height: 20px !important;
		path {
			fill: var(--accent-red);
		}
	}
}


.onModeration {
	margin-left: 8px;
	color: var(--accent-orange);
	font-size: 14px;
	font-weight: 500;
}

.error {
	@extend .onModeration;
	color: var(--accent-red);
}

.alignedIcon {
	width: 32px !important;
	height: 32px !important;
	top: -4px !important;
	left: -34px !important;
}