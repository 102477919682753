.checkboxesWrapper {
	display: flex;
	flex-direction: column;

	.checkboxWrapper {
		margin-top: 13px;
		margin-left: 12px;
	}

	.groupWrapper {
		border-bottom: 1px solid var(--main-blue-secondary);
		padding-bottom: 16px;
		padding-top: 3px;

		&:last-child {
			border-bottom: none;
		}
	}
}
