@import "styles/vars.scss";

.infoRow {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  border-bottom: 1px solid #BFBFC0 !important;
}

.row {
  display: flex;
  flex-flow: row;
  gap: 24px;
  margin-top: 26px;
  margin-bottom: 32px;

  @include mbDesktopBreakpoint {
    flex-flow: column;
  }
}

.column {
  display: flex;
  flex-flow: column;
  min-width: 440px;
  margin-bottom: 20px;
  margin-top: 26px;
  width: 680px;

  @include mbDesktopBreakpoint {
    width: unset !important;
  }
}

.buttonBackground {
  background: #F2F2F2 !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -6px !important;
  background: #3F3F40 !important;

  @include mbDesktopBreakpoint {
    left: -90px !important;
    top: 10px !important;
  }

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}

.button {
  gap: 20px;
  background: white;
  color: var(--BW-0, #1E1E1F);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;

  label {
    .unchecked {
      background: white;
    }
  }
}

.noPreAppLabel {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.preAppLabel {
  color: #9595A3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.card {
  background: white !important;
}

.error {
  color: #FF575F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}