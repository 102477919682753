.cardSuccess {
	justify-content: normal !important;
	& > div {
		&:first-child {
			margin-right: 12px;
		}
	}
}

.radio {
	padding: 0 !important;
}
