@import "styles/vars.scss";

.root {
	padding: 12px 20px 12px 40px;
	min-height: calc(100vh - 50px);
	max-height: calc(100vh - 50px);
    width: 800px;
	overflow: hidden;

	@include mbDesktopBreakpoint {
		width: 500px!important;
	}
}

.skeleton {
	margin-bottom: 24px;

	width: 100%;
	height: 40px;

	@include skeleton();
}

.header {
	height: 160px;
	background: var(--main-blue-inactive);
}

.gallery {
	height: 214px;
	width: 100%;
	background: var(--main-blue-inactive);
}

.body {
	height: 1000px;
	background: var(--main-blue-inactive);
}
