.root {
	display: flex;
	flex-direction: column;

	:first-child {
		margin-top: 0px;
		margin-bottom: 4px;
	}

	> div {
		margin-top: 8px;
	}
}

.title {
	font-weight: bold;
	font-size: 20px;
	line-height: 120%;
}
