.root {
	display: inline-flex;
}

.roundTimer {
	position: relative;
	border-radius: 50%;
	width: 34px;
	height: 34px;
}

.countdownTimer__text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	font-size: 14px;
	font-weight: bold;
	color: #fff;

	&.alert {
		color: var(--accent-red);
	}
}
