@import "styles/vars.scss";

.root {
  width: 720px;
  height: 64px;
  padding: 12px 20px 12px 0;
  display: flex;
  justify-content: end;
  gap: 32px;
  align-items: center;
  position: sticky;
  bottom: 0;
  margin-bottom: -8px;
  box-shadow: 0px -10px 20px 0px #2224281A;
  background: #F2F2F2;
  border-radius: 0 0 12px 12px;
  margin-left: -6px;

  @include mbDesktopBreakpoint {
    width: 480px !important;
  }
}

.actions {
  width: 143px;

  @include mbDesktopBreakpoint {
    width: 63px;
  }
}

.arrow {
  fill: var(--main-blue) !important;
  transform: rotate(180deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;

  }
}

.openTrigger {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--main-blue);
}

.deleteActionItem {
  color: #FF575F !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;

  svg {
    fill: #FF575F !important;
  }
}

.actionItem {
  margin: 12px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  span {
    flex: 10 1;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: var(--main-blue);
  }

  svg {
    margin-right: 12px;
    flex: 1 1;
    fill: var(--main-blue);
    opacity: 0.7;
    transition: 0.2s;
  }

  path {
    fill: var(--main-blue);
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.popupCard {
  min-width: 300px !important;
}