@import "styles/vars.scss";

.root {
	display: flex;
}

.content {
	padding: 50px 0 24px 40px;
}

.banner {
	position: relative;

	width: 280px;
	height: 280px;
	border-radius: 20px 0 0 20px;
}

.img {
	width: 100%;
	height: 100%;

	object-fit: contain;
}

.h1 {
	margin: 12px 0 4px 0 !important;
}

.noMargin {
	margin: 0 !important;
}

.grey {
	h3,
	h2 {
		color: #6c6c7e !important;
	}
}

.stage {
	margin: 4px 0 60px;

	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey-sub);

	span {
		font-size: 14px;
		line-height: 20px;
		color: var(--main-grey-inactive);
	}
}

.idCreatedAt {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-grey-sub);

	span:last-child {
		margin-left: 36px;
	}
}
