@import "styles/vars.scss";

$line-height: 16px;

.labelRoot {
	font-weight: 500;
	font-size: 12px;
	line-height: $line-height;
	color: var(--main-black);

	display: block !important;
	margin: 0 !important;
}

.tooltipCard {
	padding: 8px !important;
	top: calc(100% + 5px) !important;
	left: -5px !important;
}

.labelHovered {
	display: none;
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 0.75rem;
}

.moreItemsCount {
	font-size: 0.75rem;
	line-height: 16px;
	color: var(--main-blue-secondary);
}
