@import "styles/vars.scss";

.formContainer {
	margin: 24px 0 72px;

	max-width: 370px;
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.button {
	margin-right: 48px;

	min-width: 222px;

	display: flex;
	justify-content: center;
}

.link {
	color: var(--main-blue);
	font-size: 16px;
	line-height: 20px;
	text-decoration: none;
}

.passTheSame {
	fill: var(--accent-green);
	margin-right: 3px;
	margin-top: 2px;
}

.passRule {
	font-size: 12px;
	color: var(--main-grey-disabled);
	margin-top: 24px;
}

.changeDone {
	max-width: 350px;
	text-align: center;

	h2 {
		margin-top: 28px;
		font-size: 24px;
		text-align: center;
	}

	p {
		color: rgba(0, 0, 0, 0.5);
		font-size: 16px;
		line-height: 19px;
		text-align: center;
		font-family: $ubuntu-font;
	}
}
