.form {
	width: 100%;
	margin-top: 30px;
	padding: 8px 39px 22px 40px;
	border: 2px dashed var(--main-grey-light);
	border-radius: 20px;
}

.btnContainer {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.field {
	margin-top: 3px;
	height: 40px;

	input {
		&:-webkit-autofill,
		&:-webkit-autofill::first-line {
			font-size: 14px !important;
			font-weight: 500 !important;
		}
	}
}

.inputSelect {
	padding-left: 15px !important;
}

.selectSelected {
	margin-top: 3px;
	height: 40px;
}
