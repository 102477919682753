.createdAt {
	margin: 0;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-black);
	opacity: 0.5;
}

.title {
	margin: 12px 0 0 !important;
	color: #2D2C46;
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 31.2px */
	letter-spacing: 0.2px;
}

.shortId {
	color: #336BEB;
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: 0.2px;
	cursor: pointer;
}

.header {
	white-space: break-spaces;
}

.row {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
	border-bottom: 1px #D5D3CE solid;
	padding-bottom: 10px;
}

.adLabel {
	color: #AFAFAF;
}

hr {
	stroke-width: 1px;
	stroke: #D5D3CE;
}

.adSource {
	display: flex;
	justify-content: end;
	width: 200px;
	overflow: hidden;
	color: #2D2C46;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	letter-spacing: 0.2px;
}