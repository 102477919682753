@import "styles/keyframes";
@import "styles/vars.scss";

.root {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&.highPerf {
		backdrop-filter: blur(8px);
	}

	&.lowPerf {
		backdrop-filter: none;
	}
}

.close {
	position: absolute;
	top: 9px;
	animation: appearAnimation 1.6s;
}

.buttonMap {
	position: absolute;
	bottom: 10px;
	right: 806px;
	z-index: 1000;
	animation: appearAnimation 1.6s;
}


.rightBlock {
	position: absolute;
	right: 0;
	z-index: 100;
	max-height: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #fff;
	transition: filter 1s;
}

.rightBlockObject {
	position: absolute;
	right: 0;
	z-index: 100;
	max-height: 100%;
	height: 100%;
	background-color: #fff;
	transition: filter 1s;
}

.withShadow {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
}
