.notifyTask {
	margin: 12px 0 0;

	color: var(--main-black);
	font-size: 14px;

	span {
		margin-left: 4px;

		color: var(--main-blue);
	}
}

.dislikeWrapper {
	justify-content: normal !important;
	align-items: flex-start !important;
	& > div {
		&:first-child {
			margin-right: 44px;

			white-space: nowrap;
		}
	}
}

.dislike {
	padding-top: 24px !important;
	margin-top: 24px;
	padding-left: 0 !important;
	padding-right: 0 !important;

	border-top: 1px solid var(--main-blue-secondary);
	border-radius: 0 !important;
}
