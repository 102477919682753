.root {
  > div > div > span {
    margin-right: 12px !important;
  }
}

.noPaidServices {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: var(--main-black);
  font-weight: 500;
  line-height: 24px;
}