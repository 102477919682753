@import "styles/vars.scss";

.root {
  padding: 25px 40px 0;
  width: 800px;
  min-height: 100vh;

  @include mbDesktopBreakpoint {
    width: 500px !important;
    padding: 25px 10px 0 !important;
  }

  h2 {
    margin: 0 0 28px;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: var(--main-black);
  }
}

.buttonMap {
  background: #336BEB;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: sticky;
  top: 78vh;
  border-radius: 50%;
  left: 440px;
  z-index: 10000;

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}
