@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;

	> :first-child {
		margin-top: 0px;
	}

	> div {
		margin-top: 15px;
	}

	&:hover {
		.editIcon,
		.removeIcon {
			opacity: 1;
		}
	}
}

.pin {
	margin-bottom: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40px;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	color: var(--main-blue);

	font-size: 14px;

	cursor: pointer;

	svg {
		margin-right: 10px;
	}
}

.editIcon,
.removeIcon {
	opacity: 0;
	transition: 0.2s;
}

.headlineContainer {
	margin: 24px 0 4px;

	display: flex;
	align-items: center;

	button {
		padding: 8px;

		display: flex;
		align-items: center;

		background: none;
		border: none;

		cursor: pointer;

		font-family: $default-font;
		color: var(--accent-red);
		font-weight: 500;
		font-size: 14px;

		path {
			fill: var(--main-blue-secondary);
			transition: 0.2s;
		}

		&:hover {
			path {
				fill: var(--main-blue);
			}

			.removeIcon {
				path {
					fill: var(--accent-red);
				}
			}
		}
	}

	h4 {
		margin: 0 8px 0 0 !important;
	}
}

.pinButtonsContainer button:last-child {
	margin-bottom: 0;
}

.pinButton {
	padding: 8px;
	margin-bottom: 8px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	background: var(--main-blue-disabled);
	border: none;
	border-radius: 10px;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 24px;
	color: var(--main-blue);

	svg {
		margin-right: 8px;
	}
}
