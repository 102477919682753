@import "styles/vars.scss";
@import "styles/keyframes";

.root {
	padding-left: $sidebar-opened-width;

	display: flex;
	flex-direction: column;

	@include mdDesktopBreakpoint() {
		padding-left: $sidebar-closed-width;
	}
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.firstLine {
	display: flex;

	height: 50px;

	background: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.filterWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-right: 20px;
	padding-left: 17px;
	border-right: 1px solid var(--main-blue-inactive);

	&:last-child {
		border-right: none;
	}
}

.searchWrapper {
	width: 421px;

	& > div {
		&:first-child {
			width: 100%;
		}
	}
}

.typeWrapper {
	width: 194px;
}

.responsibleWrapper {
	width: 214px;
}

.withActiveWrapper {
	justify-content: space-between;

	width: 339px;

	& > label {
		&:nth-child(2) {
			margin-right: 18px;
		}
	}

	& > div {
		&:first-child {
			margin-right: 26px;

			color: var(--main-black);
			font-weight: 500;
			font-size: 14px;
		}
	}
}

.submitBtn {
	position: absolute !important;
	right: 8px;
	top: 58px;
}

.resetBtn {
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}
