@import "styles/vars.scss";

.root {
  display: flex;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}

.mainImg {
  display: flex;
  width: 100px;
  aspect-ratio: 1;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noImg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-blue-inactive);
}

.main {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 12px 40px 12px 16px;
}

.price {
  margin: 0.5rem 0 0;
}

.title,
.address {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title,
.price {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-black);
}

.address {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--main-grey);
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cardContent {
  margin-right: 18px;

}

.responsible {
  margin-right: -18px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;

  &.contentDraggable {
    padding-right: 24px;
  }
}

.stage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 26px;
  height: 100%;
  border-radius: 0 var(--ck-border-radius) var(--ck-border-radius) 0;

  align-items: center;
  display: flex;
  justify-content: center;
}

.stageCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.stagePopup {
  top: unset !important;
  bottom: 50% !important;
  left: unset !important;
  right: 4% !important;
  padding: 12px !important;
  max-width: 360px !important;
  transform: none !important;
  width: max-content;
  font-size: 13px !important;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.atCompilation {
  border-left: 1px solid;
  width: 25px;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  max-height: 100px;
}

.hasPreApplication {
  width: 26px;
  background-color: #D5D3CE;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  max-height: 100px;
}
