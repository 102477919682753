@import "styles/vars.scss";

.textarea {
	margin-top: 10px;
	padding: 8px 16px;

	background: var(--main-blue-disabled);

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
}
