.bodyRoot {
	margin-top: 12px;
	padding: 8px 30px;

	background: var(--main-blue);
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #fff;

	display: flex;
	width: fit-content;

	cursor: pointer;

	border: none;
	border-radius: 404px;
}
