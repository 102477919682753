@import "styles/vars.scss";

.root {
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 476px!important;
  }
}

.modal {
  position: absolute;
  bottom: 44px;
  width: 500px!important;
  padding: 10px 10px 0px 10px!important;
  border-radius: 20px 20px 0px 0px!important;
  overflow-y: unset!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF!important;
    }
  }
}


.wrapper {

}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 12px;

  @include mbDesktopBreakpoint {
    font-family: Cera Pro;
    font-size: 20.75px!important;
    line-height: 24.9px!important;
    letter-spacing: 0.15963304042816162px!important;
  }
}

.tabsRow {
  display: flex;
  gap: 12px;
  margin-bottom: 32px;

  @include mbDesktopBreakpoint {
    gap: 8px!important;
  }
}

.tab {
  height: 40px;
  width: 235px;
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
  background: #F2F2F2;
  display: flex;
  flex-flow: column;

  @include mbDesktopBreakpoint {

  }

  .tabLabel {
    color: #ABC2F7;
    font-family: Cera Pro;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }

  .tabLabelExist {
    font-family: Cera Pro;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #336BEB !important;
  }

  .tabValue {
    font-family: Cera Pro;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #BFBFC0;

    @include mbDesktopBreakpoint {
      max-width: 127.67px!important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .tabValueExist {
    font-family: Cera Pro;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #1E1E1F !important;
  }
}

.currentTab {
  background: #30CFB1 !important;

  .tabLabel {
    color: #FFFFFF !important;
  }

  .tabValue {
    color: #FFFFFF !important;
  }
}

.infoRow {
  padding: 0 10px 4px;

  min-height: 0;

  .label {
    min-width: 296px !important;
  }
}

.formList {

}

.calendarChild {
  margin-bottom: 29px !important;

  @include mbDesktopBreakpoint {
    margin-bottom: 49px!important;
  }

  & > div {
    &:last-child {
      margin-left: -46px;
    }
  }
}

.selectedContact {
  border: 2px solid #336BEB;
  border-radius: 4px;
  margin-bottom: 32px;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}


.timeRoot {
  margin-left: 21px;
}

.timeInputs {
  font-weight: 500 !important;
}

.arrow {
  svg {
    path {
      fill: #BFBFC0;
    }
  }
}

.selectRoot {
  width: 50% !important;
}

.timeWrapper {
  border: none !important;
  padding: 0;

  input {
    font-weight: 500 !important;
  }
}

.plug {
  background: #F2F2F2 !important;
  width: 720px !important;
  margin-bottom: 32px;

  .text {
    color: #BFBFC0 !important;
  }

  @include mbDesktopBreakpoint {
    width: 476px!important;
    margin-bottom: 52px!important;
  }
}

.error {
  text-align: right;
  font-size: 12px;
  color: var(--accent-red);
  position: absolute;
  right: 0;
  top: 30%;
}

.applicationList {
  display: flex;
  flex-flow: column;
  gap: 12px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 12px;
  padding-left: 16px;
  width: 750px;
  margin-left: -15px;
  padding-top: 16px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #BFBFC0;
  }

  &::-webkit-scrollbar-track {
    background: #F2F2F2;
  }

  @include mbDesktopBreakpoint {
    width: 500px!important;
  }
}

.application {
  //width: 720px;
  height: 104px;
  border-radius: 10px;
  background: var(--BW-5, #FFF);
  padding: 8px 20px 7px;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.1);

  @include mbDesktopBreakpoint {
    padding: 0!important;
  }
}

.applicationSelected {
  border: 2px solid #336BEB;
}

.subtitle {
  font-family: Cera Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 8px;
}

.calendarLabel {
  flex-direction: row-reverse;

  svg {
    margin-right: 0 !important;
    margin-left: 20px;

    path {
      fill: var(--main-blue-secondary);
    }
  }
}

.calendar {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  position: absolute;
  bottom: -36px;
  left: 0;
  width: 800px;
  height: 64px;
  padding: 12px 40px 12px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 30px 0px #2224281A;
  background: #FFFFFF;
  border-radius: 0 0 12px 12px;

  @include mbDesktopBreakpoint {
    width: 500px!important;
    border-radius: 0!important;
  }
}

.row {
  height: 45px;
  padding: 10px 0 14px 11px !important;
}

.videoLink {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  cursor: pointer;
  color: #336BEB;
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    path {
      fill: #336BEB;
    }
  }
}

