.root {
	padding: 0 17px 0 11px;

	font-weight: 500;
	font-size: 14px;

	.content {
		border-bottom: 1px solid #f2f4fd;
	}

	&:last-child .content {
		border-bottom: none;
	}
}

.rootActive {
	position: relative;
	z-index: 100;

	box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
	border-radius: 10px;
	border: none;
	background-color: #fff;
}

.content {
	display: flex;
	align-items: center;
	justify-content: space-between;

	user-select: none;
}

.checkboxHide {
	display: none;
}

.checkboxFilled,
.checkboxEmpty {
	margin-right: 17px;

	width: 19px;
	min-width: 19px;
	height: 19px;
}

.checkboxEmpty {
	display: inline-block;

	border: 2px solid var(--main-blue-secondary);
	border-radius: 4px;
}

.label {
	padding: 12px 5px 12px 14px;

	display: flex;

	cursor: pointer;
}

.bar {
	padding: 12px 8px;

	display: flex;

	cursor: grab;
	border-radius: 5px;

	&:active {
		cursor: grabbing;
	}

	&:hover {
		background: var(--main-grey-border);
	}
}
