@import "styles/vars.scss";

// Details section
.detailsWrapper {
  display: flex;
  justify-content: space-between;

  > div {
    flex-basis: 50%;

    &:last-child {
      margin-left: 56px;
    }
  }

  p {
    margin: 0;
  }
}

.detailsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  > p {
    color: var(--main-black);
    font-size: 14px;

    &:first-child {
      opacity: 0.5;
    }

    &:last-child {
      font-weight: 500;
    }
  }
}

.radioButton {
  margin-top: 28px;
  margin-bottom: 12px;
  label {
    border-radius: 100px;
    font-family: $ubuntu-font;
    font-weight: 400;
    height: 28px;
  }
  label[class^="RadioButton_checked"] {
    font-weight: 500;
    color: white !important;
  }
}

.root {
  p {
    margin: 0;
  }

  > div > div > span {
    margin-right: 12px !important;
  }
}

.label {
  display: flex;
  > p:first-child {
    margin-right: 8px;
  }
}

.arrow {
  * {
    fill: var(--main-blue);
  }
}

.popupSelectedOption {
  color: var(--main-blue);
}

.popupBody {
  p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    color: #9696A2;
    cursor: pointer;

    &:first-child {
      margin-bottom: 6px;
    }
  }
}

.detailsContainer {
  display: flex;
}

.unknownInfo {
  color: #9696A2;
  font-weight: 500;
  font-size: 14px;
}