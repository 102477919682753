.content {
  display: flex;
  flex-flow: column;
  padding: 4px 8px;
}


.contentBody {
  height: 552px!important;
  padding: 0 0 0 8px;
}

.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 20px 20px 40px 20px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    path {
      fill: #FFFFFF!important;
    }
  }
}