.takeErrorContainer p,
.textTimeIsOver {
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	color: var(--main-grey-disabled);
}

.textTimeIsOver {
	max-width: 150px;
}

.takeErrorContainer p {
	margin: 0;
	max-width: 130px;
}

.takeErrorContainer {
	display: flex;

	svg {
		margin-right: 8px;

		width: 16px;
		height: 16px;
	}
}
