@import "styles/vars.scss";

.root {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 7px 13px 4px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  transition: filter 0.2s;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}

.onActivePopup {
  z-index: 100;
}


.hasPreApplication {
  width: 26px;
  background-color: #D5D3CE;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  max-height: 100px;
}

.commission {
  color: #336BEB;
}

.linear {
  background: linear-gradient(#7F8DEF, #BC9CFF);
}

.atCompilation {
  border-left: 1px solid;
  width: 25px;
  margin: -7px -13px;
  border-radius: 0 10px 10px 0;
  max-height: 100px;

  svg {
    position: absolute;
    top: 36px;
  }

  @include mbDesktopBreakpoint {
    height: 96px !important;
    margin: -7px -36px !important;

    svg {
      position: absolute;
      top: 36px !important;
    }
  }
}

.isMini {
  svg {
    top: 28px;
  }
}

.selected {
  border-left: 1px solid;
  width: 23px !important;
  margin: -5px -11px !important;
  max-height: 96px !important;
  border-radius: 0 8px 8px 0 !important;
}

.selectedCard {
  padding: 5px 11px;
  border: 2px solid var(--main-blue);

  .img img {
    border: 2px solid var(--main-blue);
    border-right: none;
  }
}

.priceFluct {
  margin: 0 !important;
}

.price {
  text-align: right;
  white-space: nowrap;
}

.root:hover {
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
  z-index: 1;
  background: #fff;
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 9px;
  right: 29px;
}

.withPopup {
  padding-right: 55px;
}

.atPinTab {
  padding-right: 17px;
}

.background_blue {
  background: var(--main-blue-disabled);
}

.background_white {
  background: #fff;
}

.img {
  margin: -7px 10px -7px -13px;
  position: relative;
  width: 108px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    object-fit: cover;
  }

  svg {
    position: absolute;
    margin: 4px;
  }
}

.noImg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  svg {
    width: 48px;
    height: 55px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;

  &.contentDraggable {
    padding-right: 24px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flexRowAlignStart {
  align-items: flex-start;
}

.titleAndPrice {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--main-black);
}

.content {
  padding-right: 20px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  .notification {
    //margin-left: -20px;
    height: 18px;
  }
}

.tooltipInfo {
  margin: 0 4px 0 8px !important;
}

.tooltipWarn {
  margin: 0 8px 0 4px !important;
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.textTooltip {
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #FFFFFF;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  background: #3F3F40 !important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 6px !important;
  }
}

.tooltipActiveInfoLess {
  padding: 12px 8px 12px 36px !important;
  width: 360px;

  svg {
    left: 9px !important;
    top: 10px !important;
  }
}

.infoIconRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon {
  margin: 0 6px 0 0;
}

.address {
  font-family: $ubuntu-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--main-grey);

  span {
    color: #878787;
  }
}

.addressLabel {
  display: -webkit-box;
  max-width: 80%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineThrough {
  text-decoration: line-through solid var(--main-black-dark);
}

.withPadding {
  padding-right: 10px;
}

.stage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 26px;
  height: 100%;
  border-radius: 0 var(--ck-border-radius) var(--ck-border-radius) 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.stageCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  width: 18px !important;
  height: 18px !important;
}

.stagePopup {
  top: unset !important;
  bottom: 50% !important;
  left: unset !important;
  right: 4% !important;
  padding: 12px !important;
  max-width: 360px !important;
  transform: none !important;
  width: max-content;

  font-family: "Ubuntu", sans-serif;
  font-size: 13px !important;
  line-height: 15.6px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
