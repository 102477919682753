.objectItemWrapper {
  margin-bottom: 12px;
  max-width: 720px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.root {
  background: var(--BW-5) !important;
}

.loaderContainer {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  padding-right: 30px;
  background: var(--BW-5);
  border: 1px #BFBFC0 solid;
  display: flex;
  height: 50px;

  input {
    background: var(--BW-5);
  }
}

.noFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--BW-3, #BFBFC0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.objectListWrapper {
  padding-right: 12px;
  padding-left: 16px;
  max-height: 312px;
  height: 312px;
  overflow-y: auto;
  margin-top: 20px;

  &:first-child {
    margin-top: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.pinObject {
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;

    margin-top: 20px;
  }

  & > div {
    &:first-child {
      margin-top: 20px;
      padding: 12px 20px;
    }
  }
}
