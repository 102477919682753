.wrapper {
	position: absolute;
	right: 0;
	z-index: 10000;

	height: 100%;
	max-height: 100%;
}

.mobile {
	height: calc(100% - 50px)!important;
	max-height: calc(100% - 50px)!important;
}

.drawerBackground {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;

	background: rgba(30, 30, 31, 0.90);

	&.lowPerf {
		background: rgba(30, 30, 31, 0.90);
	}
}
