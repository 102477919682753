@import "styles/vars.scss";

.textInfo {
	margin-bottom: 5px;

	word-break: break-word;
	font-size: 14px;
	line-height: 20px;
}

.textInfoSum {
	font-weight: 500;
	font-family: $default-font;
}
