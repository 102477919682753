.root {
  position: absolute;
  right: 0;
  z-index: 2;
  background: white;
  width: 500px;
}

.addressSelect {
  border: 0!important;
}

.infoRow {
  border: 0!important;
}