@import "styles/vars.scss";

.container {
  margin: 0 auto;
  padding: 0 0 35px;
  max-width: 720px;
}

.tableContainer {
  padding-bottom: 67px;

  @include mbDesktopBreakpoint {
    width: 480px;
    padding-left: 20px;
  }
}

// .contactsTableRow .contactsTableCol:first-child,
// .contactsTableRow .contactsTableCol:last-child {
// 	max-width: 35px;
// }

.filters {
  padding: 15px 15px 15px 235px;
  height: 50px;
  background: #fff;

  @include mdDesktopBreakpoint() {
    padding: 15px 15px 15px 85px;
  }
}

.tableGroupTitle {
  opacity: 0.5;
}

.pagination {
  justify-content: center;
}

.row {
  padding: 0 !important;
  width: 720px;
}

.isMobile {
  width: 440px!important;
  box-shadow: none!important;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 100px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #BFBFC0;

  @include mbDesktopBreakpoint {
    width: 440px !important;
    height: 96px !important;
  }
}