@import "styles/vars.scss";

.button {
	background: var(--main-blue-disabled);
	border: none;
	border-radius: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;

	color: var(--main-blue);
	font-family: "Cera Pro";
	font-size: 14px;

	.icon {
		transition: all $default-transition-duration;
		opacity: 0.5;
	}
}

.button:hover {
	.icon {
		opacity: 1;
	}
}

.button:active {
	color: #5a6ade;

	.icon {
		path {
			fill: #5a6ade;
		}
	}
}

.button:focus {
	border: 2px solid var(--accent-orange);
}

.big {
	width: 100%;
	max-width: 600px;
	height: 72px;

	font-weight: 500;
}

.small {
	width: 100%;
	max-width: 360px;
	height: 40px;

	font-family: $ubuntu-font;
}
