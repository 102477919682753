@import "styles/vars.scss";

.modalBody {
	p {
		margin: 8px 0 0;

		max-width: 250px;

		font-family: $ubuntu-font;
		font-size: 12px;
		line-height: 18px;
		color: var(--accent-red);
	}
}

.btnContainer {
	margin: 40px auto 0 auto;

	display: flex;
	justify-content: center;

	.btnArhive {
		margin-left: 10px;
	}
}
