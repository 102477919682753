@import "styles/vars.scss";

.title {
  color: var(--BW-0, #1E1E1F);
  font-family: Cera Pro;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
  margin-right: 8px;
}

.row {
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 12px;
}

.titleEdit {
  justify-content: space-between;
}

.edit {
  margin-left: 16px;
  padding: 4px;

  cursor: pointer;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;

  color: var(--main-blue);


  svg {
    margin-right: 10px;
    opacity: 0.5;
  }
}

.edit:hover {
  svg {
    opacity: 1;
  }
}

.textareaWrapper {
  margin-bottom: 8px;
}

.saveText {
  svg {
    fill: #336BEB;
  }

  width: 24px;
  position: relative;
  right: -684px;
  top: -26px;
  cursor: pointer;
  transition: 0.1s;
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -70px !important;
  background: #3F3F40!important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}

.viewModeTitle {
  margin-bottom: 4px;

  font-family: $ubuntu-font;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-grey);
}

.viewModeDescription {
  margin-bottom: 8px;

  font-family: $ubuntu-font;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-grey);

  ol,
  ul,
  p {
    margin: 0;
  }

  ol,
  ul {
    padding-left: 15px;
  }
}

.commentPlaceholder {
  color: var(--main-grey);
  opacity: 0.5;
}
