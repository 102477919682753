@import "styles/vars.scss";

.officeName {
	font-weight: bold;
	font-size: 20px;
	max-width: 100%;
}

.officeNameRow {
	display: flex;
	align-items: center;
	word-break: break-all;
}

.employers {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--main-blue-secondary);
	margin-top: 1px;
	font-family: $ubuntu-font;
	font-weight: normal;

	svg {
		opacity: 1;
		margin-left: 14px;
		margin-right: 4px;
	}
}
