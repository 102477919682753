@import "styles/vars.scss";

.wrapper {
	@include flex-align-items-center;
	justify-content: space-between;
	border: 1px solid var(--main-blue-secondary);
	box-sizing: border-box;
	border-radius: 100px;
	padding: 8px 22px;
	max-height: 35px;
	background-color: #fff;
	margin-right: 30px;

	input {
		padding: 0;
		border: none;
		width: 40px;
		font-weight: 400;
		font-size: 14px;
		background-color: initial;
		color: var(--main-black);
		font-family: $default-font;
		&::placeholder {
			color: var(--main-blue-secondary);
		}
	}
}

.noLabel {
	.prepend {
		display: none;
	}
}

.prepend {
	font-family: $ubuntu-font;
	color: var(--main-black);
	font-size: 14px;
	margin-right: 8px;
}

.inputs {
	@include flex-align-items-center;
	position: relative;
	background: var(--BW-5);
}

.input {
	line-height: 20px;
	&:disabled {
		cursor: not-allowed;
		background-color: transparent;
	}
}

.error {
	position: absolute;
	margin-top: 12px;
	color: var(--accent-red);
	font-size: 12px;
	margin-left: -26px;
}
