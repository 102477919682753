@import "styles/vars.scss";

.root {
	margin: 80px 0;

	@include mbDesktopBreakpoint {
		margin-top: 40px!important;
	}
}

.collapseLabel {
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 26px;
	line-height: 36px;

	svg {
		margin-right: 8px;
	}

	path {
		fill: var(--accent-green);
	}
}
