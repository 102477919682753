@import "styles/vars.scss";

.container {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 102;

	border-bottom: 1px solid var(--main-blue-inactive);
}

.navigation {
	display: flex;
	flex-direction: row;

	overflow: hidden;

	&::-webkit-scrollbar {
		display: none;
	}
}

.rightArrow,
.leftArrow {
	position: absolute;
	top: 0;
	bottom: 1px;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	user-select: none;

	svg {
		fill: var(--main-blue-40);

		transition: 0.2s;

		width: 30px;
		height: 24px;
	}

	&:hover {
		svg {
			fill: var(--main-blue);
		}
	}
}

.leftArrow svg {
	transform: rotate(90deg);
}

.rightArrow svg {
	transform: rotate(-90deg);
}

.navItem {
	padding: 11px 12.5px 16px;

	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	user-select: none;
	cursor: pointer;
	white-space: nowrap;
	color: var(--main-blue);
	transition: border-top-color 0.1s;

	text-transform: capitalize;

	&.navItemActive,
	&:hover {
		padding: 11px 12.5px 16px;
		border-top: 5px solid var(--accent-yellow);
		// color: #000;
	}
	&.navItemHidden {
		display: none;
	}
}

.label {
	padding: 16px 12.5px;

	font-family: $ubuntu-font;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: var(--main-blue-secondary);
	white-space: nowrap;
}

.fixedNavContainer {
	display: flex;
	justify-content: space-between;
}

.notNavigation {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.containerWhite {
	background: #fff;
}

.containerGrey {
	background: var(--main-blue-disabled);
}

.navItemWhite {
	border-top: 5px solid #fff;
}

.navItemGrey {
	border-top: 5px solid var(--main-blue-disabled);
}

.arrowWhite {
	background: #fff;
}

.arrowGrey {
	background: var(--main-blue-disabled);
}
