@import "styles/vars.scss";

.loadingName,
.loadingRow,
.loadingTitle,
.loadingBlock {
	@include skeleton();

	margin-bottom: 12px;
}

.loadingNav {
	@include skeleton();

	height: 50px;
	width: 100%;

	border-radius: 0px;
}

.loadingContent {
	padding: 30px 40px;
}

.loadingName {
	height: 40px;
	width: 80%;
}

.loadingRow {
	height: 40px;
	width: 100%;
}

.loadingTitle {
	margin-top: 90px;

	height: 30px;
	width: 30%;
}

.loadingBlock {
	height: 60px;
	width: 100%;
}
