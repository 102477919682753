.contentWrapper {
	padding: 28px 40px!important;

	h3 {
		font-size: 26px;
	}
}


.contentWrapperMobile {
	position: absolute;
	bottom: 0;
	width: 500px!important;
	padding: 20px 15px 40px 15px!important;
	border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
	background: #1E1E1F!important;
	border: 0!important;
	svg {
		left: -3px!important;
		path {
			fill: #FFFFFF!important;
		}
	}
}
