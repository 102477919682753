@import "styles/vars.scss";

.root {
	margin-top: -64px;
	padding: 0 40px;
}

.h2 {
	justify-content: flex-start !important;

	button {
		margin-left: 20px;

		svg {
			margin-right: 8px;
		}
	}
}

.comment,
.object {
	margin-top: 80px;
}

.responsibles {
	margin-top: 60px;
}

.comment {
	word-break: break-word;
}

.sellerLocal {
	margin-top: 40px;
}

.buttonSaveContainer {
	padding: 12px 20px;

	position: fixed;
	bottom: 0;
	right: 4px;
	z-index: 10000;

	display: flex;
	justify-content: flex-end;

	width: 796px;

	background: #fff;
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
}

.bottomAlert {
	padding-right: 40px;

	position: sticky;
	top: calc(100% - 64px);
	z-index: 10000;

	height: 64px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: 12px;

	background: #fff;
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));

	span {
		font-family: $ubuntu-font;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: var(--main-grey-inactive);
	}
}

.arrowIcon {
	width: 16px;
	height: 16px;

	transform: rotate(225deg);

	path {
		fill: var(--main-grey-inactive);
	}
}

.alertIcon path {
	fill: var(--accent-yellow) !important;
}
