.root {
	padding: 40px;

	background: var(--main-blue-disabled);
}

.photoAndWork {
	display: flex;
}

.photoBlock {
	margin: 0 20px 30px 0;

	width: 280px;

	img {
		width: 100%;
		height: 306px;

		border-radius: 10px;

		object-fit: cover;
	}
}

.h3 {
	margin: 0 0 12px !important;
}

.noPhoto {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 306px;

	border-radius: 10px;
	background: var(--main-blue);

	p {
		font-size: 68px;
		color: #fff;
		font-weight: bold;
	}
}
