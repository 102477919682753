.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 20px 20px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF!important;
    }
  }
}

.wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}