@import "styles/vars.scss";

.root {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  width: 432px;
}

.invitesList {
  height: 210px;
  margin-bottom: 57px;
  overflow-y: scroll;
  padding-right: 40px;
}

.invitesListItem {
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invitesListItemCompany {
  display: flex;
  align-items: center;

  width: 50%;
  max-width: 50%;
}

.invitesListItemManipulation {
  display: flex;
}

.invitesListItemDecline {
  margin-left: 20px;
}

.invitesListItemName {
  max-width: 166px;
  overflow: hidden;
  color: var(--main-black);
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.invitesListItemInfo {
  margin-left: 10px;
  display: flex;
  cursor: pointer;

  svg {
    transform: scale(0.8);
    fill: var(--main-grey-disabled);
  }

  &:hover svg {
    fill: var(--main-blue);
  }
}

.continueBtnContainer {
  width: 425px;

  .button {
    min-width: 222px;
  }
}
