@import "styles/vars.scss";

.root {
	display: flex;
	padding: 12px 20px;
	background-color: #fff;
	border-radius: 100px;
	align-items: center;
	font-family: $ubuntu-font;
}

.icon {
	margin-right: 10px;
	display: flex;
	align-items: center;
}
//

.success {
	border: 2px solid var(--accent-green);
	path {
		fill: var(--accent-green);
	}
}

.wait {
	border: 2px solid var(--accent-orange);
	path {
		fill: var(--accent-orange);
	}
}

.error {
	border: 2px solid var(--accent-red);
	path {
		fill: var(--accent-red);
	}
}

.text {
	font-size: 14px;
	color: var(--main-blue);
	line-height: 20px;
}
