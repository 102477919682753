@import "styles/vars.scss";

.wrap {
	display: flex;
	flex-direction: column;
	gap: 18px;
}

.block {
	display: flex;
	flex-direction: row;
	gap: 47px;
}
h1 {
	color: #2D2C46;
	font-family: Cera Pro;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
	letter-spacing: 0.2px;
	padding: 0;
	margin: 0;
	margin-bottom:4px;
}
h2 {
	margin-bottom: 12px;
}
p {
	padding: 0;
	margin: 0;
	color: #2D2C46;
	font-family: Ubuntu;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.2px;
}

.blockButtons {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.blockButtons h2 {
	padding-right: 130px;
}
.buttons {
	// width:100%;
	gap:4px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.buttons button {
	width: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
}