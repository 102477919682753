@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	min-height: 24px;
	font-size: 12px;
	line-height: 16px;
	color: #7e7e7e;
}


.owner {
	display: flex;
	flex-direction: row;
	align-items: center;
	max-width: 300px;
	padding-right: 17px;

	.ownerLogo {
		position: relative;
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);

	}

	span {
		max-width: 157px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 20px;
	}
}

.iconBlock {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-height: 24px;
	max-width: 390px;
	font-weight: 500;
	font-size: 12px;
	line-height: 14.4px;
	letter-spacing: 0.2px;

	.svgIcon {
		display: flex;
		margin-right: 5px;
	}

	.other {
		background-color: #EDEDED;
		width: 24px;
		height: 24px;
		-moz-border-radius: 50px;
		-webkit-border-radius: 50px;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		display: flex;

		span {
			color: #9595A3;
		}
	}
}

.contact {
	color: #336BEB;
	font-size: 13px;
	font-weight: 400;
	line-height: 15.6px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 4px;
}

.contactText {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 162px;
}

.avatar {
	height: 24px;
	img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}
}

.ownerText {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;


	@include mbDesktopBreakpoint {
		max-width: 95px;
	}
}

.responsible {
	.responsibleLogo {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		position: relative;
	}

	svg {
		margin-right: 3px;
		margin-left: 3px;
		width: 24px;
		height: 24px;
	}
}

.owner {
	.iconContainer {
		margin-left: 7px;
		position: relative;
		width: 24px;
		height: 24px;
		border-radius: 100px;
		background: var(--main-blue);
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.tooltipRoot {
	margin: 0 !important;
}

.tooltipHoveredLabel {
	color: #fff;
}

.tooltipContent {
	margin-top: 10px;
	font-weight: normal !important;
	font-size: 12px !important;
	color: #fff;

	> div {
		margin-top: 3px;
	}
}
