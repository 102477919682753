@import "styles/vars.scss";

.countStatusInfoContainer {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	margin-top: 16px;
	& > * {
		margin-right: 20px;
	}
}

.smallCountInfo {
	margin-top: -4px;
	font-size: 14px;
	margin-right: 40px;
	&:last-child {
		margin-right: 0;
	}
}

.smallCountInfoTitle {
	font-family: $ubuntu-font;
	color: var(--main-black);
	opacity: 0.5;
	margin-right: 20px;
}

.smallCountInfoValue {
	font-weight: 500;
	&.success {
		color: var(--accent-green);
	}

	&.wait {
		color: var(--accent-orange);
	}

	&.error {
		color: var(--accent-red);
	}
}
