@import "styles/vars.scss";

.header,
.blockItem {
	@include skeleton();
}

.root {
	.block:first-child {
		.header {
			margin-top: 30px !important;
		}
	}
}

.block {
	padding: 0;

	.header {
		margin-top: 80px;
		margin-bottom: 12px;

		width: 100%;
		height: 32px;
	}

	.blockItem {
		width: 100%;
		height: 80px;
	}
}
