.popupContent {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.wrapper {
	position: relative;

	display: flex;
}

.error {
	position: absolute;
	left: 0;
	bottom: 0;

	transform: translateY(100%);
margin-left: 10px;
	color: var(--accent-red);
	font-size: 12px;
}

.label {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	svg {
		transform: translateY(-2px);
		margin-right: 12px;
	}

	&.notSelectedDate {
		color: var(--main-blue-secondary);
	}
}

.popupWrapper {
	width: auto !important;
}

.card {
	width: 271px;
	height: 265px;
	padding: 7px 24px!important;
}
