@import "styles/vars.scss";

.root {
height: 100%;
}

.content {
  padding: 24px 40px;

  @include mbDesktopBreakpoint {
    padding: 24px 30px!important;
  }
}

.application {
  box-shadow: 0 0 30px 0 #2224281A;
  width: 720px;
  height: 100px;
  border-radius: 10px;
  background: var(--BW-5, #FFF);
  padding: 8px 20px 4px;
  margin-bottom: 24px;
  cursor: pointer;

  @include mbDesktopBreakpoint {
    width: 440px!important;
    padding: unset!important;
    height: 96px!important;
  }
}

.task {
  margin-top: 12px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 60px;
}

.user {
  margin-top: 12px;
}

.footer {
  border-top: #F2F2F2 1px solid;
  height: 102px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px 40px 24px 40px;

  @include mbDesktopBreakpoint {
    flex-wrap: wrap;
    height: unset!important;
  }

  .label {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #8B8B8D;
    width: 173px;
  }

  .text {
    width: 173px;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #1E1E1F;
  }

  .link {
    font-family: Ubuntu;
    width: 173px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #336BEB;
  }
}

.stickyFooter {
  position: sticky;
  height: 64px;
  display: flex;
  justify-content: end;
  padding: 12px 40px 12px 40px;
  align-items: center;
  box-shadow: 0px 0px 35px 0px #124BF659;
  background: #FFFFFF;
  gap: 32px;
  bottom: 0;

  .cancel {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #FF575F;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      path {
        fill: #FF575F;
      }
    }
  }
}

.wrapper {
  min-height: 100%;
  position: relative;
}
