@import "styles/vars.scss";

.root {
  display: flex;
  width: 720px;
  margin-left: -6px;
  padding: 24px 30px;
  align-items: flex-start;
  gap: 12px;
  border-top: 1px solid #BFBFC0;

  @include mbDesktopBreakpoint {
    width: 480px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  width: 161px;
  align-items: flex-start;
}

.label {
  color: #CCC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.link {
  color: #336BEB;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}