@import "styles/vars.scss";

.root {
  padding: 0 9px !important;

  @include mbDesktopBreakpoint {
    padding: 0 2px !important;
  }
}

.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 10px 10px 0px 10px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;

  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF!important;
    }
  }
}

.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;

  @include mbDesktopBreakpoint {
    margin-bottom: 32px;
  }
}

.buttonCreate {
  margin-left: 12px;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
  margin-left: 32px;
  margin-bottom: 50px;
  margin-top: 26px;
}

.title {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  letter-spacing: 0.2px;
}

.text {
  color: #2D2C46;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.2px;
}