.root {
  padding: 4px 8px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
}

.subTitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-bottom: 4px;
}

.search {
  border: 1px solid #BFBFC0;
  width: 720px;
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  height: 50px;
}

.list {
  height: 360px;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 40px;
  padding-top: 20px;
  width: 750px;
  padding-left: 20px;
}

.item {
}

.listWrapper {
  padding-bottom: 4px;
  margin-left: -20px;
}

.noFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--BW-3, #BFBFC0);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.button {
  display: flex;
  flex-flow: row;
  justify-content: end;
  align-items: center;
  height: 64px;
  width: 800px;
  border-radius: 0 0 20px 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
  z-index: 1;
  background: var(--BW-4, #F2F2F2);
  padding-right: 40px;
}