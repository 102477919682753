.root {
  max-width: 418px;

  h1 {
    margin-bottom: 6px;
  }
}

.tabs {
  margin-top: 13px;
}

.tab {
  max-width: 137px;
}
