.root {
  display: flex;
  flex-flow: column;
  padding: 20px;
}

.title {
  margin-bottom: 35px;
  font-family: Cera Pro;
  font-size: 20.75px;
  font-weight: 700;
  line-height: 24.9px;
  letter-spacing: 0.15963304042816162px;
  text-align: left;
}

.submitWrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: right;
  width: calc(100% - 40px);
  margin-bottom: 20px;
}