.card {
	padding: 16px 16px 16px 32px;
	height: 80px;
	border-radius: 10px;
	background: var(--main-blue-disabled);
	display: flex;
	align-items: center;
}

.info {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.name {
	font-size: 16px;
}

.phone {
	font-size: 16px;
	font-weight: 400;
}

.actions {
	display: flex;
	align-items: center;
}
