@import "styles/vars.scss";

.root {
  padding-left: $sidebar-closed-width;
  display: flex;
  flex-direction: column;
}

.expanded.root {
  padding-left: $sidebar-opened-width;
}

.firstLine {
  display: flex;

  height: 50px;

  background: #fff;
  border-bottom: 1px solid var(--main-blue-inactive);
}

.secondLine {
  display: flex;
}

.filterWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 20px;
  padding-left: 17px;
  border-right: 1px solid var(--main-blue-inactive);

  &:last-child {
    border-right: none;
  }
}

.filterBtn {
  color: var(--main-black);
  display: flex;
  align-items: center;

  svg {
    width: 25px !important;
    height: 25px !important;
    margin-right: 14px !important;
  }

  path {
    fill: var(--main-black) !important;
  }

  .filterBtnText {
    color: var(--main-blue);
    font-size: 12px;
  }
}

.submitWrapper {
  position: absolute;
  right: 66px;
  bottom: -30px;
}

.searchWrapper {
  padding-left: 0px;
  width: 216px;
}

.typeWrapper {
  width: 194px;
}

.responsibleWrapper {
  width: 214px;
}

.signedWrapper {
  width: 280px;
  height: 50px;
  background: #FFFFFF;
  border-bottom: 1px solid var(--main-blue-inactive);
}
.statusWrapper {
  width: 274px;
}

.filtersWrapper {
  display: flex;
  height: 30px;
}

.typeSelect {
  width: 100%;
}

.more {
  display: flex;
  justify-content: center;
  align-items: center;


}

.moreWrapper {
  width: 50px !important;
  height: 50px !important;
  background: var(--main-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px !important;
    height: 24px !important;

    path {
      fill: #FFFFFF !important;
    }
  }
}

.filterBtnText {
  color: var(--main-blue);
  font-size: 12px;
}

.resetBtn {
  height: 50px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  background: #BFBFC0;
  padding: 0px 17px !important;
  cursor: pointer;
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 20px!important;
  letter-spacing: 0.20000000298023224px;
  text-align: left;


  &:hover {
    background-color: #FF575F!important;
    div {
      color: #FFFFFF!important;
    }
  }

  svg {
    width: 30px!important;
    height: 30px!important;
  }
}

.cancelIcon {
  width: 36px !important;
  height: 36px !important;

  path {
    fill: var(--main-blue) !important;
  }
}

.resetBtn:hover {
  color: var(--accent-red) !important;

  span {
    opacity: 1;
  }

  .cancelIcon {
    path {
      fill: var(--accent-red) !important;
    }
  }
}
