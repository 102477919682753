.popupItem {
	margin: 7px 0;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
	cursor: pointer;
	transition: color 0.25s ease;
	display: flex;
	gap: 4px;
	align-items: center;

	&:hover {
		color: var(--main-blue);
	}
}

.popupItemDisabled {
	color: var(--main-blue-secondary);
	cursor: not-allowed;

	&:hover {
		color: var(--main-blue-secondary);
	}
}
