@import "styles/vars.scss";

.needMoreData {
	display: flex;
	align-items: center;
	margin-left: 40px;

	@include mbDesktopBreakpoint {
		margin-left: 0!important;
	}
}

.iconInfo {
	display: flex;
	align-items: center;
	color: var(--accent-red);
	font-size: 14px;
	font-family: $ubuntu-font;
	svg {
		margin-right: 8px;
		width: 20px;
		height: 20px;
		path {
			fill: var(--accent-red);
		}
	}
}

.addDataButton {
	margin-left: 16px;
}

.unfilledRequiredFields {
	p {
		margin: 0;
		white-space: pre;
		&:first-child {
			margin-bottom: 8px;
			font-size: 13px;
		}
	}
}
