@import "styles/vars.scss";

li {
  list-style-type: none;
}
ul {
  margin: 0;
  padding: 0;
}
.title {
  color: #2D2C46;
  font-family: 'Cera Pro';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}

.root {
  margin-top: 60px;

  @include mbDesktopBreakpoint {
    margin-top: 40px!important;
  }
}