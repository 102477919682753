@import "styles/vars.scss";

.root {
	height: calc(100% - 50px);
	overflow-y: auto;
	overflow-x: hidden;
}
.cardLead {
	display: flex;
	justify-content: center;

	@include mbDesktopBreakpoint {
		width: 440px;
		height: 80px;
		margin-bottom: 30px;
	}
}
.card_lead {
	margin-bottom: 30px;
}
.root {
	padding: 20px 20px;

	@include mbDesktopBreakpoint {
		padding: 20px 20px 20px 30px!important;
	}
}

.dealHeader {
	display: flex;
	flex-flow: column;
	width: 100%;
	gap: 8px;
	padding-left: 20px;

	@include mbDesktopBreakpoint {
		padding-left: 0!important;
	}

	.item {
		height: 100px;
		width: 720px;
		box-shadow: 0px 0px 30px 0px #2224281A;
		padding: 8px 23px 4px 12px;
		border-radius: 10px;
		cursor: pointer;

		@include mbDesktopBreakpoint {
			height: 96px!important;
			width: 440px!important;
			padding: 0!important;
		}
	}
}

