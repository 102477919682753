@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;

	column-gap: 4px;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	color: #fff;
}

.icon {
	width: 12px;
	height: 12px;
}

.label {
	text-transform: capitalize;
}
