.root {
  height: 50px;
  display: flex;
  flex-flow: row;
  box-shadow: 0px 0px 30px 0px #2224281A;
  padding: 13px 12px 13px 12px;
  background: #FFFFFF!important;
  position: relative;
  z-index: 99991;
  justify-content: space-between;
  border: #E3E9F6 1px solid;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
}

.arrow {
  min-width: 30px;
  display: flex;
  justify-content: left;
}

.buttonItem {
  padding:  14px 0;
  margin: 0;
  background: linear-gradient(270deg, #fcfcfc -13.33%, #ffffff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Cera Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-blue-secondary);

  svg {
    fill: var(--main-blue-secondary);
  }
}

.buttonItemActive {
  color: var(--main-black);

  svg {
    path {
      fill: #1E1E1F;
      stroke: #1E1E1F;
    }

    stroke: #1E1E1F;
    fill: #1E1E1F;
  }
}

.buttonItemDisabled {
  background: #f1f1f1;
  color: #ddd;
  cursor: not-allowed;
}

.buttonText {
  white-space: nowrap;
  margin-bottom: 4px;
}


