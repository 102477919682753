@import "styles/vars.scss";

.infoRow {
	padding: 0 10px 4px;

	min-height: 0;
}

.calendarChild {
	& > div {
		&:last-child {
			margin-left: -46px;
		}
	}
}

.flexRow {
	@include flex-align-items-center;
}

.calendarLabel {
	flex-direction: row-reverse;

	svg {
		margin-right: 0 !important;
		margin-left: 20px;

		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.calendar {
	height: 30px;

	@include flex-align-items-center;
}

.popupTrigger {
	padding: 12px 24px !important;
}

.timeRoot {
	margin-left: 21px;
}

.timeWrapper {
	border: none;
	padding: 0;
}
