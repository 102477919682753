.dateWrapper {
  display: flex;
}

.dateIcon {
  margin-right: 15px;
  margin-top: 2px;

  path {
    fill: var(--main-blue-secondary);
  }
}

.title {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
margin-top: 30px;
}
