@import "styles/vars.scss";

.root {
	padding: 20px 0 54px;

	display: flex;
	align-items: center;
	justify-content: center;

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;

	background: #fff;
	border-top: 1px solid rgba(45, 44, 70, 0.1);

	span {
		margin-left: 12px;

		font-weight: 500;
		color: var(--main-blue);
	}
}
