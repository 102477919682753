.red {
	path {
		fill: var(--accent-red);
	}
}

.gray {
	path {
		fill: var(--main-grey-inactive);
	}
}
