@import "styles/vars.scss";

.root {
  margin-top: 32px;
}

.radio {
  display: flex;
  align-items: center;
}

.radioLabel {
  flex: 1 1;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--main-black);
}

.radioButton {
  margin-left: 16px;
  flex: 1 1;
}

.select {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
}

.selectLabel {
  flex: 7 1;
  font-family: $ubuntu-font;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: var(--main-grey-sub);
}

.selectButtons {
  flex: 10 10;
}
