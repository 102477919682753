@import "styles/vars.scss";

.root {
	display: flex;
	align-items: flex-start;

	@include mbDesktopBreakpoint {
		background: #F2F2F2!important;
	}
}

.icon {
	padding: 10px 28px 0 18px;

	svg {
		fill: var(--main-blue-secondary);
		height: 31px;
		width: 31px;
		path {
			fill: var(--main-blue-secondary);
		}
	}
}

.children {
	padding: 17px 45px 15px 0;

	color: var(--main-grey-inactive);
	font-family: $ubuntu-font;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
}
