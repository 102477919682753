@import "styles/vars.scss";

.root {
	display: flex;
	align-items: center;
	column-gap: 0.75rem;
}

.container {
	padding: 2px 12px;

	display: flex;
	align-items: center;
	column-gap: 12px;

	border-radius: 100px;
	background: var(--main-blue-disabled);
}
