@import "styles/vars.scss";

.root {
  padding: 25px 30px 25px 30px;

  @include mbDesktopBreakpoint {
    padding-bottom: 0!important;
  }
}

.header {
  font-size: 26px;
  margin-top: 0;
  color: var(--main-black);
}

.content {
  display: flex;
  width: 100%;
}

.leftBlock {
  min-width: 440px;
  width: 100%;
}

.actions {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: end;
  box-shadow: 0px 0px 30px 0px #2224281A;
  height: 64px;
  padding-right: 30px;
  position: absolute;
  width: 500px;
  left: 0;
  bottom: 0;
  background: #FFFFFF;

  @include mbDesktopBreakpoint {
    position: sticky;
    margin-left: -30px;
  }
}



.cancelBtn {
  display: flex;
  align-items: center;
  color: #FF575F;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  cursor: pointer;

  svg {
    path {
      fill: #FF575F;
    }
  }
}
