.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 440px;
  height: 80px;
  border-radius: 10px;
  background: #F2F2F2;
}

.text {
  color: #BFBFC0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}