@import "styles/vars.scss";

.root {
  width: 720px;
  height: 100px;
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
  border-radius: 10px;

  //:hover {
  //  box-shadow: 0 10px 30px rgba(0, 64, 230, 0.3);
  //}
}

.image {
  margin-right: 16px;
}

.mini {
  width: 476px !important;
  height: 96px !important;

  img {
    width: 82px !important;
    height: 80px !important;
    border-radius: 10px 0px 0px 10px;
  }

  @include mbDesktopBreakpoint {
    height: 96px !important;

    img {
      width: 96px !important;
      height: 96px !important;
    }

    .logo {
      width: 96px !important;
      height: 96px !important;
      margin-right: 0!important;
    }
  }

  .logo {
    width: 82px !important;
    height: 80px !important;
    margin-right: 12px;
  }
}

.reputation {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.selected {
  border: 2px solid #336BEB;
  width: 720px !important;
  height: 100px !important;

  img {
    width: 102px !important;
    height: 96px !important;
    border-radius: 10px 0px 0px 10px;
  }

  .logo {
    width: 102px !important;
    height: 96px !important;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  min-width: 106px;
  height: 100px;
  border-radius: 10px
}

.content {
  display: flex;
  flex-flow: row;
  position: relative;
  height: 100%;
  border-radius: 10px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;

  img {
    width: 100px;
    height: 100px;
    border-radius: 10px 0px 0px 10px;
    object-fit: cover;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 400px;
  overflow: hidden;

  @include mbDesktopBreakpoint {
    width: 250px!important;
  }
}

.mainCol {
  height: 100%;
  padding: 8px 12px 8px 12px;
  width: 100%;
}

.users {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
  width: 130px;
}

.rowMain {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mbDesktopBreakpoint {
    flex-flow: column;
    align-items: start;
  }
}

.col {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.tariff {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
}