.root {
	padding: 20px 40px 40px;

	background: var(--main-blue-disabled);
}

.h3 {
	margin-top: 0 !important;
}

.submitBtns {
	margin: 16px 0 24px;

	display: flex;
	justify-content: flex-end;
	column-gap: 24px;
}
