@import "styles/vars.scss";

.root {
  position: relative;

  font-family: $ubuntu-font;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: var(--main-grey);
  margin-right: 42px;
  border-radius: 10px;

  width: 166px;
  height: 164px;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    border-radius: 10px;
  }

  .loader,
  svg {
    position: absolute;
    top: 15%;
    left: 85%;
    transform: translate(-50%, -50%);

    opacity: 0;
    transition: 0.3s;

    fill: #fff;

    width: 12px;
    height: 12px;
  }

  &:hover:not(.cantUpload),
  &.isLoading {
    cursor: pointer;

    .loader,
    svg {
      opacity: 1;
    }

    .shadow {
      opacity: 0.6;
    }
  }
}

.noAvatar {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--main-blue);

  p {
    font-size: 68px;
    color: #fff;
    font-weight: bold;
  }
}

.shadow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  opacity: 0;
  transition: 0.3s;

  width: 100%;
  height: 100%;

  border-radius: 10px;
  background: #000;
}
