@import "styles/vars.scss";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: inherit;

  display: flex;
  width: $sidebar-closed-width;
  transition: width $default-transition-duration;

  .sidebarBadge {
    margin-left: 18px;

    transform: none;
    position: relative;
  }

  &:not(:hover) {
    @media all and (max-width: 1601px) {
      .button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 0 !important;
        }
      }
    }
  }

  &:hover {
    width: $sidebar-opened-width;

    .logo {
      & > svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
          margin-top: 8px;
          margin-bottom: 9px;
        }
      }
    }

    .buttonText {
      display: block;
    }

    .button {
      display: flex;
      padding-left: 16px !important;


      svg {
        width: 16px;
        height: 16px;
        margin-right: 30px;
      }
    }

    .buttonVideo {
      display: flex;
      justify-content: center;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .buttonTG {
      display: flex;
      justify-content: center;

      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }

    .sidebarColumn {
      width: $sidebar-opened-width;
    }

    .sidebarMenuItem {
      &__text {
        opacity: 1;
      }
    }
  }
}

.open {
  width: $sidebar-opened-width;

  .logo {
    & > svg {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
        margin-top: 8px;
        margin-bottom: 9px;
      }
    }
  }

  .sidebarColumn {
    width: $sidebar-opened-width;
  }

  .sidebarMenuItem {
    &__text {
      opacity: 1;
    }
  }
}

.buttonText {
  display: none;
}

.logo {
  margin: 24px 0;
  cursor: pointer;

  @include flex-align-items-center;
  justify-content: center;

  & > svg {
    &:last-child {
      margin-left: 0;
      display: none;
      margin-bottom: 8px;
      margin-top: 9px;
    }
  }
}

.unAuthorized {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  gap: 12px;
  border-top: 1px solid #8B8B8D;
  padding-top: 17px;
  padding-bottom: 10px;
  height: 85px;

  .sidebarMenuItem__text {
    color: white;

    :hover {
      color: #336BEB;
    }
  }
}

.buttonContainer {
  padding: 0 12px;
  height: 45px !important;
  transition: 0.2s !important;

  .button {
    width: 100%;
    height: 45px !important;
    font-size: 14px !important;
    padding: 5px 6px 5px 7.5px !important;
    transition: 0.2s !important;
    color: var(--BW-5, #FFF);
    border: 1px solid var(--BW-5, #FFF) !important;

    span {
      color: var(--BW-5, #FFF) !important;
    }

    svg {
      width: 11px;
      height: 11px;
      transition: 0.2s !important;

      path {
        fill: var(--BW-5, #FFF) !important;
      }
    }
  }

  .buttonBig {
    display: flex;
    padding-left: 16px !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 30px;
    }
  }
}

.buttonContainerVideo {
  padding: 0 12px;
  height: 45px !important;
  transition: 0.2s !important;
  position: absolute;
  bottom: 187px;
  width: 100%;

  @include mbVerticalDesktopBreakpoint {
    display: none;
  }

  :hover {
    background: #FF575F !important;
  }

  .buttonVideo {
    width: 100%;
    height: 45px !important;
    font-size: 14px !important;
    padding: 5px 6px 5px 7.5px !important;
    transition: 0.2s !important;
    color: var(--BW-5, #FFF) !important;
    border: 1px solid #FF575F !important;
    justify-content: center;

    span {
      color: var(--BW-5, #FFF) !important;
    }

    svg {
      width: 24px !important;
      height: 24px !important;
      transition: 0.2s !important;
      margin-right: 0;

      path {
        fill: var(--BW-5, #FFF) !important;
      }
    }
  }

  .buttonBig {
    display: flex;
    padding-left: 16px !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0 !important;
    }
  }
}

.buttonContainerTG {
  padding: 0 12px;
  height: 45px !important;
  transition: 0.2s !important;
  position: absolute;
  bottom: 130px;
  width: 100%;


  @include mbVerticalDesktopBreakpoint {
    display: none;
  }

  :hover {
    background: #336BEB !important;

    svg {
      g {
        :nth-child(1) {
          fill: white !important;
        }

        :nth-child(2) {
          fill: #336BEB !important;
        }

      }
    }
  }

  .buttonTG {
    width: 100%;
    height: 45px !important;
    font-size: 14px !important;
    padding: 5px 6px 5px 7.5px !important;
    transition: 0.2s !important;
    color: var(--BW-5, #FFF);
    border: 1px solid #336BEB !important;
    justify-content: center;

    span {
      color: var(--BW-5, #FFF) !important;
      margin-left: 8px;
    }


    svg {
      width: 24px !important;
      height: 24px !important;
      transition: 0.2s !important;
      margin-right: 0;

      g {
        :nth-child(2) {
          fill: white ;
        }
      }
    }
  }

  .buttonBig {
    display: flex;
    padding-left: 16px !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0 !important;
    }
  }
}

.sidebarMenu {
  margin: 30px 0;
  padding: 0;
}

.sidebar__inner {
  .logo {
    & > svg {
      opacity: 1;
      display: block;
    }
  }

  .button {
    display: flex;
    padding-left: 16px !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 30px;
    }
  }

  .buttonText {
    display: block;
  }
}

.sidebar__wrapperHide {
  .sidebarMenu,
  .buttonContainer,
  .logo {
    opacity: 0;
  }
}

.sidebarColumn {
  display: flex;
  flex-direction: column;
  width: $sidebar-closed-width;

  background: var(--BW-0, #1E1E1F);
  backdrop-filter: blur(20px);

  transition: width $default-transition-duration;
}

.sidebarBlur {
  position: absolute;
  height: 100%;
  width: 325px;
  left: $sidebar-opened-width;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;

  backdrop-filter: blur(6px);

  &__open {
    opacity: 1;
  }
}

.sidebarColumn__content {
  flex-grow: 1;
}

.sidebarMenuItem {
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;

  &__link {
    @include flex-align-items-center;

    height: 42px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    opacity: 0.7;

    &:hover,
    &_active {
      opacity: 1;
      position: relative;

      .aniBorder {
        animation: aniHeight 0.5s linear forwards;
      }

      .sidebarMenuItem__text {
        color: #fff;
      }
    }
  }

  &__text {
    opacity: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $sidebar-closed-width;
    height: 100%;
    border-color: transparent;
    border-style: solid;
    border-width: 0 6px;

    path {
      fill: var(--main-grey-disabled);
    }
  }
}

.sidebarMenuItem__button {
  padding: 0;
  border: none;
  background: transparent;

  width: 100%;
  font-family: $default-font;

  cursor: pointer;
}

.sidebarMenuItem__link {
  border-left: 6px;
  content: "";
}

.sidebarMenuItem__link:hover {
  //animation: ani 0.5s linear forwards;
  position: relative;

  .aniBorder {
    animation: aniHeight 0.5s linear forwards;
  }
}

@keyframes ani {
  0% {
    border-left: 0;
  }
  50% {
    border-left: 3px var(--Primary-Basic, #336BEB) solid;
  }
  100% {
    border-left: 6px var(--Primary-Basic, #336BEB) solid;
  }
}

.aniBorder {
  position: absolute;
  width: 6px;
  background: #336BEB;
  border-radius: 0px 20px 20px 0px;
}

@keyframes aniHeight {
  0% {
    height: 0
  }
  50% {
    height: 15px
  }
  100% {
    height: 30px
  }
}

.sidebarMenuItem__link_active {
  position: relative;

  .aniBorder {
    height: 42px !important;
  }

  .sidebarMenuItem__icon {


    path {
      fill: #fff;
    }
  }
}

.sidebarMenuItemHide {
  display: none;
}

@media all and (min-width: 1601px) {
  .sidebar {
    width: $sidebar-opened-width;

    .logo {
      & > svg {
        &:last-child {
          display: block;
        }
      }
    }

    .buttonText {
      display: block;
    }

    .button {
      display: flex;
      padding-left: 16px !important;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 30px;
      }
    }

    .sidebarColumn {
      width: $sidebar-opened-width;
    }

    .sidebarMenuItem {
      &__text {
        opacity: 1;
      }
    }
  }
}
