@import "styles/vars.scss";

.systemCreator {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
	opacity: 0.7;
}

.block {
	padding: 12px 16px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	border-radius: 10px;
	background: var(--main-blue-disabled);
}

.buttonsContainer {
	display: flex;
	align-items: center;
}

.button {
	padding: 4px 8px;

	border: none;
	background: transparent;

	cursor: pointer;

	svg {
		transition: 0.2s;

		width: 24px;
		height: 24px;
	}

	&:hover {
		svg {
			transform: translateY(-2px);
		}
	}
}
