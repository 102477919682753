.root {
	max-height: 100vh;

	display: flex;
}

.map {
	width: calc(100% - 790px);
}

.card {
	position: relative;

	width: 790px;
	z-index: 1;

	box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
}

.wideContainer {
	padding: 0 40px;
}

.cardContent {
	overflow-y: scroll;
	max-height: 100vh;
}

.cardContainer {
	padding: 80px 212px 60px 40px;
}

.subtitle {
	margin: 0 0 16px;

	font-weight: bold;
	font-size: 20px;
	color: var(--main-black);
}

.infoSubtitle {
	margin-bottom: -64px;
}

.block {
	margin: 0 0 80px 0;
}

.goBack {
	position: absolute;
	top: 12px;
	left: -44px;
	z-index: 1000;
}
