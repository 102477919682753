@import "src/components/UI/Tag/TagTaskStatus/styles/utils";

.root {
	display: flex;
	column-gap: 4px;
	align-items: center;

	@include set-status-color(var(--main-grey-disabled));

	&.red {
		@include set-status-color(var(--accent-red));
	}

	&.green {
		@include set-status-color(var(--accent-green));
	}

	&.warn {
		@include set-status-color(var(--main-blue));
	}

	&.cancel {
		@include set-status-color(var(--main-blue-secondary));
	}

	&.reload {
		@include set-status-color(var(--accent-red));
	}
}
