@import "styles/vars.scss";

.root {
	position: relative;
	background: var(--main-blue-disabled);
	border-radius: 10px;
	overflow: hidden;
	height: 202px;
}

.filter {
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	background: rgba(14, 29, 68, 0.3);
}

.wrapper {
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	display: flex;
	background: rgba(14, 29, 68, 0.3);

	align-items: center;
	justify-content: center;

	border-radius: 10px;

	> div {
		width: 316px;
		text-align: center;
	}
	color: #ffffff;
}

.skeleton {
	font-weight: 300;
	filter: blur(8px);

	padding: 10px 20px;
	> div {
		display: flex;

		> div {
			background: rgba(14, 29, 68, 0.056);

			padding: 5px 10px;
			border-radius: 10px;

			margin-left: 10px;
		}
	}
}

.firstLine {
	> div {
		margin-top: 10px;
		width: 30%;
	}
}

.secondLine {
	> div {
		margin-top: 40px;
		width: 50%;
	}
}

.thirdLine {
	> div {
		margin-top: 10px;
		width: 20%;
	}
}
