@import "styles/vars.scss";

.root {
  //margin-top: 80px;
  display: flex;
  flex-direction: column;
  background: #F2F2F2 !important;

  @include mbDesktopBreakpoint {

    position: relative;
  }
}

.partnerAdHeader {
  padding: 8px 8px 0;
}

.partnersAdEditFooter {
  padding: 0 20px 38px 32px;

  @include mbDesktopBreakpoint {
    padding: 0 10px 24px 10px !important;
  }
}

.arrow {
  fill: var(--main-blue) !important;
  transform: rotate(180deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;

  }
}

.startAd {
  padding-top: 29px;
  padding-bottom: 20px;
  padding-left: 32px;
}

.wasInAd {
  & > * {
    &:nth-child(2) {
      order: 3;
      padding-top: 0;
    }
  }
}

.archived {
  //* {
  //	color: var(--main-black);
  //}

  > div:nth-child(2) {
    > div > div {
      > div:first-child {
        > div:first-child {
          opacity: 0.5;
        }

        > div:last-child {
          > ul > div > div {
            opacity: 0.5;

            div {
              color: var(--main-black);
            }

            svg * {
              fill: #9595A3;
            }
          }
        }
      }

      > div:nth-child(2) {
        > div {
          > div:first-child {
            opacity: 0.5;

            > div {
              > span > div > div {
                pointer-events: none;
                opacity: 0.5;
              }

              p {
                color: var(--main-black);
              }

              &:last-child {
                pointer-events: none;
              }
            }

            svg * {
              fill: #9595A3;
            }
          }

          > div:nth-child(2) {
            > div:first-child {
              opacity: 0.5;
            }

            > div:nth-child(2) {
              opacity: 0.5;

              > div {
                pointer-events: none;
              }

              > div:first-child > label {
                color: white !important;
                background-color: #BFC4DF;
              }

              > div > label {
                color: #9595A3;
              }
            }

            > div:last-child {
              opacity: 0.5;
              pointer-events: none;

              svg * {
                stroke: #9595A3;
              }
            }
          }
        }
      }
    }
  }

  > div:nth-child(3) {
    > div:first-child {
      opacity: 0.5;
    }

    > div > div > div:last-child {
      pointer-events: none;
    }
  }
}

.popupCard {
  width: fit-content!important;
}

.popupCardNot {
  width: fit-content!important;
  left: -130px!important;
}

.dotsNot {
  width: 44px;
  position: absolute;
  bottom: -42px;
  z-index: 2;
  left: 178px;
}

.dotsNotWithoutButton {
  left: 387px!important;
}

.archiveContainer {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  opacity: 1 !important;

  @include mbDesktopBreakpoint {
    justify-content: end;
    background: #F2F2F2 !important;
    position: absolute;
    width: 440px;
    left: 0px;
    height: 64px;
    padding-right: 10px;
    border-radius: 0px 0px 20px 20px;
    bottom: -50px;
    box-shadow: 0px 0px 30px 0px #2224281A;
    align-items: center;

    svg {
      path {
        fill:  var(--main-blue)!important;
      }
    }
  }
}
