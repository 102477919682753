@import "styles/vars.scss";

.root {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	padding: 0 40px 0 98px;

	height: 70px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background: linear-gradient(
		170deg,
		rgba(3, 29, 64, 1) 0%,
		rgba(3, 29, 64, 1) 68%,
		rgba(6, 108, 253, 1) 68%,
		rgba(6, 108, 253, 1) 100%
	);
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
}

.logo {
	margin-right: 28px;

	display: flex;
	align-items: center;

	.logoLabel {
		width: 36px;
		height: 32px;
	}
}

.avatarRound {
	background: var(--main-blue-inactive) !important;
}

.avatarNoImgIcon path {
	fill: var(--main-blue) !important;
}

.meBlock {
	display: flex;
}

.namePhone {
	margin-right: 12px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	color: #fff;
}

.name {
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
}

.phone {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 16px;
}
