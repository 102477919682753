@import "styles/vars.scss";

.root {
  width: 440px;
  border-bottom: 5px solid #336BEB;
  margin-top: 20px;
}

.elements {
  height: 24px;
  display: flex;
  flex-flow: row;

  :last-child {
    border: none !important;
  }

  :first-child {
    width: 200px !important;
  }
}

.element {
  width: 80px;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  border-right: 1px solid #CCCCCC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  position: sticky;
  box-shadow: 0px 10px 30px 0px #124BF64D;
  height: 346px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 16px 30px 30px 30px;
  width: 500px;
}

.footerUnHovered {
  display: none;
}

.footerInfo {
  width: 440px;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.rowButton {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.inputCode {
  padding: 4px 0;

  font-size: 14px;
  line-height: 20px;
  font-family: $default-font;
  font-weight: 500;

  background: transparent;
  border: none;

  width: 100%;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: var(--main-blue-secondary);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.promoCodeButton {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
  margin-left: 30px;
  cursor: pointer;
}

.infoRow {
  width: 100%;
}

.priceWithDiscount {
  font-family: Cera Pro;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.20000000298023224px;
  text-align: right;
}

.price {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  text-decoration: line-through;
  text-align: left;
  margin-left: 12px;
}