@import "styles/vars.scss";

.root {
	display: flex;
	flex-direction: column;
	padding-left: 38px;
}

.table {
	width: 100%;
	margin-bottom: 75px;
	border-spacing: 0;

	th {
		padding: 13px 8px;
		border-bottom: 6px solid var(--main-blue);
	}
}

.firstTh {
	display: flex;
}

.subject {
	margin-left: 15px;
	font-weight: bold;
	font-size: 20px;
}

.openLock {
	opacity: 0.2;
}

.headers {
	color: #555a7d;
	font-weight: normal;
	font-size: 14px;
	font-family: $ubuntu-font;
}

.rowTr {
	&:nth-child(even) {
		background-color: var(--main-blue-disabled);
	}
}

.td {
	text-align: center;
}

.tdName {
	padding: 18px 30px 20px 50px;
	color: var(--main-black);
	font-weight: 500;
	font-size: 18px;
	text-align: left;
}

.toggleIcon {
	cursor: pointer;
	fill: var(--main-blue);
}

.toggleIconEdit {
	transform: scale(1.3);
}

.toggleIconUnset {
	width: 14px;
	height: 14px;
	margin: auto;
	background-color: var(--main-blue);
	border-radius: 50%;
	cursor: pointer;
}
