.placeholder {
	&::placeholder {
		font-family: Cera Pro !important;
		font-style: normal !important;
		font-weight: 500 !important;

		color: var(--main-blue-secondary) !important;
	}
}

.parsedObject {
	margin-top: 20px;
}

.parsedTitle {
	font-size: 16px;
}

.parsedAddress {
	font-size: 14px;
}

.parsedImg {
	width: 79px;
	height: 64px;
}

.metro {
	top: 6px;
}
