@import "styles/vars.scss";

.root {
	padding: 12px 20px 12px 40px;

	@include mbDesktopBreakpoint {
		padding: 12px 0px 12px 10px!important;
	}
}

.title {
	color: var(--main-black);
	font-size: 26px;
	line-height: 31px;
	font-weight: bold;

	@include mbDesktopBreakpoint {
		align-items: center!important;
	}
}

.row {
	display: flex;
	align-items: flex-start;
}

.rowLeft {
	flex: 3 1;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include mbDesktopBreakpoint {
		max-width: 275px!important;
	}
}

.rowRight {
	flex: 1 1;
	display: flex;
	justify-content: end;
}

.priceWith {
	padding-right: 10px;
}

.price {
	position: relative;
	font-size: 24px;
	line-height: 31px;
	display: flex;
	align-items: center;
}

.address {
	color: var(--main-grey-disabled);
	font-family: $ubuntu-font;
	font-size: 14px;
	font-weight: 400;
}

.priceUnit {
	padding-left: 25px;
	display: flex;
	align-items: center;
	color: var(--main-grey-disabled);
	font-family: $ubuntu-font;
	font-size: 14px;
}

.objectType {
	padding-left: 25px;
	color: var(--main-grey-disabled);
	font-size: 14px;
	font-family: $ubuntu-font;
}

.metro {
	display: flex;
	align-items: center;
}

.metroItem {
	margin-left: 16px;
	display: flex;
	align-items: center;
	color: var(--main-grey-disabled);
	font-size: 14px;

	font-family: $ubuntu-font;
	&:first-child {
		margin-left: 0;
	}
}

.metroLine {
	margin-right: 8px;
	width: 8px;
	height: 8px;
	border-radius: 100%;
}

.middlePrice {
	font-family: $ubuntu-font;
	font-size: 12px;
	line-height: 14px;
	font-weight: normal;

	p {
		margin: 8px 0 0 0;
		min-width: 210px;
	}

	span {
		display: block;
		margin: 4px 0 8px;
		opacity: 0.5;
	}
}

.rootTooltip {
	margin: 1px 8px !important;
}

.tooltipCard {
	max-height: 260px;
	max-width: 360px !important;
	min-height: 40px;
	padding: 8px 8px 4px 36px !important;
	background: #3F3F40!important;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 15;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-wrap: break-word;
		font-size: 13px;
		font-family: "Ubuntu", sans-serif;
	}

	svg {
		left: 8px !important;
		top: 6px !important;
	}
}
