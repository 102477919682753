@import "styles/vars.scss";

.root {
	padding: 42px 39px 45px 41px;
	width: 100%;

	border-bottom-left-radius: 20px;
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
}

.title {
	font-weight: bold;
	font-size: 16px;
}

.href {
	margin-top: 22px;
	padding: 7px 17px 8px 17px;
	width: 100%;

	font-family: $ubuntu-font;
	font-size: 14px;
	border: 1px solid var(--main-grey-light);
	border-radius: 100px;
}

.buttonWrapper {
	margin-right: 12px;

	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.button {
	margin-top: 25px;

	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 14px;
	font-family: $default-font;
	cursor: pointer;
	position: relative;

	background-color: inherit;
	border: none;

	svg {
		fill: var(--main-grey-disabled);
	}
}

.updateSubTitle {
	position: absolute;
	bottom: -24px;
	left: 42px;

	width: 100%;

	text-align: left;
	font-family: $ubuntu-font;
	color: var(--main-grey-disabled);
	font-size: 12px;
	font-weight: 400;
}
