@import "styles/vars.scss";

.root {
	@include flex-align-items-center;
	font-size: 14px;
}

.label {
	opacity: 0.5;
}

.inputWrapper {
	margin-left: 20px;
	@include flex-align-items-center;
	position: relative;
	border-radius: 10px;
	padding: 5px 7px;
	border: 1px solid var(--main-blue-secondary);
}

.input {
	background-color: transparent;
	text-align: right;
	border: 0;
	font-family: $default-font;
	max-width: calc(100% - 30px);
	font-weight: 300;
}

.arrows {
	display: flex;
	flex-direction: column;
	height: 16px;
	justify-content: space-between;
	margin-left: auto;
	div {
		cursor: pointer;
		height: 8px;
		width: 8px;
		position: relative;
		&:first-child {
			transform: rotate(180deg);
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			fill: var(--main-blue-secondary);
		}
	}
}
