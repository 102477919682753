@keyframes appearAnimation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes blinkErrorColor {
	0% {
		color: var(--main-black);
	}
	49% {
		color: var(--main-black);
	}
	60% {
		color: var(--accent-red);
	}
	100% {
		color: var(--accent-red);
	}
}

@keyframes blinkErrorBorder {
	0% {
		border-color: var(--main-blue-secondary);
	}
	49% {
		border-color: var(--main-blue-secondary);
	}
	60% {
		border-color: var(--accent-red);
	}
	100% {
		border-color: var(--accent-red);
	}
}

@keyframes rotation {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes skeletonAnimation {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.4;
	}

	100% {
		opacity: 1;
	}
}

@keyframes blinkShadow {
	0% {
		box-shadow: none;
	}

	25% {
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
	}

	50% {
		box-shadow: none;
	}

	75% {
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
	}

	100% {
		box-shadow: none;
	}
}
