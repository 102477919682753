.icon {
	display: flex;
	width: 100%;
	height: 100%;
}

.closeIcon {
	path {
		fill: var(--main-black);
	}
}

.clockIcon {
	path {
		fill: var(--accent-orange);
	}
}

.timerIcon {
	path:first-child {
		fill: var(--accent-green);
	}
}
