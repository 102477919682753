@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	padding: 9px 3px;
	background-color: #fff;
	border-radius: 10px;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		height: 0;
	}
}

.status {
	&__default .inviteIcon {
		fill: var(--accent-green);
	}

	&__warning .inviteIcon {
		fill: var(--accent-orange);
	}

	&__hot .inviteIcon {
		fill: var(--accent-red);
	}
}

.inviteIcon {
	margin-right: 12px;
}

.overflowText {
	overflow: hidden;
	color: var(--main-black);
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.inviteBlock {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 9px 14px;
	border-right: 1px solid #f2f4fd;

	&:last-child {
		justify-content: flex-end;
		border: none;
	}
}

.phoneBlock {
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 163px;
	min-width: 163px;
}

.userPhone {
	color: var(--main-black);
	font-weight: bold;
	font-size: 14px;
	text-align: center;
}

.days {
	margin-right: 14px;
	color: var(--main-grey-disabled);
	font-size: 14px;
	white-space: nowrap;
}

.linkBtn {
	padding: 10px 16px !important;

	svg {
		width: auto !important;
		height: auto !important;
	}
}

.decline {
	margin-left: 16px;
}

.declined {
	margin-right: 50px;
	color: var(--main-black-dark);
	font-weight: 500;
	font-size: 14px;
	white-space: nowrap;
}

.revert {
	margin-right: 12px;
}
