@import "styles/vars.scss";

.selected {
	position: relative;
	width: 464px;
	margin-bottom: 42px;
	padding: 42px 53px 44px 47px;
	border-radius: 20px;
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.2);
	font-family: $ubuntu-font !important;
}

.selectedClose {
	fill: var(--main-grey-disabled);
	position: absolute;
	top: 10.5px;
	right: 10.5px;
	cursor: pointer;
}

.selectedInfo {
	margin-top: 0;
	margin-bottom: 23px;
	font-size: 14px;
	word-break: break-word;

	span {
		color: var(--main-blue);
	}
}

.selectedManipulations {
	display: flex;
	font-size: 14px;
}

.selectedAccept {
	margin-left: 27px;
}

.selectedDecline {
	margin-left: 16px;
}
