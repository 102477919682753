@import "styles/vars.scss";

.root {
	padding: 10px 22px 10px 0;
	border-radius: 10px;
}

.wrapper {
	@include flex-align-items-center;
}

.percent {
	width: 80px;
	max-width: 80px;
	text-align: center;

	span {
		font-size: 20px;
		font-family: $ubuntu-font;
		font-weight: 500;
		line-height: 24px;
		&:last-child {
			font-size: 16px;
		}
	}
	&.blue {
		span {
			color: var(--main-blue);
		}
	}
	&.red {
		span {
			color: var(--accent-red);
		}
	}
	&.orange {
		span {
			color: var(--accent-orange);
		}
	}
}

.label {
	flex-grow: 1;

	max-width: 386px;
	font-weight: 500;
	font-size: 16px;
	color: var(--main-black);
}

.icon {
	@include flex-align-items-center;

	margin-left: auto;
}

.iconClickable {
	cursor: pointer;
}

.children {
	padding: 20px 20px 16px 20px;
}
