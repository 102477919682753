@import "styles/vars.scss";

.root {
	padding: 12px 30px 0px 48px;

	width: 0;
	z-index: -1;

	&.rootIsOpen {
		width: 260px;
	}
}

.list {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.item {
	margin-bottom: 18px;

	font-weight: 700;
	cursor: pointer;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.45);

	white-space: nowrap;

	&:hover {
		color: rgba(255, 255, 255);
		transition: $default-transition-duration;
	}
}

.item:hover {
	color: rgba(255, 255, 255);

	div {
		div {
			color: rgba(255, 255, 255, 0.6);
		}

		div:hover {
			color: rgba(255, 255, 255, 1);
		}
	}
}
