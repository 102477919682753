@import "styles/vars.scss";

.root {
  padding-top: 12px;
  padding-bottom: 0;
  min-height: 350px;
}

.select::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 1px;

}

.type {
  display: flex;
  align-items: center;
  flex-flow: row !important;
}

.info {
  width: 60%;
}

.RadioButton {
  gap: 12px;
  background: var(--BW-4, #F2F2F2);
}


.buttonContainer {
  @include flex-align-items-center;
  justify-content: flex-end;

  margin-top: 40px;
  height: 64px;
  position: absolute;
  width: 660px;
  left: 0px;
  bottom: 0;
  padding-right: 40px;
  box-shadow: 0 -4px 20px rgba(0, 64, 229, 0.3);
}

.buttonContainerMobile {
  width: 500px!important;
  height: 84px!important;
}

.warningContainer {
  width: 580px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--BW-4, #F2F2F2);
  padding: 12px 20px 20px 20px;

  .title {

    margin-bottom: 4px;
    color: var(--BW-0, #1E1E1F);
    font-family: "Cera Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;
  }

  .warning {
    color: var(---, #FF9052);
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
  }
}

.warningMobile {
  width: 468px!important;
  height: 130px!important;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 4px;
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.tooltipRoot {
  margin: 0;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -8px !important;
  background: #3F3F40 !important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}