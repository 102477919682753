@import "styles/vars.scss";

.root {
  display: flex;
  border-top: 1px solid #e5e5e5;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    &:first-child {
      @include flex-align-items-center;
    }
  }
}

.col {
  display: flex;
  flex-flow: column;
  gap: 4px;
  align-items: start;
  margin-top: 8px;
}

.title {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.text {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
}

.link {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
  cursor: pointer;
}