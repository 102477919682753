.searchSingleInput {
	width: 100%;

	input {
		padding: 0;

		overflow: hidden;
		text-overflow: ellipsis;
	}
}
