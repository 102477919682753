@import "styles/vars.scss";

.root {
	position: relative;

	display: flex;
	align-items: center;
	column-gap: 26px;

	width: 100%;

	padding: 13px 50px 13px 20px;

	border-radius: 10px;
	background: var(--main-blue-disabled);

	transition: filter 0.2s;
}

.root:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	background: #fff;
	cursor: pointer;
}

.fio {
	display: flex;
	flex-direction: column;

	max-width: 33%;

	text-overflow: ellipsis;
}

.overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.linkIcon {
	width: 20px;
	height: 20px;

	position: absolute;
	top: 4px;
	right: 4px;

	path {
		fill: #7f8def;
	}
}

.types {
	display: flex;
	flex-direction: column;

	max-width: 33%;

	text-overflow: ellipsis;
	text-transform: capitalize;
}

.type {
	font-size: 14px;
	line-height: 19px;
	font-weight: 400;
	color: #000;
}

.subtype {
	font-size: 12px;
	line-height: 16px;
	font-weight: 300;
	color: #000;
	opacity: 50%;
}

.phone {
	flex: 1;

	display: flex;
	justify-content: center;

	white-space: nowrap;
	font-size: 14px;
}
