@import "styles/vars.scss";

.root {
  position: relative;
  background: #F2F2F2 !important;

  & > div {
    &:first-child {
      left: -8px;
    }
  }
}

.rootWithLabel {
  display: flex;
  align-items: center;
}

.radioWrapper {
  display: flex;
  flex-direction: column;
}

.radioWrapperWithLabel {
  flex: 1 1;
}

.label {
  padding-left: 12px;
  padding-bottom: 6px;

  flex: 1 1;

  font-family: $ubuntu-font;
  font-size: 14px;
  line-height: 20px;
  color: var(--main-grey-sub);

  border-bottom: 1px solid var(--main-blue-inactive);
}

.radio {
  div:nth-child(1n) {
    margin: 4px;
  }

  width: 100%;

  &:first-child {
    margin-top: 0;
  }
}

.error {
  margin-top: 8px;

  text-align: right;
  font-size: 14px;
  color: var(--accent-red);
}
