@import "styles/vars.scss";

.root {
  padding: 0 9px !important;

  @include mbDesktopBreakpoint {
    padding: 0 2px !important;
  }
}

.modal {
  position: absolute;
  bottom: 44px;
  width: 500px!important;
  padding: 10px 10px 0px 10px!important;
  border-radius: 20px 20px 0px 0px!important;
  overflow-y: unset!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    left: -3px!important;
    path {
      fill: #FFFFFF!important;
    }
  }
}

.wrapper {
  overflow-y: unset!important;
}