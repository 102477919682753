@import "styles/vars.scss";

.root {
	padding: 12px 30px 0px 48px;
	padding-left: 30px;

	width: 0;
	z-index: -1;

	div:last-child {
		margin-bottom: 0px;
	}
}

.item {
	margin-bottom: 18px;
	font-weight: 700;
	cursor: pointer;
	font-size: 14px;

	white-space: nowrap;
}
