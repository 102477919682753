.root {
	margin-top: 80px;
}

.sourceContainer {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	margin: 12px 0;

	height: 60px;

	background: var(--main-blue-disabled);
	border-radius: 10px;

	cursor: pointer;

	transition: 0.2s;

	&:hover {
		background: #ffffff;
		box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
	}
}

.linkIcon {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);

	path {
		fill: var(--main-blue);
	}
}

.cianIcon {
	width: 117px;
	height: 40px;
}

.avitoIcon {
	width: 104px;
	height: 30px;
}

.otherSource {
	font-size: 14px;
	font-weight: 500;
}
