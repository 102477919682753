.divider {
	position: relative;
	margin: 20px -16px;
	height: 1px;
	width: calc(100% + 32px);
}

.linesContainer {
	padding: 0 12px;
	position: absolute;
	top: 50%;
	left: 26px;
	right: 0;
	transform: translateY(-50%);
	height: 3px;
	display: flex;
}

.line {
	margin: 0 4px;
	height: 100%;
	width: 18px;
	background: #c5cdf4;
	border-radius: 12px;
}

.line-1,
.line-2,
.line-3,
.line-4,
.line-5,
.line-6,
.line-7,
.line-8,
.line-9 {
	background: var(--main-blue);
}

.line-10 {
	background: #848bea;
}

.line-11 {
	background: #9384d7;
}

.line-12 {
	background: #a978ba;
}

.line-13 {
	background: #be6da0;
}

.line-14 {
	background: #e95768;
}

.line-15 {
	background: #ff4b4b;
}
