@import "styles/vars.scss";

.root {
  position: absolute;
  right: 18px;
  top: 13px;
  z-index: 10;
}

.rootActive {
  .iconToggle {
    border-radius: 20px 20px 0 0;

  }
}

.icon {
  fill: var(--main-blue);
}

.text {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.iconToggle {
  @include button-no-style();
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 9px;

  width: 84px;
  height: 36px;
  position: absolute;
  top: -60px;
  right: 10px;

  background-color: #E5E5E5;
  border-radius: 100%;
  cursor: pointer;
  z-index: 11;
}

.dropdown {
  padding: 10px 0;
  position: relative;
  right: -7px;
  top: -14px;

  width: 255px;
  //max-height: 345px;
  height: 100%;

  background-color: #fff;
  border-radius: 20px 20px 20px 20px;
  box-shadow: $default-box-shadow;
}
