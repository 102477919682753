@import "styles/vars.scss";

.root {
	margin-top: 60px;
}

.comment {
	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: var(--main-grey);
}

.noComment {
	opacity: 0.5;
}
