@import "styles/vars.scss";

.title {
	display: inline-flex;
	align-items: center;
	color: #2D2C46;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 24px */
	letter-spacing: 0.2px;
}

.row {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
}

.card {
	margin-top: 8px;
	display: flex;
	flex-flow: column;
	gap: 8px;

}
