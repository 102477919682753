@import "styles/vars.scss";

.switchBlock {
	padding: 12px 16px;

	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	color: #fff;

	border-bottom: 1px solid #6c6b7d;
	background: var(--main-black);

	button {
		position: relative;
		margin: 0 20px;

		width: 40px;
		height: 40px;

		background: var(--main-blue);
		border-radius: 20px;
		border: none;

		cursor: pointer;

		svg {
			position: absolute;
			top: 50%;
			left: 50%;

			fill: #fff;
		}

		&.prevBtn {
			svg {
				transform: translate(-55%, -50%) rotate(90deg);
			}
		}
		&.nextBtn {
			svg {
				transform: translate(-45%, -50%) rotate(-90deg);
			}
		}
	}

	p {
		margin: 0 32px 0 0;
	}

	span {
		font-size: 18px;
		line-height: 24px;
	}
}

.form {
	padding: 12px 16px 20px;

	background: var(--main-black);
}

.voteLine {
	padding: 12px 0 20px;

	display: flex;
	align-items: center;
}

.voteBtnContainer {
	display: flex;
	justify-content: space-between;

	width: 100%;
}

.voteBtnCircle {
	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 50px;
	height: 50px;

	background: #fff;
	border-radius: 100px;

	cursor: pointer;
}

.voteBtn {
	display: flex;
	align-items: center;

	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-blue-secondary);

	cursor: pointer;

	&:hover {
		color: #fff;
		opacity: 1;
	}
}

.voteBtnHasResults {
	opacity: 0.5;
}

.thumbUp {
	svg {
		transform: translateY(-1px);
	}
	path {
		fill: var(--accent-green);
	}

	&__active {
		background: var(--accent-green);

		path {
			fill: #fff;
		}
	}
}
.thumbDown {
	svg {
		transform: translateY(1px) rotate(180deg);
	}
	path {
		fill: var(--accent-red);
	}

	&__active {
		background: var(--accent-red);

		path {
			fill: #fff;
		}
	}
}

.question {
	path {
		fill: var(--accent-orange);
	}

	&__active {
		background: var(--accent-orange);

		path {
			fill: #fff;
		}
	}
}

.checkboxLabel {
	color: #fff;
}

.checkboxContainer {
	padding: 5px 0 5px 20px;
	margin-left: 20px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	min-width: 215px;

	border-left: 1px solid #6c6b7d;
}

.resultComment {
	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-blue-secondary);
}
