@import "styles/vars.scss";

textarea {
	font-family: $ubuntu-font;
}

.form {
	margin-top: 20px;
}

.description {
	margin-top: 20px;
}

.submitBtn {
	margin-top: 16px;
	margin-left: 282px;
}

.ownerComment {
	height: 105px;
	margin-top: 8px;
}

.callCenterComment {
	height: 70px;
	margin-top: 12px;
}
