.root {
	padding: 32px 24px;
}

.divider {
	margin: 24px 0 14px 0;

	height: 1px;
	width: 100%;

	background: var(--main-blue-secondary);
}

.popupTrigger {
	transform: translateX(-24px);
}

.submitContainer {
	margin-top: 12px;

	display: flex;
	justify-content: flex-end;
}

.error {
	color: var(--accent-red);
	font-size: 12px;
}
