@import "styles/vars.scss";

.root {
	padding: 16px 8px 16px 14px;
	overflow: hidden;
	max-width: 220px;
	text-overflow: ellipsis;
	border-top: 1px solid white;
}

.base {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.avatarContainer {
	position: relative;

	cursor: pointer;

	&:hover .notifications {
		opacity: 1;
	}
}

.notifications {
	opacity: 0;

	transition: 0.2s;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	border-radius: 20px;
	background: var(--main-blue);
}

.info {
	margin-left: 16px;
}

.company {
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: rgba(255, 255, 255, 0.5);
	font-family: $default-font;
	max-width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: 0.2s;
	display: flex;
	align-items: center;
	height: 24px;
}

.tooltipDescription {
	margin: 0 !important;
}

.tooltipWrapper {
	position: absolute;
	left: 165px;
}

.tooltipCard {
	max-height: 260px!important;
	max-width: 460px !important;
	min-height: 40px!important;
	padding: 8px 8px 8px 36px !important;
	left: -7px !important;
	top: -9px !important;
	background: #3F3F40!important;
	display: flex;
	align-items: center;

	div {
		display: -webkit-box;
		-webkit-line-clamp: 15;
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-wrap: break-word;
		font-size: 13px;
		font-family: "Ubuntu", sans-serif;
	}

	svg {
		left: 8px !important;
		top: 8px !important;
	}
}

.name {
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	color: rgba(255, 255, 255, 0.5);
	font-family: $default-font;
	cursor: pointer;
	border-bottom: 1px white solid;
	max-width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;

	white-space: nowrap;

	transition: 0.2s;
}

.baseHover:hover .name {
	color: var(--main-blue);
}

.infoCurrent .name {
	color: #fff;
}

.link {
	padding-left: 56px;

	cursor: pointer;
	white-space: nowrap;
	color: var(--main-blue);
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}
