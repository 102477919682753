@import "styles/vars.scss";

.description {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
}
