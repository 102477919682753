.rowBetween {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  margin-bottom: 8px;
  padding: 16px 28px 0 28px;
}

.count {
  color: #CCC;
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-left: 8px;
}

.title {
  display: inline-flex;
  align-items: center;
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.2px;
}

.actionText {
  color: #336BEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
  cursor: pointer;
}

.preAppTable {
  display: flex;
  flex-flow: column;
  max-height: 265px;
  overflow: auto;
  padding: 10px 28px 0 28px;
  width: 100%;
}

.preApp {
}

.plug {
  display: flex;
  justify-content: center;
}