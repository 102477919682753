.root {
  border-radius: 12px;
  background: #FFF;
  padding: 12px 20px;
  margin-bottom: 32px;
}

.root p {
  padding: 0;
  margin: 0;
  color: #FF9052;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}
h2 {
  color: #2D2C46;
  font-family: Cera Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}