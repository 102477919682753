@import "styles/vars.scss";

.root {
	margin-bottom: 30px;

	display: flex;
	flex-direction: column;
	max-width: 540px;
}

.field {
	display: flex;
	flex-direction: column;
}

.middleField {
	margin: 0 48px;
}

.label {
	margin-bottom: 10px;

	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-grey-sub);
}

.popupTrigger {
	width: 175px;

	font-size: 14px;
}

.popupCard {
	left: -24px !important;
}

.objectSubType {
	margin: 8px 0 16px 0;
}

.radioLabelClassName {
	white-space: nowrap;
}
