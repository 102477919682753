@import "styles/vars.scss";

.root {
  margin-top: 60px;
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 60px;

  @include mbDesktopBreakpoint {
    margin-top: 40px!important;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 12px;
}

.content {
  padding: 20px;
  width: 720px;
  border-radius: 10px;
  background: #F2F2F2;

  @include mbDesktopBreakpoint {
    width: 440px!important;
  }
}

.alert {
  margin-bottom: 20px;
  padding: 12px 12px;
  width: 680px;
  height: 92px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-flow: column;


  .alertText {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #FF575F;
  }

  alertTitle {
    font-family: Cera Pro;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
}

.fileContainer {
  margin-top: 20px;
  background: #FFFFFF !important;
  width: 680px;
  min-height: 56px;

  border-radius: 10px;
  padding: 8px 24px;
  margin-bottom: 10px;

  @include mbDesktopBreakpoint {
    width: 400px!important;
  }
}

.fileRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0 !important;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;

    @include mbDesktopBreakpoint {
      max-width: 200px!important;
    }
  }
}

.fileRowTitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-left: 8px;
}

.fileItem {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F2F2F2;
  padding-left: 12px;
}

.fileItemNew {
  width: 52px;
  height: 19px;
  background: #FF575F;
  padding: 2px 6px 2px 6px;
  border-radius: 100px;
  color: #FFFFFF;
  font-family: Ubuntu;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-left: 20px;
}

.filesList {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
  padding-right: 10px;

  :last-child {
    border-bottom: 0 !important;
  }
}

.counter {
  position: absolute;
  top: 20px;
  left: -6px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.20000000298023224px;
  padding-bottom: 2px;
}

.counterWrapper {
  position: relative;
}

.rowEnd {
  height: 64px;
  display: flex;
  justify-content: end;
  gap: 32px;
  align-items: center;
  position: absolute;
  width: 720px;
  bottom: 0;
  left: 0;
  padding-right: 30px;
  background: #F2F2F2;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 10px 30px 0 #124BF64D;

  @include mbDesktopBreakpoint {
    height: 84px!important;
    width: 440px!important;
  }


  .preview {
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #336BEB;
    cursor: pointer;
  }
}