$paddingY: 12px;

.root {
	position: relative;

	display: flex;
	flex-direction: column;
	row-gap: 5px;

	margin-top: 5px;
	padding: $paddingY 16px;
}

.updatePhonesBtn {
	position: absolute;

	top: $paddingY;
	left: -5px;

	transform: translate(-100%, 0);

	width: 20px;
	height: 20px;

	cursor: pointer;

	path {
		fill: var(--accent-green);
	}
}
