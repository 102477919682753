.contentWrapperClassName {
	overflow-y: hidden !important;

	min-height: 660px !important;
}

.contentWrapperClassNameMobile {
	position: absolute;
	bottom: 0;
	width: 500px!important;
	padding: 20px 20px 40px 20px!important;
	border-radius: 20px 20px 0px 0px!important;
}

.wrapperClassName {
	display: flex;
	flex-direction: column;

	height: 100%;

	overflow-y: auto;
}

.contentBody {
	overflow-y: auto;
}

.title {
	position: sticky;

	margin: 0;
	padding-left: 16px;

	font-size: 26px;
}

.modalClose {
	background: #1E1E1F!important;
	border: 0!important;
	svg {
		left: -3px!important;
		path {
			fill: #FFFFFF!important;
		}
	}
}