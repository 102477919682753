.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: none;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -8px;
}

.button {
  background: #336BEB;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.row {
  display: flex;
  align-items: center;
}

.buttonClear {
  background: #BFBFC0;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 131px;
  cursor: pointer;
  height: 50px;
  justify-content: center;

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}

.selectedFilters {
  position: absolute;
  color: #FFFFFF;
  background: #FF575F;
  border-radius: 100px;
  padding: 2px 6px 2px 6px;
  left: 28px;
  top: 36px;
  min-width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}