@import "styles/vars.scss";

.root {
	color: var(--main-black);
}

.area {
	& > div {
		&:first-child {
			font-size: 14px;
			font-weight: 400;
		}

		&:last-child {
			margin-top: 8px;
		}
	}
}
