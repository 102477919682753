@import "styles/vars.scss";

.table {
	cursor: default;
}

.tariff {
	@include flex-align-items-center;
}

.linkColumn {
	width: 30px;
	flex-grow: 0;
}

.link {
	@include flex-align-items-center;

	width: 30px;
	height: 30px;
	cursor: pointer;
	svg {
		path {
			fill: var(--main-blue);
		}
	}
}

.label {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
	opacity: 0.5;
}

.value {
	font-size: 24px;
	line-height: 36px;
	font-weight: 500;
	color: var(--main-black);
}

.tariffLabel {
	font-weight: 400;
	font-size: 16px;
	color: var(--main-black);
}

.tariffTooltip {
	margin: 0 8px 0 0 !important;

	svg {
		path {
			fill: var(--main-grey-border) !important;
		}
	}
}

.tariffServices {
	@include flex-justify-content-center;
	align-items: center;

	max-height: 28px;
	max-width: 28px;
	padding: 6px;
	white-space: nowrap;

	background: var(--main-blue-disabled);
	border-radius: 100px;
	color: var(--main-black);
	font-size: 14px;
}

.services {
	padding: 12px 16px;
}

.servicesHeader {
	font-size: 14px;
	font-weight: 400;
	font-family: $ubuntu-font;
	opacity: 0.5;
}

.servicesBody {
	margin-top: 4px;
}

.service {
	@include flex-align-items-center;

	& > div {
		&:first-child {
			margin-right: 8px;

			color: white;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		&:last-child {
			font-size: 12px;
			font-family: $ubuntu-font;
			color: var(--main-blue);
		}
	}
}

.showMore {
	margin-top: 20px;

	@include flex-justify-content-center;

	span {
		font-weight: 500;
		cursor: pointer;
		font-size: 16px;
		color: var(--main-blue);
		border-bottom: 1px dotted var(--main-blue);
	}
}
