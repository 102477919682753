.card {
	background: var(--main-blue-disabled);
	border-radius: 10px;
	height: 68px;
	padding: 12px;

	&:hover {
		filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
		background: #fff;
		cursor: pointer;
	}
}

.title {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

.user {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

	font-size: 14px;
	line-height: 20px;
}
