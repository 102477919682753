@import "styles/vars.scss";

.bottomBar_root {
  padding: 8px 32px 30px 32px;
  background: #1E1E1F;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 16;
  width: 500px;
  left: 0;
}

.sidebarMenuItem__link:hover,
.sidebarMenuItem__link_active {
  .sidebarMenuItem__icon {


    path {
      fill: #fff;
    }
  }
}

.avatar {
  width: 30px;
  height: 30px;
}

.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0!important;
  border-radius: 20px 20px 0 0 !important;

  h3 {
    width: 348px;
    margin-bottom: 20px;
    text-align: center;
  }
  div {
    width: 348px;
  }
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {


    @include mbDesktopBreakpoint {
      left: -3px!important;
    }
    path {
      fill: #FFFFFF!important;
    }
  }
}

.sidebarMenuItem__button {
  display: flex;
  align-items: center;

  path {
    fill: #fff;
  }
}

.buttonAdd {
  position: absolute;
  top: -55px;
  right: 15px;
}

.sidebarMenuItem {
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;

  &__link {
    @include flex-align-items-center;

    height: 42px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    opacity: 0.7;

    &:hover,
    &_active {
      opacity: 1;

      .sidebarMenuItem__text {
        color: #fff;
      }
    }
  }

  &__text {
    opacity: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $sidebar-closed-width;
    height: 100%;
    border-color: transparent;
    border-style: solid;
    border-width: 0 6px;

    path {
      fill: var(--main-grey-disabled);
    }
  }
}