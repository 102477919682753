@import "styles/vars.scss";

.root {
	display: grid;
	grid-template:
		"status tabs" auto
		"status list" 1fr
		/ auto 1fr;

	padding: 35px 40px;
	height: calc(100vh - 50px);
	overflow: overlay;

	@include mbDesktopBreakpoint {
       padding: 30px 30px 30px 20px!important;
	}
}

.tabs {
	grid-area: tabs;
	margin-left: 8px;
}

.statusFilter {
	grid-area: status;
}

.statusArea {
	position: sticky;
	z-index: 100;
	top: 40px;
	margin: 40px 28px 0 0;
	width: 40px;
}

.content {
	grid-area: list;
	margin: 30px auto 0;
	min-width: 720px;

	@media screen and (max-width: 1380px) {
		margin: 30px 30px 0 auto;
	}

	@include mbDesktopBreakpoint {
		min-width: 440px!important;
	}
}

.pagination {
	padding-bottom: 20px;
}
