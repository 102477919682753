@import "styles/vars.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  overscroll-behavior: none;
}

.root {
  height: 100%;
  width: 100%;

  @include mbDesktopBreakpoint {
    position: relative;
  }
}

.container {
  position: relative;
  min-height: 100vh;
  width: 100%;

  overflow: auto;

  padding-left: $sidebar-opened-width;

  .isMobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @include mdDesktopBreakpoint() {
    padding-left: $sidebar-closed-width;
  }
  @include mbDesktopBreakpoint() {
    padding-left: 0;
  }


}

.containerFullSize {
  padding-left: calc(80px + $sidebar-opened-width);
  padding-right: 160px;

  @include mdDesktopBreakpoint() {
    padding-left: calc(100px + #{$sidebar-closed-width});
  }
}

.containerServices {
  padding-left: calc(180px + $sidebar-opened-width);
  padding-right: 160px;

  @include mdDesktopBreakpoint() {
    padding-left: calc(100px + #{$sidebar-closed-width});
  }
}

.background__blue {
  background: #E5E5E5;
}

.background__white {
  background: #fff;
}

.header {
  position: fixed;
  z-index: 102;
  left: 0;
  right: 0;

  &.headerWithRedLine {
    top: 20px !important;
  }

  &.headerPremiumAlert {
    top: 50px !important;
  }
}

.alert {
  margin-top: 50px;
}
