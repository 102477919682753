@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: column;
  width: 480px;
}

.loadingRow {
  margin: 10px 0;
  width: 100%;
  height: 60px;
  background: var(--main-blue-inactive);
  border-radius: 4px;
}
