@import "styles/vars.scss";

.root {
  margin-top: 60px;

  @include mbDesktopBreakpoint {
    margin-top: 40px!important;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 12px;
}

.content {
  width: 720px;
  border-radius: 10px;
  background: #F2F2F2;
  padding: 20px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
  }
}

.plug {
  display: flex;
  justify-content: center;
  color: #8B8B8D;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.fileContainer {
  margin-top: 20px;
  background: #FFFFFF !important;
  width: 680px;
  min-height: 56px;

  border-radius: 10px;
  padding: 8px 24px;
  margin-bottom: 10px;

  @include mbDesktopBreakpoint {
    width: 400px!important;
  }

}

.fileRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0 !important;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;

    @include mbDesktopBreakpoint {
      max-width: 200px!important;
    }
  }
}

.fileRowTitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-left: 8px;
}

.fileItem {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F2F2F2;
  padding-left: 12px;
}


.filesList {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
  padding-right: 10px;

  :last-child {
    border-bottom: 0 !important;
  }
}

.counter {
  position: absolute;
  top: 20px;
  left: -6px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.20000000298023224px;
  padding-bottom: 2px;
}

.counterWrapper {
  position: relative;

  svg {
    g {
      path {
        fill: #FF575F;
      }

      g {
        path {
          fill: #FFFFFF;
        }
      }

    }
  }
}