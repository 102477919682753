@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 50px);
  //max-height: calc(100vh - 50px);
}

.filters {
  padding: 15px 15px 15px 235px;
  background: #fff;
  @include mdDesktopBreakpoint() {
    padding: 15px 15px 15px 85px;
  }
}

.list {
  padding: 20px 40px;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;

  @include mbDesktopBreakpoint {
    margin-bottom: 30px;
  }
}


.sideContainer {
  width: 500px;
  min-width: 500px;
  background: var(--main-blue-inactive);
}

.tabContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tableWithPagination {
  width: 100%;
  @include flex-align-items-center;
  flex-direction: column;
}

.pagination {
  margin-top: 38px;
  justify-content: center;
}

.tabTitle {
  min-width: 130px;
}

.tabs {
  margin-bottom: 30px;
  margin-top: 20px;

  @include flex-justify-content-center;

  @include mbDesktopBreakpoint {
    width: 500px;
    margin-left: -43px;
  }
}

.tabLabel {
  @include flex-justify-content-center;
}

.bottomLine {
  span {
    height: 6px;
    background: #FFF!important;
  }
}


.tabsRoot {
  overflow-x: auto!important;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E5E5E5;
  }

  &::-webkit-scrollbar-button:end:increment {
    width: 0%;
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background:  linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0) 30%,
                    #E5E5E5 50%,
                    rgba(0, 0, 0, 0) 70%,
                    rgba(0, 0, 0, 0) 100%
    );;
    border-radius: 0;
    margin-left: 40px;
    margin-right: 40px;
  }
}
