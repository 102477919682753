@import "styles/vars.scss";

.root {
  display: flex;
  flex-flow: row;
  gap: 10px;
  margin-bottom: 20px;
}

.mainIcon {
  width: 209px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #FFF;
}

.headerInfo {
  width: 485px;
  height: 100px;
  border-radius: 4px;
  background: #FFF;
  padding: 8px 0 8px 24px;
  display: flex;
  flex-flow: column;
  gap: 6px;
}

.withoutIcon {
  margin-left: 28px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.icon {
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.tooltipDescription {
  margin: 0 0 0 4px !important;
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}

.infoLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}

.date {
  color: var(--, #2D2C46);
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.offer {
  color: #9595A3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.tooltipCard {
  max-height: 260px;
  max-width: 360px !important;
  min-height: 40px;
  padding: 8px 8px 8px 36px !important;
  left: -200px !important;
  background: #3F3F40 !important;

  @include mbDesktopBreakpoint {
    left: -214px !important;
  }

  div {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    font-size: 13px;
    font-family: "Ubuntu", sans-serif;
  }

  svg {
    left: 8px !important;
    top: 8px !important;
  }
}