@import "styles/vars.scss";

.root {
  padding: 16px 40px;
  height: 100%;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 16px;
}

.description {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;


  p {
    margin: 12px 0;
  }

  img {
    max-height: 400px;
  }
}

.content {
 height: calc(100% - 48px);
}

.footer {
  position: sticky;
  margin-left: -40px;
  width: 800px;
  bottom: 0;
  background: white;
  display: flex;
  padding: 12px 40px;
  align-items: center;
  justify-content: end;
  gap: 26px;
  box-shadow: 0px 0px 30px 0px #2224281A;

  @include mbDesktopBreakpoint {
    width: 500px!important;
  }
}
