$line-height: 14px;

.root {
	text-transform: capitalize;
}

.tooltip {
	padding: 15px 23px !important;
	top: calc(100% + 5px) !important;
	left: -5px !important;
}

.labelRoot {
	display: block !important;
	margin: 0 !important;
}

.labelHovered {
	display: none;
}

.statistics {
	display: flex;
	align-items: center;
	column-gap: 15px;
}

.statisticsItem {
	display: flex;
	align-items: center;
	column-gap: 6px;

	font-size: 0.75rem;
	line-height: $line-height;
	color: #fff;
}

.icon {
	height: $line-height;

	svg {
		height: 100%;
	}

	svg,
	path {
		fill: #bfc4df;
	}
}
