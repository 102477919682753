.title {
  h2 {
    font-family: Cera Pro;
    font-size: 16px!important;
    font-weight: 700;
    line-height: 24px!important;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }

  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;


}