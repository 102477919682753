.wrapper {
	width: 100%;
	display: inline-flex;
}

.root {
	position: relative;
	display: inline-block;
	width: 100%;

	svg {
		display: flex;
	}
}

.disabled {
	cursor: not-allowed;

	opacity: 0.3;
}

.button {
	cursor: pointer;
}

.triggerClicked {
	position: relative;
	z-index: 11;

	.icon {
		transform: rotate(180deg);
	}
}

.triggerWrapper {
	user-select: none;
}

.popup {
	padding: 18px 24px;

	position: absolute;
	top: calc(100% + 4px);
	right: 0;
	z-index: 1000;

	cursor: default;
	animation-duration: 0.1s !important;

	min-width: 200px;
	width: 100%;

	background: #fff;
	border-radius: 20px;
	box-shadow: 0 10px 30px rgba(0, 64, 230, 0.3);

	&.popupToRight {
		right: unset;
		left: 0;
	}

	&.popupToTop {
		top: unset;
		bottom: calc(100% + 4px);
	}
}

.popupHide {
	display: none;
}

.withArrow {
	.icon {
		fill: var(--main-black-dark);
		transition: 0.1s;
	}

	.button {
		width: 100%;
	}

	.popup {
		// min-width: 244px;
		// width: calc(100% + 40px);
	}

	.triggerWrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.arrow__light {
	path {
		fill: var(--main-blue-secondary);
	}
}
