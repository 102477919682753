@import "styles/vars.scss";

.root {
 margin-top: 60px;

  @include mbDesktopBreakpoint {
    margin-top: 40px!important;
  }
}

.content {
  background: #F2F2F2;
  width: 720px;
  border-radius: 12px;
  padding: 8px 8px 0 8px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
  }
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.error {
  text-align: right;
  font-size: 12px;
  color: var(--accent-red);
  position: absolute;
  right: -12px;
  top: 30%;
}

.separator {
  margin-left: -8px;
  margin-right: 8px;
  opacity: 0.5;
}

.subtitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 8px;
}

.mainIcon {
  width: 209px;
  height: 100px;
  border-radius: 4px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainDescription {
  border-radius: 4px;
  background: #FFFFFF;
  padding-top: 22px;
  padding-left: 56px;
  width: 485px;
}

.statusDescription {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.card {
  margin: 0 12px 0 12px;

  @include mbDesktopBreakpoint {
    margin: 0!important;
  }
}

.headerRow {
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
}

.plug {
  width: 680px !important;
  background: #FFFFFF !important;
  margin-top: 8px;
  border-radius: 4px!important;

  @include  mbDesktopBreakpoint {
    width: unset!important;
  }
}

.statusInactive {
  svg {
    path {
      fill: #BFBFC0;
    }
  }
}

.statusActive {
  svg {
    path {
      fill: #21BC5F;
    }
  }
}

.termsRow {
  display: flex;
  margin-top: 32px;
  align-items: center;
}

.terms {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  width: 165px;
}

.calendar {
  position: relative;
  margin-left: 25px;
  z-index: 10;
  transform: translateX(-24px);
  min-width: fit-content;
}

.form {
  margin-top: 32px;
}

.infoRow {
  height: 45px;
  padding: 10px 0 14px 0 !important;
}

.row {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;

  @include mbDesktopBreakpoint {
    gap: unset!important;
    justify-content: space-between;
  }
}

.footer {
  width: 720px;
  height: 64px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
  margin-left: -20px;
  margin-top: 32px;
  box-shadow: 0px -20px 20px 0px rgba(34, 36, 40, 0.1019607843);
  border-radius: 0px 0px 12px 12px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
    margin-left: -8px!important;
  }
}
