@import "styles/vars.scss";

.body {
  background: #F2F2F2;
  padding: 24px 20px;
  border-radius: 0 0 12px 12px;
  overflow-x: hidden;
  padding-bottom: 0!important;
  overflow-y: hidden;
  //transform: translateY(0%);
  //animation: ani 2s forwards;
  margin-bottom: 32px;

  @include mbDesktopBreakpoint {
     padding: 24px 10px!important;
  }
}

.root {
  margin-bottom: 40px;
  position: relative;
  border-radius: 0 0 12px 12px;

  @include mbDesktopBreakpoint {
    width: 460px;
    margin-left: -10px;
  }
}

.stripe {
  display: flex;
  justify-content: center;
  background: #F2F2F2;

  div {
    height: 1px;
    background: #BFBFC0;
    width: 680px;
  }
}

.stripe2 {
  padding-bottom: 20px;
  //background: #F2F2F2;
  border-radius: 0 0 12px 12px;
}

svg {
  opacity: 1;
}

.content {
  padding-right: 8px;
  background: #F2F2F2;
  border-radius: 0 0 12px 12px;
  padding-bottom: 20px;
  padding-top: 8px;

  ::-webkit-scrollbar-track {
    background: white;

  }
}

.bodyUp {
  background: #F2F2F2;
  padding: 24px 20px;
  border-radius: 0 0 12px 12px;
  padding-bottom: 0!important;
  overflow-y: hidden;
  transform: translateY(0%);

  @include mbDesktopBreakpoint {
    padding: 24px 10px!important;
  }

  animation: aniUp 1s forwards;
}

@keyframes aniUp {
  0% {max-height: 50vw;}
  100% {max-height: 0;}
}

@keyframes ani {
  0% {max-height: 0;}
  100% {max-height: 100vw;}
}