@import "styles/keyframes";

.root {
  height: 100%;
  overflow: hidden;
}

.loadingWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: rotation 2s linear infinite;
  width: 40px !important;
  height: 40px !important;
  margin-left: 15px;
  margin-bottom: -3px;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 35px 20px;
  margin-bottom: 42px;
  overflow-y: scroll;
  height: calc(100% - 50px);
}

.actions {
  position: absolute;
  bottom: 0;
  height: 64px;
  top: unset;
  width: 684px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding-right: 40px;
  left: 0;
  box-shadow: 0 0 30px 0 #2224281A;
  background: #FFFFFF;


  .cancelBtn {
    margin-right: 32px;
    margin-top: 0;

    svg {
      width: 30px !important;
      height: 30px !important;
      opacity: 1 !important;
      margin-right: 0 !important;
    }
  }
}

.actionsMobile {
  position: absolute;
  bottom: 0;
  height: 84px;
  top: unset;
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 0;
  box-shadow: 0 0 30px 0 #2224281A;
  background: #FFFFFF;
  padding-right: 0 !important;


  .cancelBtn {
    margin-right: 32px;
    margin-top: 0;

    svg {
      width: 30px !important;
      height: 30px !important;
      opacity: 1 !important;
      margin-right: 0 !important;
    }
  }
}
