@import "styles/vars.scss";

.root {
	display: flex;
	flex-flow: column;
}

.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	margin-left: 40px;

	@include mbDesktopBreakpoint {
		margin-top: 20px!important;
		width: 500px;
		margin-left: 0;
	}
}

.element {
	display: flex;
	justify-content: center;
}
