@import "styles/vars.scss";

.number {
	font-size: 16px;
	line-height: 24px;
	color: var(--main-black);
}

.date {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--main-black);
	opacity: 0.5;
	white-space: nowrap;
}
