@import "styles/vars.scss";

.root {
	padding: 39px 4px 0px 10px;

	width: 325px;
}

.officesOpened {
	pointer-events: auto;
}

.closeIcon {
	cursor: pointer;
	transform: scale(1.5);
	fill: #fff;
	position: absolute;
	right: 9px;
	top: 11px;
}

.form {
	margin-right: 14px;
}

.officesContainer {
	margin-top: 22px;
	padding-right: 14px;

	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
}

.office {
	margin-bottom: 22px;
}

.title {
	position: sticky;
	top: 0;

	margin-bottom: 14px;

	width: 100%;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
	background-color: rgba(45, 44, 70, 0.4);
	padding: 6px 13px;
	border-radius: 10px;
	word-break: break-all;
}

.department {
	margin-bottom: 20px;
	margin-left: 12px;
}

.departmentName {
	margin-bottom: 12px;

	font-weight: 500;
	font-size: 16px;
	color: #fff;
	word-break: break-all;
}

.user {
	margin: 8px 0;
	display: flex;
	align-items: center;

	cursor: pointer;

	&:hover {
		.name {
			color: var(--main-blue);
		}
	}
}

.userInfo {
	display: flex;
	flex-direction: column;
}

.avatarMargin {
	margin-right: 12px;
}

.position,
.name {
	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: #fff;

	transition: 0.2s;
}

.position {
	opacity: 0.5;
}
