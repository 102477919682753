.root {
	width: 0;

	font-size: 12px;

	display: flex;
	flex-direction: column;

	position: sticky;
	top: 10vh;
}

.title {
	color: var(--main-black);
	opacity: 0.5;
	margin-bottom: 6px;
}

.wrapper {
	background: #fff;
	border-radius: 10px;
	padding: 11px 0px 11px 11px;
	color: var(--main-blue-secondary);

	width: fit-content;
	max-width: 84px;

	svg {
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;

		margin-right: 7px;

		height: 24px;
		width: 24px;

		fill: var(--main-blue-secondary);

		path:first-child {
			fill: var(--main-blue-secondary);
		}
	}
}

.active {
	color: var(--main-blue);

	svg {
		fill: var(--main-blue);
		path:first-child {
			fill: var(--main-blue);
		}
	}
}

.status {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;

	margin-bottom: 14px;

	cursor: pointer;

	span {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.status:last-child {
	margin-bottom: 0px;
}
