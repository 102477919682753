.root {
	position: relative;
	display: flex;
	flex-flow: column;
	height: 100%;
	overflow: hidden;
	width: 100%;
}

.mapContainer {
	position: relative;
	order: 2;
	height: 100%;

	&.mapContainerFirst {
		order: 1;
	}
}

.handlersContainer {
	order: 1;

	&.handlersContainerSecond {
		order: 2;
	}
}

.blackAndWhite {
	[class*="-ground-pane"] {
		filter: grayscale(100%);
	}
}

.mapIsBusy {
	opacity: 0.5;
	transition: opacity 1s;
}

.mapIsNotBusy {
	opacity: 1;
	transition: opacity 1s;
}