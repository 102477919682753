.wrap {
  position: static;
  background: rgb(232, 239, 255);
  background: linear-gradient(3deg, rgba(232, 239, 255, 1) 0%, rgba(160, 191, 247, 1) 100%);
}

.wrap img {
  width: 100%;
  min-width: 100%;
  position: static;
}