.customInfoRow {
	padding: 10px 0 5px 12px;
}

.inputsContainer {
	display: flex;
	align-items: center;
}

.selectContainer {
	display: flex;
	align-items: center;
	max-width: 55px;
}
