@import "styles/vars.scss";

.root {
   margin-top: 60px;
}

.title {
    font-family: Cera Pro;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    margin-bottom: 12px;
}

.plug {
    width: 720px;

    @include mbDesktopBreakpoint {
        width: 440px!important;
    };
}