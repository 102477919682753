.addressContainer {
	padding: 0 12px;

	display: flex;
	flex-direction: row;

	border-bottom: 1px solid var(--main-blue-secondary);
}

.selectContainer {
	flex: 18 1;

	position: relative;
}

.noHomeNumber {
	display: flex;
	align-items: center;
}

.verticalDivider {
	margin: 0 12px;

	width: 1px;
	height: 20px;

	background: var(--main-blue-inactive);
}

.inputWrapper {
	flex: 5 1;
}
