@import "styles/vars.scss";

.root {
	display: flex;
	justify-content: flex-end;

	&.rootWithLeftBottom {
		justify-content: space-between;
	}
}

.statsContainer {
	display: flex;
	align-items: center;
}

.statItem {
	display: flex;
	align-items: center;

	span {
		font-family: $ubuntu-font;
		font-size: 12px;
		line-height: 14px;
		color: #000000;
		opacity: 0.5;
	}

	svg {
		margin: 0 4px 0 12px;
	}

	path {
		fill: var(--main-blue-secondary);
	}
}
