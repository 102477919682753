.root {
background: white;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.rowBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.callIcon {
  margin-right: 24px;
}

.date {
  color: #9595A3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.label {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}