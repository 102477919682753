@import "styles/vars.scss";

.wrapper {
  margin-top: 20px;
  //padding-bottom: 36px;
  border-radius: 12px !important;
  //height: 540px;
  position: relative;
  //overflow-x: scroll;
  //overflow-y: hidden;
  display: flex;
  height: 572px;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8B8B8D;
  }

  &::-webkit-scrollbar-button:end:increment {
    width: 20%;
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-track {

    background: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0) 30%,
                    #BFBFC0 50%,
                    rgba(0, 0, 0, 0) 70%,
                    rgba(0, 0, 0, 0) 100%
    );;
    border-radius: 0;
    margin-left: 40px;
  }
}

.ScrollbarsCustom-TrackX {
  background: linear-gradient(
                  to top,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0) 30%,
                  #BFBFC0 50%,
                  rgba(0, 0, 0, 0) 70%,
                  rgba(0, 0, 0, 0) 100%
  );;
  border-radius: 0;
  margin-left: 40px;

  @include  mbDesktopBreakpoint {
    width: 480px!important;
  }
}

.ScrollbarsCustom-Scroller {
  .ScrollbarsCustom-Scroller {
    overflow-x: hidden !important;
  }


}

.ScrollbarsCustom-TrackY {
  right: -14px !important;
}

.tableWrapper {
  border-radius: 12px;
  background: #FFFFFF;
  height: 540px;
  width: 1080px;
}

.tw {
  height: 540px;
}

.table {
  position: relative;
  //padding-bottom: 32px;
  //min-width: 1200px;
  //width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  //overflow: hidden;
  border-spacing: 0;
  height: 540px !important;

  thead {
    tr {
      vertical-align: bottom;
    }
  }

  tbody {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    &:last-child {
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;
    }
  }
}

.arrowIcon {
  margin-bottom: 1px;
  margin-left: 14px;

  opacity: 0;
  fill: var(--main-grey-disabled);

  &.arrowIconAsc {
    opacity: 1;
  }

  &.arrowIconDesc {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.td {
  &:last-child {
    .tableTdContent {
      border-right: none;
    }
  }
}

.displayNone {
  display: none;
  animation: aniReverse 1,5s forwards;
}

.tdContent {
  padding: 26px 21px;
  height: 70px;
  border-right: 1px solid var(--main-grey-light);
  font-family: $ubuntu-font;
}

.plugTdContent {
  width: 1096px;
  height: 482px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: linear-gradient(86.64deg, #FFFFFF 29.3%, #F7F8FF 72.73%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ABC2F7;
}

.plug {
  height: 32px;
  background: #E5E5E5 !important;
  z-index: 1;
  border: 1px solid #E5E5E5;
  margin-bottom: -1px;
  margin-left: -2px;
}

.plugVertical {
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  width: 100px;
  height: 563px;
  position: absolute;
  right: 10px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  opacity: 0.8;
  animation: ani 1s forwards;
}

@keyframes ani {
  0% {opacity: 0;}
  100% {opacity: 0.8;}
}
@keyframes aniReverse {
  0% {display: unset;}
  100% {display: none;}
}

.plugThContent {
  width: 1096px !important;
  height: 76px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom: 5px solid var(--main-blue);
  background: #FFFFFF;
}


.tablePlug {
  min-width: 1096px !important;
}

.fixed {
  :first-child {
    position: sticky;
    left: 0;
  }
}

.fixedHeader {
  & > :first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
  }
}


.fixedFirstColumn {
  .headerTh {
    width: 285px !important;
    background-color: #fff;
    border-right: 1px solid var(--main-grey-light);
    position: relative;

    .svg {
      position: absolute;
      right: 16px;
      bottom: 9px;
    }

    //border-bottom: 5px solid rgba(127, 141, 239, 0.5);
    &:first-child {
      border-top-left-radius: 12px;
      border-bottom: 5px solid rgba(127, 141, 239, 0.5);
    }

    &:last-child {
      border-top-right-radius: 12px;
    }

    .headerThContent {
      border-right: none;
      padding: 8px 20px 12px 20px;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;

    }
  }

  .td {
    background-color: #fff;
    z-index: 1;
    //border-right: 1px solid var(--main-grey-light);

    .tdContent {
      //padding: 7px 12px 13px 12px;
      font-family: Ubuntu;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.20000000298023224px;
      text-align: left;

    }
  }
}

.header {
  height: 50px;
  position: sticky;
  top: 0px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 10px 30px 0px #0040E533;
  z-index: 3;
}

.footer {
  height: 72px;
  position: sticky;
  bottom: 0px;
  z-index: 3;
  box-shadow: 0px 10px 30px 0px #0040E533;
  border-bottom-right-radius: 12px;


  .row {
    & > :first-child {
      .tdContent {
        border-bottom-left-radius: 12px;
      }
    }
  }
}

.headerTh {
  border-bottom: 5px solid var(--main-blue);
  font-family: $ubuntu-font;

  &:last-child {
    .headerThContent {
      border-right: none;
    }
  }
}

.headerThContent {
  padding: 23px 20px 10px 20px;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  line-height: 140%;
  border-right: 1px solid var(--main-grey-light);
}

.row {
  cursor: pointer;
  transition: 0.07s;

  &:hover {
    background-color: #fff;
  }
}

.buttonscroll {
  height: 34px;
  width: 57px;
  display: flex;
  border-radius: 26px;
  background: #FFFFFF;
  box-shadow: 0 0 30px 0 #2224281A;
  position: absolute;
  bottom: -45px;
  right: 40px;

  div {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.rowSelected {
  border-top: 2px solid #336BEB !important;
  border-bottom: 2px solid #336BEB !important;
  height: 72px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.fixedPlugVertical {
  width: 50px;
  height: 563px;
  position: absolute;
  left: 270px;
  opacity: 0.5;
  z-index: 5;
  background: linear-gradient(90deg, #124BF640 0%, rgba(255, 255, 255, 0) 100%);
}
