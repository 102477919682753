@import "styles/vars.scss";

.root {
  margin-top: 24px;
}

.title {
  color: #2D2C46;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  margin-bottom: 12px;
}

.chatCard {
  margin-top: 20px;
  width: 720px;
  height: 400px;
  border-radius: 20px;
  background: #F2F2F2;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BFBFC0;

  @include mbDesktopBreakpoint {
    width: unset !important;
  }
}

.callsWrapper {
  width: 720px !important;
  padding: 20px;

  @include mbDesktopBreakpoint {
    width: 460px !important;
  }
}

.callsSeparator {
  color: #2D2C46;
  text-align: start;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  border: 0 !important;
}

.radioBody {
  @include flex-align-items-center;
  justify-content: space-between;
  width: 100%;
}

.tableWithPagination {
  width: 100%;

  @include flex-align-items-center;
  flex-direction: column;
}