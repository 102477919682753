@import "styles/vars.scss";

.body {
	background-color: #fff;
	border-radius: 10px;
	padding: 12px 16px;
	margin-top: 5px;
	color: var(--main-black);
}

.tariff {
	padding-bottom: 16px;
	border-bottom: 1px solid var(--main-grey-light);
}

.tooltipContainer {
	@include flex-align-items-center;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);
	svg path {
		fill: var(--main-grey-light);
	}
	.tooltip {
		margin: 0 !important;
	}
}

.tariffContainer {
	max-width: 112px;
	background: var(--main-blue-disabled);
	border-radius: 100px;
	padding: 4px 12px 4px 6px;
}

.info {
	margin-left: 12px;
}

.formWrapper {
	margin-top: 20px;
}

.stats {
	font-size: 14px;
	font-family: $ubuntu-font;
	opacity: 0.5;
	margin-top: 16px;
}

.form {
	margin-top: 19px;
	@include flex-align-items-center;
}

.counterInput {
	input {
		width: 92px;
	}
}

.progress {
	margin-left: 52px;
	transform: translateY(100%);
}

.button {
	margin-left: 50px;
}

.applyButton {
	margin-left: 30px;
}

.footer {
	margin-top: 25px;
	@include flex-align-items-center;
}

.table {
	table td {
		padding-top: 0 !important;
	}
}

.select {
	svg {
		width: 16px;
		height: 16px;
		path {
			fill: var(--main-blue);
		}
	}
}

.selectWrapper {
	height: 20px;
	max-width: 108px;
}

.customSelectWrapper {
	min-width: 108px !important;
}

.rubble {
	margin-left: 5px;
	opacity: 0.5;
}
