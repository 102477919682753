.activeImg {
	position: relative;

	height: 304px;
	width: 100%;

	border: 1px solid var(--main-blue-secondary);
	border-radius: 10px;

	img {
		width: 100%;
		height: 100%;

		object-fit: contain;
	}
}

.noImg {
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 100px;
		height: 112px;
	}
}

.arrowPrev,
.arrowNext {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;

	border: 2px solid var(--main-blue-secondary);
	background: #fff;
	border-radius: 20px;

	cursor: pointer;

	transition: 0.2s;

	svg {
		transition: 0.2s;

		fill: var(--main-blue-secondary);
	}

	&:hover {
		border: 2px solid var(--main-blue);

		svg {
			fill: var(--main-blue);
		}
	}
}

.arrowDisabled {
	cursor: not-allowed;

	&:hover {
		border: 2px solid var(--main-blue-secondary);

		svg {
			fill: var(--main-blue-secondary);
		}
	}
}

.arrowPrev {
	left: -20px;

	svg {
		transform: translateX(-1px) rotate(90deg);
	}
}

.arrowNext {
	right: -20px;

	svg {
		transform: translateX(1px) rotate(-90deg);
	}
}

.previewContainer {
	margin-top: 10px;

	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	cursor: grab;
	column-gap: 10px;

	&::-webkit-scrollbar {
		display: none;
	}
}

.grabbing {
	cursor: grabbing;

	.photoItem {
		pointer-events: none;
	}
}

.photoItem {
	img {
		width: 80px;
		height: 80px;

		object-fit: cover;

		border-radius: 6px;
	}
}
