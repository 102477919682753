@import "styles/vars.scss";

.supportData {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 8px;
	margin-left: 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: var(--main-blue-secondary);
	font-family: $ubuntu-font;
}

.supportDataManagerInfo {
	display: flex;
	align-items: flex-start;

	p {
		margin: 0;
	}

	> p {
		margin-right: 12px;
	}
}

.supportButton {
	margin-left: 14px;
}

.supportDataIdContainer {
	text-align: right;
	> div {
		margin: 0;
		justify-content: flex-end;
	}
}

.supportDataId {
	margin-right: 10px;
	@include flex-align-items-center;
	span {
		margin-left: 10px;
		&:last-child {
			margin-left: 10px;
			cursor: pointer;
			svg {
				width: 11px;
				height: 11px;
				path {
					fill: var(--main-blue-secondary);
				}
			}
		}
	}
}
