.root {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

	display: flex;
	flex-direction: column;
}

.dot {
	margin: 1px 0;

	width: 6px;
	height: 6px;

	border-radius: 50%;

	&__avito {
		background: #97cf26;
	}
	&__yandex {
		background: var(--accent-red);
	}
	&__cian {
		background: #0468ff;
	}
}
