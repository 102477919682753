.customInfoRow {
	padding: 10px 0 5px 12px;
}

.hideLabel {
	position: absolute;
	left: 0;
	top: 0px;

	width: 49%;
	height: 100%;

	background: #F2F2F2;
}
