.popupContent {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.rangesContainer {
	margin-right: 96px;
	margin-left: 24px;
}

.rangeItem {
	margin: 12px 0;
	padding: 4px 0;

	white-space: nowrap;

	cursor: pointer;

	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: var(--main-blue);
	transition: 0.2s;

	&.rangeItemActive,
	&:hover {
		color: var(--main-black);
	}
}

.label {
	display: flex;
	align-items: center;

	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-black);

	svg {
		transform: translateY(-2px);
		margin-right: 12px;
	}

	&.notSelectedDate {
		color:  #BFBFC0;
	}
}

.card {
	min-width: 470px;
}

.reset {
	cursor: not-allowed;
}

.popupWrapper {
	width: auto !important;
}
