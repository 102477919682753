@import "styles/vars.scss";

.root {
  border-radius: 20px;
  transition: 0.2s;

  //&:hover {
  //  box-shadow: $default-box-shadow;
  //  border-color: #fff;
  //}
}

.contacts,
.card {
  margin-top: 12px;
}

.card {
  border: 1px solid rgba(var(--main-blue-secondary-rgb), 0.4);
  border-radius: 10px;
}

.cardRoot {
  border-radius: 12px;
  background: #F2F2F2;
  width: 720px;
  display: flex;
  flex-flow: column;
  padding: 6px;
  max-height: 800px;

  @include mbDesktopBreakpoint {
    width: 480px!important;
  }
}

.tableRoot {
  width: 680px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
  }
}

.titleCollapse {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.collapse {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.labelText {
  margin-right: 8px !important;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  margin-top: 32px;
  gap: 86px;
}

.labelObject {
  color: #9595A3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.content {
  padding: 12px;
  max-height: 644px;
  overflow-y: auto;

  @include mbDesktopBreakpoint {
    padding: 12px 10px!important;
    overflow-x: hidden;
  }
}

.title {
  color: #2D2C46;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
}

.cardApp {
  margin-top: 12px;
  width: 680px;
  height: 100px;
  border-radius: 4px;
  background: var(--BW-5, #FFF);
  padding: 8px 15px 4px;
  cursor: pointer;

  @include mbDesktopBreakpoint {
    width: 440px!important;
    padding: 0!important;
    height: 96px!important;
  }

  /* Default object */
  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);
}

