:root {
	// colors
	--main-blue: #336BEB;
	--main-blue-rgb: 127, 141, 239;

	--main-blue-40: #c4cbf6;
	--main-blue-secondary: #bfc4df;
	--main-blue-secondary-rgb: 191, 196, 223;

	--main-blue-inactive: #D5D3CE;
	--main-blue-disabled: #f2f5fb;
	--main-blue-link: #336BEB;

	--main-black: #2d2c46;
	--main-black-rgb: 45, 44, 70;
	--main-black-dark: #323232;

	--main-grey: #484848;
	--main-grey-inactive: #68687c;
	--main-grey-disabled: #9595a3;
	--main-grey-light: #cccccc;
	--main-grey-border: #e5e5e5;
	--main-grey-sub: #afafaf;

	--accent-red: #ff575f;
	--accent-green: #49d7bd;
	--accent-yellow: #ffd403;
	--accent-orange: #ff9052;

	// stages in requests colors
	--stage-banner-grey: #ababc8;

	// ckeditor
	--ck-border-radius: 10px !important;
	--ck-color-toolbar-background: #fff !important;
	--ck-color-button-default-hover-background: #fff !important;
	--ck-color-button-default-active-background: var(--main-blue) !important;
	--ck-color-button-on-hover-background: var(--main-blue) !important;
	--ck-color-focus-border: var(--main-blue) !important;
}
