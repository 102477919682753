.root {
  padding: 4px;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #3F3F40;
  margin-bottom: 4px;
}

.description {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #3F3F40;
  opacity: 0.8;
}

.rowEnd {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
  margin-top: 32px;
}


.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    path {
      fill: #FFFFFF!important;
    }
  }
}

.contentWrapperClassNameMobile {
  position: absolute;
  bottom: 0;
  max-height: 100vh!important;
  width: 500px!important;
  padding: 20px 20px 40px 20px!important;
  border-radius: 20px 20px 0px 0px!important;
}