@import "styles/vars.scss";

.root {
	padding: 24px 76px 24px 40px;

	display: flex;
	align-items: flex-start;

	background: #fff;
	border-radius: 20px;
}

.children {
	width: 100%;

	margin-right: 80px;
}

.header {
	font-size: 18px;
	color: var(--main-black);
	margin: 0 0 13px;
}

.text {
	font-family: $ubuntu-font;
	font-size: 16px;
	color: var(--main-black);
	opacity: 0.8;
}
