@import "styles/vars.scss";

.aboutMe {
	margin-bottom: 8px;

	font-family: $ubuntu-font;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: var(--main-grey);

	ol,
	ul,
	p {
		margin: 0;
	}

	ol,
	ul {
		padding-left: 15px;
	}
}

.aboutMeNoInfo {
	color: var(--main-grey);
	opacity: 0.5;
}
