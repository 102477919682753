@import "styles/vars.scss";

.root {
  position: relative;
  width: 720px;

  padding: 0px 20px;
  height: 100px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 #2224281A;
}

.tooltipDescription {
  margin-left: 8px;
}

.mini {
  width: 440px!important;
  height: 96px!important;

  .content {
    height: 96px!important;
    padding: 2px 6px 2px 0!important;
    width: 312px;

    &>:first-child {
      width: 175px;
    }
  }

  .countsContainer {
    gap: 6px;
  }


  .price {
    font-size: 14px!important;
  }

  .img {
    margin: -7px 8px -7px -20px;

    position: relative;
    width: 96px;
    height: 96px;

    img {
      width: 100%;
      height: 100%;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      object-fit: cover;
    }

    svg {
      position: absolute;
      margin: 4px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  height: 100px;
  padding: 4px 6px 4px 0;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  background: rgba(77, 77, 104, 0.3);
  backdrop-filter: blur(4px);
}

.loadingHide {
  display: none;
}

.countsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  column-gap: 12px;
}

.countBlock {
  padding: 6px 8px;
  height: 24px;
  max-width: 70px;
  min-width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 35px;
  background: #F2F2F2;
  gap: 4px;

  svg {
    margin-right: -11px;
  }
}

.count {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #68687C;
}


.responsible {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  width: available;
  padding-right: 4px;
  justify-content: end;

  .responsibleLogo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
  }

  span {
    max-width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;
    font-family: $ubuntu-font;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #7E7E7E;
  }
}

.titleAndPrice {
  padding-right: 3px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-black);
  letter-spacing: 0.2px;
}

.noImg {
  align-items: center;
  display: flex;
  justify-content: center;
}
.img {
  margin: -7px 20px -7px -20px;

  position: relative;
  width: 108px;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    object-fit: cover;
  }

  svg {
    position: absolute;
    margin: 4px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--main-black);
  letter-spacing: 0.2px;

  .notification {
    margin-left: -20px;
    height: 18px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.end {
  justify-content: end!important;
}

.flexColumnRes {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.applicationFlexRow {
  margin-right: -23px;
}

.flexBetween {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  display: flex;
}

.contact {
  color: #336BEB;
  font-family: $ubuntu-font;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.price {
  text-align: right;
  white-space: nowrap;
  padding-right: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.stage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  width: 26px;;
  height: 100%;

  border-radius: 0 var(--ck-border-radius) var(--ck-border-radius) 0;
}

.stageCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;

  border-radius: 50%;

  width: 18px;
  height: 18px;
}

.stagePopup {
  top: unset !important;
  bottom: 50% !important;
  left: unset !important;
  right: 50% !important;

  height: 40px;
  padding-left: 12px !important;

  width: max-content;

  padding-right: 2rem !important;

  line-height: 1;

  transform: none !important;
  font-weight: 400;
  font-size: 13px !important;
  font-family: "Ubuntu", sans-serif;
}

.wrapperCard {
  margin-top: 60px;
  display: block;
}

.label {
  color: #2D2C46;
  font-family: "Cera Pro";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}

.delete {
  color: #FF575F;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

.row {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    fill: #FF575F;
  }
}
.rowBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 8px;
}