@import "styles/vars.scss";

.root {
	h3 {
		margin: 0;

		font-size: 18px;
		line-height: 24px;
	}
	color: var(--main-black);
}

.container {
	ul {
		margin: 0;
		padding: 0;

		list-style-type: none;
	}
	.cardsContainer {
		margin-top: 24px;
	}
}

.listContainer {
	display: flex;
	align-items: flex-start;

	padding-right: 15px !important;
	& > * {
		flex-basis: 50%;
		&:first-child {
			margin-right: 80px;
		}
	}
}

.cardsContainer {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	overflow: hidden;

	& > * {
		margin-bottom: 13px;
		margin-right: 15px;

		min-width: 300px;
		& > div {
			& > div:last-child {
				font-size: 14px;
				font-family: $default-font;
			}
		}
	}
}

.cardsCollapse {
	span {
		font-weight: 500;
	}
	> div > div > span {
		margin-right: 12px !important;
	}
	//margin-top: 36px;
}

.cardsLabel {
	margin-right: 12px;
	font-family: Cera Pro;
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;
}

.sumAll {
	& > * {
		font-family: $default-font;
		font-weight: 700;
	}
}

.small {
	margin-top: 0;
}

.smallInfoRows {
	margin-right: 12px;

	width: 260px;
	min-width: auto;
	&:nth-child(2) {
		margin-right: 0;
	}
}

.smallListContainer {
	& > li {
		&:first-child {
			margin-right: 58px;
		}
	}
}

.tooltip {
	display: flex;
	align-items: center;
}

.tooltipInfo {
	margin: 0 !important;
}

.tooltipCard {
	padding: 12px 18px 16px 28px;
	background: #3F3F40!important;
	max-width: 270px;
}

.tooltipText {
	font-weight: 500;
	color: var(--accent-green);
	span {
		margin-left: 4px;

		font-weight: 400;
	}
}

.priceChangeContainer {
	font-size: 12px;
	font-weight: 400;
	.priceChangeHeader {
		opacity: 0.5;
	}
	.priceChangeInfo {
		margin-top: 12px;
	}
}

.currency {
	font-family: $default-font;
	font-weight: 400;
	font-size: 14px;
}

.infoRow {
	font-weight: 500;
}

.expensesLabel {
	margin-right: 12px !important;

	color: var(--main-black);
	font-weight: 700;
	font-size: 20px;
}

.radioButton {
	margin-top: 28px;
	label {
		border-radius: 100px;
		font-family: $ubuntu-font;
		font-weight: 400;
		height: 28px;
	}
	label[class^="RadioButton_checked"] {
		font-weight: 500;
		color: white !important;
	}
}

.detalizationContainer {
	margin-top: 28px;
}

.chartWrapper {
	margin-top: 12px;
}

.unknown {
	color: #9696A2;
	font-weight: 500;
	font-size: 14px;
}