.root {
  margin-top: 80px;

  width: 100%;
}

.rowsBlock {
  h3 {
    margin: 24px 0 0;
    font-family: Cera Pro;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
  }
}
