@import "styles/vars.scss";

.body {
	margin-top: 24px;
	padding: 12px;
}

.wrapper {
	justify-content: space-between;

	margin-top: 16px;
	padding: 24px;

	background-color: #fff;
	border-radius: 10px;

	&.sellerWithoutContact,
	&.sellerWithoutAgent {
		@include flex-align-items-center;
	}
}

.button {
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: var(--main-blue);
	&.active {
		padding-bottom: 24px;
		color: var(--main-black);
		border-bottom: 1px solid var(--main-blue-secondary);
	}
}

.buttonBody {
	margin-top: 24px;
}

.radio {
	font-size: 16px;
}

.objectWrapper {
	position: relative;
}

.objectEdit {
	@include flex-align-items-center;

	position: absolute;
	top: 50%;
	right: 24px;
	transform: translateY(-50%);

	cursor: pointer;
	svg {
		width: 16px;
		height: 16px;
		fill: var(--main-grey-disabled);
	}
}

.showTime {
	display: flex;
	justify-content: flex-end;

	margin-top: 20px;
	padding-top: 16px;

	border-top: 1px solid rgba(var(--main-blue-secondary-rgb), 0.3);
}

.contactContent {
	max-height: 620px !important;
	overflow-y: unset!important;
}
