@import "styles/vars.scss";

.tableFilters {
	padding: 16px 80px 15px 320px;

	position: fixed;
	top: 0;
	z-index: 5;
	width: 100%;
	height: 50px;
	background-color: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);

	@include mdDesktopBreakpoint() {
		padding: 16px 80px 15px 170px;
	}
}
