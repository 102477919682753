.contentWrapperMobile {
  position: absolute;
  bottom: 0;
  width: 500px !important;
  padding: 20px 15px 60px 15px !important;
  border-radius: 20px 20px 0px 0px !important;
}

.modalClose {
  background: #1E1E1F !important;
  border: 0 !important;

  svg {
    path {
      fill: #FFFFFF !important;
    }
  }
}


.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 32px;
}

.submitBtnContainer {
  display: flex;
  justify-content: end;
  margin-top: 32px;
}