@import "styles/vars.scss";

.root {
  position: absolute;
  bottom: 15px;
  max-width: 350px;
  width: 100%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 120%;
}

.btn {
  display: inline-flex !important;
  font-size: 12px !important;
}
