@import "styles/vars.scss";

.root {
  margin-top: 60px;
  margin-bottom: 90px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-bottom: 36px;
}

.subtitle {
  font-family: Cera Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-bottom: 36px;
}

.content {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  width: 1095px;
  height: 604px;
  gap: 66px;

  @include mbDesktopBreakpoint {
    flex-flow: column;
    width: 480px!important;
    gap: 32px!important;
    padding: 20px!important;
  }
}

.calendar {
  @include mbDesktopBreakpoint {
    right: -54px;
  }
}

.buttonContainer {
  margin-top: 60px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.arrow {
  color: #BFBFC0;

  svg {
    path {
      fill: #BFBFC0;
    }
  }
}

.infoRowLabel {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #8B8B8D;
}

.inputs {
  width: 400px;
  height: 88px;
  display: flex;
  flex-flow: column;

  @include mbDesktopBreakpoint {
    width: unset!important;
  }
}

.fileContainer {
  margin-top: 20px;
  width: 560px;
  min-height: 56px;
  border-radius: 4px;
  padding: 8px 40px 8px 20px;
  margin-bottom: 10px;
  background: #F2F2F2;

  @include mbDesktopBreakpoint {
    width: 400px !important;
  }

}

.fileContainerNotOpen {
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 35px 0px #124BF659;
  }
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.fileRow {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0 !important;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 360px;

    @include mbDesktopBreakpoint {
      max-width: 200px !important;
    }
  }
}

.rowOpen {
  border-bottom: 1px solid #BFBFC0;
  padding-bottom: 6px;
  cursor: pointer;
}

.fileRowTitle {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #1E1E1F;
  margin-left: 8px;
}

.fileItem {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #BFBFC0;
  padding-left: 12px;
}


.filesList {
  margin-top: 10px;
  max-height: 173px;
  overflow-y: scroll;
  padding-right: 20px;
  margin-left: 12px;
  margin-right: -24px;

  &::-webkit-scrollbar-thumb {
    background: #BFBFC0;
  }

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
  }

  :last-child {
    border-bottom: 0 !important;
  }
}

.counter {
  position: absolute;
  top: 20px;
  left: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF575F;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.20000000298023224px;
  padding-bottom: 2px;
}

.counterWrapper {
  position: relative;
  height: 40px;

  svg {
    g {
      path {
        //fill: #FF575F;
      }

      g {
        path {
          fill: #FFFFFF;
        }
      }

    }
  }
}