@import "styles/vars.scss";

.root {
  border-radius: 4px;
  background: white;
  padding: 8px 0 4px 20px;
  position: relative;
  height: 100px;
  display: flex;

  box-shadow: 0px 0px 30px 0px rgba(34, 36, 40, 0.10);

  &:hover {
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 64, 229, 0.25);
    transition: 0.2s;
  }
}

.backgroundWhite {
  background: white !important;
}

.atAC {
  width: 472px!important;
}

.atGPmini {
  width: 424px!important;
}

.img {
  img {
    width: 108px!important;
    height: 100px!important;
    object-fit: cover;
    margin-top: -8px;
    margin-left: -20px;
    border-radius: 10px 0px 0px 10px;
    margin-right: 12px;
  }

  svg {
    object-fit: cover;
    margin-top: -8px;
    margin-left: -20px;
    border-radius: 10px 0px 0px 10px;
    margin-right: 12px;
    background: #BFBFC0;
  }
}

.miniImg {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 82px!important;
    height: 80px!important;
    object-fit: cover;
    margin-top: -8px;
    margin-left: -20px;
    border-radius: 10px 0px 0px 10px;
    margin-right: 12px;
  }

  @include mbDesktopBreakpoint {
    img {
      width: 96px!important;
      height: 96px!important;
    }
  }

  svg {
    object-fit: cover;
    margin-top: -8px;
    border-radius: 10px 0px 0px 10px;
    margin-right: 12px;
    width: 96px;
    height: 96px;
    margin-left: -12px;
  }
}

.mini {
  height: 96px;
  width: 440px;
  padding: 8px 0 2px 12px;

  @include mbDesktopBreakpoint {
    height: 96px!important;
  }

  .title {
    width: 300px;
  }
}

.atSelect {
  height: 96px;
  width: 476px;
  padding: 8px 0 2px 12px;

  @include mbDesktopBreakpoint {
    height: 96px!important;
  }

  .title {
    width: 300px;
  }
}


.popup {
margin-right: 12px;

}

.red {
  color: #FF575F!important;
}

.popupCard {
  width: 300px!important;
}

.withStrap {
  padding: 8px 26px 4px 20px;
}

.row {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.rowBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.strap {
  right: 0px;
  top: 0px;
}

.title {
  overflow: hidden;
  color: var(--, #2D2C46);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 350px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
}

.date {
  color: #7E7E7E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
  margin-right: 12px;
  margin-left: 8px;
}

.phone {
  color: #2D2C46;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
  margin-left: 8px;
}


.type {
  color: #878787;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
  margin-left: 8px;
}

.responsible {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  width: available;
  padding-right: 8px;
  justify-content: end;

  .responsibleLogo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    object-fit: cover;
  }

  span {
    max-width: 157px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #7E7E7E;
  }
}

