@import "styles/vars.scss";

.root {
	font-weight: bold;
	color: var(--main-black);
}

.type {
	display: flex;
	align-items: center;

	font-family: $ubuntu-font;
	line-height: 20px;
	color: #152242;

	svg {
		height: 20px;
		width: auto;
		margin-right: 5px;
	}
}

.call {
	font-family: $ubuntu-font;
	color: var(--main-black);
	opacity: 0.7;
	font-size: 14px;
	font-weight: 400;
}
