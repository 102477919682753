@import "styles/vars.scss";
@import "styles/keyframes";

.selectWrapper {
	position: relative;

	display: flex;
	align-items: center;

	min-height: 30px;
	width: 100%;
}

.arrowContainer {
	display: flex;
	padding: 0;
	transition: 0.15s;
	background: transparent;
	border: none;
	margin-left: 8px;
	cursor: pointer;

	.iconSmall {
		fill: var(--main-grey-inactive);
		height: 8px;
		width: 8px;
		margin-top: 2px;
	}

	.iconDefault {
		fill: var(--main-black);
	}
}

.arrowContainerDisabled {
	cursor: not-allowed;

	svg {
		opacity: 0.5;
	}
}

.arrowContainerSelectOpen {
	transform: rotate(180deg);
}

.optionsOverflow {
	padding-right: 8px;

	scrollbar-gutter: stable;

	display: flex;
	flex-direction: column;
	row-gap: 8px;

	overflow: hidden auto;
	max-height: 250px;
}

.selectedVariant {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
}

.customOptionWrapper {
	@include flex-align-items-center;
}

.customOptionNotSelected {
	cursor: pointer;

	@include flex-align-items-center;

	color: rgba(45, 44, 70, 0.5);

	font-family: Cera Pro;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;


	&:hover,
	&:hover p {
		color: var(--main-blue)!important;
	}
	}


.customOptionSelected {
	color: var(--main-black);
}

.selectedOptionWrapper {
	width: 100%;

	cursor: pointer;
	display: inline-block;

	user-select: none;
	font-weight: 500;
	font-size: 14px;
	color: var(--main-black);

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.size__small {
	height: 20px;

	.selectedOptionWrapper {
		color: var(--main-blue);
		font-size: 13px;
	}

	.customOptionNotSelected {
		font-size: 13px;
		line-height: 16px;

		&:hover {
			color: var(--main-blue)!important;
		}
	}

	.optionsOverflow {
		row-gap: 4px;
	}

	.selectLoader {
		right: 14px;
	}
}

.selectError {
	.selectedOptionWrapper {
		animation: blinkErrorColor 1s linear 3;
		color: var(--accent-red);
	}

	&.withBorder {
		.selectedVariant {
			animation: blinkErrorBorder 1s linear 3;
			border-bottom: 1px solid var(--accent-red);
		}
	}
}

.optionCheckbox {
	margin-right: 10px;
	pointer-events: none;
}

.error {
	position: absolute;
	bottom: -2px;
	right: 0;

	display: flex;
	justify-content: flex-end;

	height: 0;

	font-family: $ubuntu-font;
	font-weight: 400;
	font-size: 12px;
	color: var(--accent-red);
}

.withBorder {
	.selectedVariant {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}
}

.prepend,
.append {
	user-select: none;
	cursor: pointer;

	display: flex;
}

.prepend {
	margin-right: 8px;
}

.append {
	margin-left: 8px;
}

.disabled {
	cursor: not-allowed;

	.selectedOptionWrapper {
		cursor: not-allowed;
	}
}

.alwayOpenOption {
	position: relative;
	height: 100%;
	width: 100%;
}

.searchInput {
	margin-bottom: 12px;

	display: flex;

	input {
		border: 1px solid rgba(0, 0, 0, 0.3);
	}
}

.selectLoader {
	position: absolute;

	top: 50%;
	right: 24px;
	transform: translateY(-50%);

	svg {
		width: 8px !important;
		height: 8px !important;
	}
}

.highlightSearch {
	font-weight: 700;
}

.placeholder {
	color:  #BFBFC0;
}
