@import "styles/vars.scss";

.root {
	padding: 6px 0;

	@include flex-align-items-center;

	background-color: #fff;
	border-radius: 10px;

	min-height: 100px;
}

.root:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
}

.main {
	padding: 10px 20px;

	@include flex-align-items-center;

	column-gap: 25px;

	width: 100%;
	border-right: 1px solid var(--main-grey-border);
}

.click {
	cursor: pointer;
}

.body {
	font-size: 14px;
}

.title {
	@include flex-align-items-center;
}

.label {
	line-height: 20px;
	font-weight: 700;
	color: var(--main-black);
}

.tag {
	margin-left: 16px;

	background-color: var(--accent-green) !important;
	color: white !important;
	font-weight: 700;
}

.description {
	margin-top: 4px;

	max-width: 362px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	color: var(--main-black);
	font-weight: 400;
}

.bottom {
	margin-top: 4px;

	@include flex-align-items-center;

	color: var(--main-grey-disabled);
}

.date {
	margin-right: 12px;
}

.taskType {
	margin: 0 auto;
	max-width: 166px;
}

.status {
	width: 90px;

	padding: 20px 18px;

	@include flex-align-items-center;
	flex-direction: column;

	& > div {
		&:first-child {
			@include flex-justify-content-center;
		}
	}
}

.timeLeft {
	margin-top: 6px;

	font-size: 12px;
	font-family: $ubuntu-font;
	white-space: nowrap;
	color: var(--main-grey-disabled);
}

.contacts {
	max-width: 250px;
}

.text {
	font-size: 14px;
	color: var(--main-black);
	font-weight: 400;
	line-height: 20px;
}

.underlay {
	background: var(--main-blue-disabled);
}
