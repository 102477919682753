@import "styles/vars.scss";

.root {
  width: 720px;
  height: 100px;
  border-radius: 10px;
  background: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mbDesktopBreakpoint {
    max-width: 440px;
    max-height: 80px;
  }
}
.text {
  color: #BFBFC0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.2px;
}

