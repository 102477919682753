.template {
  min-width: 150px;
  max-width: 285px;
  height: 72px;
  padding: 30px 19px;
  margin: -27px -23px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  background: #F2F2F2;
  border-radius: 10px;
  height: 57px;
  display: flex;
  align-items: center;

  justify-content: center;
  min-width: 108px;
  max-width: 237px;
}

.row {
  display: flex;
}

.text {
  width: 235px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.number {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
}

.numberMain {
  font-family: Cera Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #BFBFC0;
}