@import "styles/vars.scss";

.container {
	padding-top: 47px;

	.smallHeader {
		margin: 0;
		color: var(--main-black);
		font-weight: normal;
		font-size: 14px;
		opacity: 0.5;
		font-family: $ubuntu-font;
	}
}

.table {
	width: calc(100% + 80px);
	position: relative;
}

.table::after {
	content: "";
	width: 25px;
	height: 100%;
	position: absolute;
	right: -1px;
	top: 0;
	background: rgb(255, 255, 255);
	background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(242, 245, 251, 1) 100%);
}
.requestBlock {
	margin-top: 60px;

	h2 {
		margin-bottom: 5px;
		font-family: $default-font;
		color: var(--main-black);
		font-weight: 300;
		font-size: 20px;
		opacity: 1;
	}
}
