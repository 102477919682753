.filters {
	display: flex;
	width: 100%;
	flex-direction: column;

	&:after {
		content: "";
		display: flex;
		min-height: 1px;
		width: 100%;
	}
}

.titleGroup {
	margin: 0px 0px 20px 0px;
}
