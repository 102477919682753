@import "styles/vars.scss";

.root {
  position: relative;
}

.footer {
  width: 800px;
  height: 64px;
  display: flex;
  padding: 0 40px 0 40px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  box-shadow: 0px 0px 30px 0px #2224281A;
  background: #FFFFFF;

  @include mbDesktopBreakpoint {
    width: 500px!important;
    height: 84px!important;
  }
}

.content {
  min-height: calc(100% - 64px);
  position: relative;
  padding: 28px 40px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  //height: 100vh;
  //overflow-y: scroll;
}

.title {
  font-family: Cera Pro;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 40px;
}

.subtitle {
  font-family: Cera Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.text {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.warningText {
  font-family: Ubuntu;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

}

.row {
  display: flex;
  align-items: center;
  gap: 4px;
}