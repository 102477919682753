.groupName {
	font-weight: bold;
	font-size: 16px;
	color: #000;
	word-break: break-all;
}

.groupWrapper {
	display: flex;
	flex-direction: column;
	row-gap: 4px;
}
