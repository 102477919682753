@import "styles/vars.scss";

.root {
  margin-bottom: 60px;
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2px;
  text-align: left;
}

.openTrigger {
  font-family: Cera Pro;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 31.2px !important;
  letter-spacing: 0.20000000298023224px !important;
  text-align: left;
  color: #336BEB !important;
}

.clicked {
  max-width: 175px;
  width: fit-content;
}

.arrow {
  margin-left: 10px;
  fill: var(--main-blue) !important;
  transform: rotate(0deg) !important;

  @include mbDesktopBreakpoint() {
    display: none !important;
  }
}


.popupCard {
  width: 115px !important;
  left: -40px !important;
  min-width: unset !important;
  padding: 20px !important;

  & > :first-child {
    margin-bottom: 16px;
  }
}

.actionItem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  width: 50px !important;

  span {
    flex: 10 1;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #8B8B8D;
  }
}

.selected {
  span {
    color: #1E1E1F !important;
    flex: 10 1;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 450;
  }
}

.menu {
  margin-top: 16px;
  margin-bottom: 24px;
  height: 30px;
}

.radio {
  background: #FFFFFF;
}

.content {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.tasks {
  width: 720px;

  @include mbDesktopBreakpoint {
    width: 440px!important;
  }
}

.buttons {
  @include flex-align-items-center;
  justify-content: flex-end;

  margin-top: 8px;

  & > button {
    &:last-child {
      margin-left: 24px;
    }
  }
}