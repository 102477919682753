@import "styles/vars.scss";

.root {
	display: flex;
	align-items: flex-start;
	position: relative;
}

.dateIcon {
	margin-right: 15px;
}

.input {
	input {
		padding-top: 0;
		padding-bottom: 0;
		&:disabled {
			background-color: transparent;
		}
	}
}

.blackVariant {
	input::placeholder {
		color: black;
	}
}

.popupWrapper {
	width: unset !important;
}
