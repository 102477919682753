@import "styles/vars.scss";

.root {
	padding: 16px 40px;
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	font-size: 14px;
	line-height: 17px;
	border-radius: 10px;
	font-family: "Ubuntu", sans-serif;

	svg {
		position: absolute;

		width: 20px;
		height: 20px;
	}
}

.cardBackround__light {
	background-color: #f5f5f5;
	color: #000;
}

.cardBackround__dark {
	background-color: var(--main-grey-inactive);
	color: #fff;
}

.iconColor__grey,
.iconColor__grey path {
	fill: #c5c5cc;
}

.iconColor__red,
.iconColor__red path {
	fill: var(--accent-red);
}

.position__top-left {
	top: 4px;
	left: 4px;
}

.position__top-right {
	top: 4px;
	right: 4px;
}

.position__bottom-left {
	bottom: 4px;
	left: 4px;
}

.position__bottom-right {
	bottom: 4px;
	right: 4px;
}

.button {
	margin-top: 8px;
	padding: 0;

	font-family: $ubuntu-font;
	font-size: 14px;
	line-height: 20px;
	color: var(--main-blue);

	border: none;
	border-bottom: 1px dashed var(--main-blue);
	background: none;
	cursor: pointer;
}
