@import "styles/vars.scss";

.modal {
  position: absolute;
  bottom: 0;
  width: 500px!important;
  padding: 10px 10px 0px 10px!important;
  border-radius: 20px 20px 0px 0px!important;
}

.modalClose {
  background: #1E1E1F!important;
  border: 0!important;
  svg {
    path {
      fill: #FFFFFF!important;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
}

.title {
  font-family: Cera Pro;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.rowEnd {
  display: flex;
  justify-content: end;

  @include mbDesktopBreakpoint {
    margin-bottom: 20px;
  }
}