.ck {
	&.ck-toolbar {
		padding-left: 0;
		& .ck-toolbar__items {
			& > button.ck-button {
				margin-left: 12px;
				margin-right: 12px;
				&:nth-child(3) {
					margin-left: 16px;
				}
				&:last-child {
					margin-left: 0;
				}
				& svg {
					font-size: 12px;
				}
			}
		}
	}

	&.ck-editor__main {
		border: 1px solid var(--ck-color-base-border);
		border-radius: var(--ck-border-radius);
		border-top-right-radius: 0;
		border-top-left-radius: 0;

	}
	&.ck-content {
		&.ck-editor__editable {
			max-height: 264px;
			border: 0;
			margin: 4px 7px 4px 0px;

		}
		&.ck-focused {
	        border: 0!important;
			box-shadow: none !important;
		}
	}
	&.ck-button {
		border-radius: 4px !important;
		&.ck-off {
			& > .ck-button__icon:hover {
				color: var(--main-blue);
			}
		}
		&.ck-on {
			& > .ck-button__icon:hover {
				color: #fff;
			}
		}
		&__icon {
			cursor: pointer;
			transition: 0.2s;
		}
	}
}

.ck.ck-button.ck-on {
	background-color: var(--main-blue);
	color: white;
}


