@import "styles/vars.scss";

.objectItemWrapper {
	margin-bottom: 12px;
}

.objectsGroupItem {
	margin-bottom: 12px;
}

.skeleton {
	display: flex;
	flex-wrap: nowrap;

	width: 100%;
	height: 100px;

	margin-bottom: 10px;

	@include skeleton();
}

.list {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.empty {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 720px;
	height: 100px;
	border-radius: 4px;
	background: #FFFFFF;
	color: #BFBFC0;

	@include mbDesktopBreakpoint {
		width: 440px !important;
		height: 96px !important;
	}
}
