@import "styles/vars.scss";

.root {
	margin-right: -30px;

	display: flex;
	flex-wrap: wrap;
}

.object {
	margin-right: 30px;
	margin-bottom: 40px;

	position: relative;
}

.close {
	padding: 9px;

	position: absolute;
	top: -9px;
	right: -9px;

	@include flex-align-items-center;
	justify-content: center;

	opacity: 0;
	border-radius: 100px;
	cursor: pointer;
	background: var(--accent-red);

	svg {
		fill: white;
		width: 14px;
		height: 14px;
	}

	transition: opacity $default-transition-duration;

	&:hover {
		opacity: 1;
	}
}
