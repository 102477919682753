@import "styles/vars.scss";

.root {
	padding: 20px 26px 20px 30px;
	height: 100%;
	overflow: auto;
	background: var(--BW-5, #FFF);

	h3 {
		margin: 0;

		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: var(--main-black);

		span {
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			opacity: 0.5;
		}
	}
}

.noFreeLeads {
	font-family: $ubuntu-font;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: var(--main-grey-disabled);
}

.loader {
	position: relative;
	display: inline;
	left: 18px;

	svg {
		height: 25px !important;
	}
}

.row {
	padding: 0!important;
	height: 96px!important;

	>:hover {
		box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
	}
}

.col {
	border-radius: 10px;
}
