.root {
	position: relative;

	.title {
		color: var(--main-black);

		margin: 0px 0px 20px 0px;
	}

	&.mediumBottom {
		margin-bottom: 28px;
	}

	&.mediumBottomAndRight {
		margin-bottom: 28px;
		margin-right: 22px;
	}

	&.bigBottom {
		margin-bottom: 40px;
	}

	&.bigBottomAndRight {
		margin-bottom: 40px;
		margin-right: 10px;
	}


}
