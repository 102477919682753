@import "styles/vars.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 10px;
}

.text {
  display: flex;
  align-items: center;
}

.text svg {
  margin-right: 8px;
}

.wrap {
  background: #F2F2F2;
  border-radius: 20px 20px 0 0;
  gap: 10px;
  padding: 8px;
}

h1 {
  color: #2D2C46;
  font-family: Cera Pro;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 138.462% */
  letter-spacing: 0.2px;
}

.headerIcon {
  width: 30%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #FFF;
}

.gray svg {
  fill: #BFC4DF;
}

.gray path {
  fill: #BFC4DF;
}

.green path {
  fill: #21BC5F;
}

.violet path {
  fill: #7F8DEF
}

.link {
  color: #7F8DEF !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  margin: 0 !important;
  padding: 4px 0 !important;
}

.red {
  color: #FF575F !important;
  margin: 0 !important;
  padding: 4px 0 !important;
}

.statusBlock {
  width: 70%;
  padding: 16px 20px;
  border-radius: 4px;
  background: #FFF;
  display: flex;
  justify-content: flex-start;
  position: relative;

  @include mbDesktopBreakpoint {
    width: 100% !important;
  }
}

.sentSMS {
  color: #FF9052;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.terminationRequested {
  color: #FF575F;
  font-family: "Cera Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.popup {
  position: absolute;
  right: 22px;
  top: 12px;
}

.popupCard {
  width: 300px !important;
  top: 5px !important;
  padding: 16px 20px !important;
}

.statusBlock .statusText {
  color: #2D2C46;
  font-family: Cera Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.2px;
  padding-right: 8px;
}

.statusBlock .date {
  color: #2D2C46;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.rightBlock {
  display: flex;
  flex-direction: column;
}

.dropDown {
  display: flex;
  gap: 8px;
}

.rotate svg {
  transform: rotate(180deg);
}

.dropDown p {
  color: #2D2C46;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.2px;
}

.date p {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.icon {
  margin-right: 8px;
}

.description {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  opacity: 0.5;
}

.descLabel {
  color: #2D2C46;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  margin-left: 4px;
}

.row {
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
}