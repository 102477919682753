@import "styles/vars.scss";

.root {
	position: relative;

	padding-left: $sidebar-closed-width;
	display: flex;
	flex-direction: column;
}

.expanded.root {
	padding-left: $sidebar-opened-width;
}

.filterLine {
	display: flex;

	height: 50px;

	background: #fff;
	border-bottom: 1px solid var(--main-blue-inactive);
}

.secondLine {
	display: flex;
}

.filterBtn {
	color: var(--main-black);
	display: flex;
	align-items: center;

	svg {
		width: 25px !important;
		height: 25px !important;
		margin-right: 14px !important;
	}

	path {
		fill: var(--main-black) !important;
	}

	.filterBtnText {
		color: var(--main-blue);
		font-size: 12px;
	}
}

.contactFilter {
	&::placeholder {
		color: var(--main-black);
	}
}

.submitBtn {
	position: absolute !important;

	bottom: -20px;
	right: 250px;

	transform: translateY(100%);

	width: 200px;
	height: 50px;

	border-radius: 0 !important;
	box-shadow: 0 10px 30px rgba(0, 64, 229, 0.25);
}

.checkbox {
	width: 160px;
}

.checkboxLabel {
	opacity: 1;
}

.subtype {
	width: 325px;
}

.sourceWrapper {
	width: 300px;

	z-index: 1;
}

.filtersWrapper {
	display: flex;
	height: 30px;
}

.resetBtn {
	position: absolute !important;
	bottom: 0;

	transform: translateY(100%);

	width: fit-content;
	height: 30px;

	background: white !important;
	border-radius: 0px !important;
	padding: 0px 24px !important;

	border-bottom: 1px solid var(--main-blue-inactive) !important;
	border-right: 1px solid var(--main-blue-inactive) !important;

	span {
		opacity: 0.3;
	}
}

.cancelIcon {
	width: 36px !important;
	height: 36px !important;

	path {
		fill: var(--main-blue) !important;
	}
}

.resetBtn:hover {
	color: var(--accent-red) !important;

	span {
		opacity: 1;
	}

	.cancelIcon {
		path {
			fill: var(--accent-red) !important;
		}
	}
}


.filterWrapper {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 300px;
	padding-right: 20px;
	padding-left: 17px;
	border-right: 1px solid var(--main-blue-inactive);
}