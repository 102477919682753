.placeholder {
	&::placeholder {
		font-family: Cera Pro !important;
		font-style: normal !important;
		font-weight: 500 !important;

		color: var(--main-blue-secondary) !important;
	}
}

.row {
	height: 20px;
}
