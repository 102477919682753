.root {
	margin: 0;
	padding: 0 0 0 20px;

	border-left: 1px solid rgba(var(--main-black-rgb), 0.2);
}

.item {
	margin-bottom: 8px;

	position: relative;
	list-style-type: none;
	font-weight: 400;
	font-size: 14px;
	cursor: pointer;

	&:last-child {
		margin-bottom: 0;
	}

	&.active {
		font-weight: 700;

		&:before {
			content: "";

			height: 100%;
			width: 5px;

			top: 0;
			left: -23px;
			position: absolute;

			border-left: 5px solid var(--main-grey-inactive);
		}
	}

	& > span {
		&:last-child {
			color: var(--main-grey-sub);
			margin-left: 12px;
		}
	}
}
