@import "styles/vars.scss";

.root {
  display: flex;
  flex-flow: column;
}

.title {
  font-family: Cera Pro;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #2D2C46;

}

.description {
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 20px;
}

.callsWrapper {
  width: 720px !important;
  padding: 20px 20px 20px 20px;
  margin-left: -20px;

  @include mbDesktopBreakpoint {
    width: 480px !important;
  }
}


.callsSeparator {
  color: #2D2C46;
  text-align: start;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: 0.2px;
  border: 0 !important;
}

.tableWithPagination {
  width: 100%;
  padding-left: 20px;
  flex-direction: column;
}

.chatCard {
  margin-top: 20px;
  width: 720px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mbDesktopBreakpoint {
    width: unset !important;
  }
}

.rowEnd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  position: absolute;
  width: 100%;
  height: 64px;
  left: 0;
  bottom: 0;
  padding-right: 40px;
  background: white;
  border-radius: 0 0 20px 20px;
  box-shadow: (0px 10px 30px rgba(0, 64, 229, 0.25));

  @include mbDesktopBreakpoint {
    bottom: -36px !important;
    border-radius: 0 !important;
  }
}

.tableRow {
  box-shadow: 0 0 30px 0 #2224281A;
  background: #FFFFFF !important;
  width: 440px !important;
}


.modal {
  position: absolute;
  bottom: 26px;
  width: 500px !important;
  padding: 10px 10px 0px 10px !important;
  border-radius: 20px 20px 0px 0px !important;
  overflow-y: unset !important;
}

.modalClose {
  background: #1E1E1F !important;
  border: 0 !important;

  svg {
    left: -3px !important;

    path {
      fill: #FFFFFF !important;
    }
  }
}

.wrapper {
  overflow-y: unset !important;
}