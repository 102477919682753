@import "styles/vars.scss";

.partnersInfo {
	margin-top: 24px;
	background: #F2F2F2!important;
}

.partnersInfoHeader {
	padding: 24px 32px 0;
}

.header {
	h3 {
		margin: 0;
	}
}

.small {
	& > div {
		&:first-child {
			padding: 20px 28px 0;
		}
		&:last-child {
			padding: 16px 22px 22px;
		}
	}
}
