@import "styles/vars.scss";

.map-handlers {
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 10px 9px 17px rgba(0, 0, 0, 0.2);
}

.map-handlers-not-premium {
	position: absolute;
	bottom: 50px;
	width: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: #fff;
	box-shadow: 10px 9px 17px rgba(0, 0, 0, 0.2);
}

.select {
	top: -70px!important;
}

.map-handlers__draw {
	position: relative;
	padding: 11px 100px 11px 18px;
	width: 100%;
	transition: all 0.25s ease;

	&::after {
		position: absolute;
		top: 1px;
		right: 0;
		bottom: 3px;
		width: 1px;
		background-color: #e5e5e5;
		content: "";
	}

	@include mbDesktopBreakpoint {
		background: #336BEB;
		position: absolute;
		width: 50px!important;
		height: 50px!important;
		padding: 11px 11px 11px 11px!important;
		bottom: 100px;
		left: 20px;

		.map-handlers__count {
			display: none;
		}

		.map-handlers__button-text {
			display: none;
			width: 0!important;
		}

		button {
			width: 24px;
			height: 24px;
		}

		svg {

			path {
				fill: #FFFFFF;
			}
		}
	}

	&.active {
		background-color: var(--main-blue-disabled);
	}
}

.map-handlers__count {
	position: absolute;
	top: 50%;
	right: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	padding-right: 7px;
	padding-left: 15px;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	background-color: #336BEB;
	border-radius: 100px;
	transform: translateY(-50%);
	cursor: pointer;
	transition: all 0.25s ease;

	g {
		transition: all 0.25s ease;
	}

	&:hover {
		background-color: #9da9ff;

		g {
			opacity: 1;
		}
	}
}

.map-handlers__remove {
	margin-bottom: 1px;
}

.map-handlers__remove,
.map-handlers__button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: transparent;
	width: 100%;
	border: none;
	outline: none;
	cursor: pointer;
}

.map-handlers__button {
	cursor: pointer;
	transition: all 0.25s ease;

	path {
		transition: all 0.25s ease;
	}

	&:hover {
		path {
			fill: var(--main-blue);
		}

		.map-handlers__button-text {
			color: var(--main-blue);
		}

		@include mbDesktopBreakpoint {
			path {
				fill: #FFFFFF!important;
			}
		}
	}
}

.map-handlers__button-text {
	margin-left: 17px;
	color: #000;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	transition: all 0.25s ease;
}

.map-handlers__select {
	padding: 16px 24px;
	width: 100%;

	@include mbDesktopBreakpoint {
		display: none;
	}
}

.row-map-buttons {
	display: flex;
	gap: 32px;
	align-items: center;
	position: absolute;
	background: white;
	bottom: 0;
	width: 500px;
	height: 64px;
	justify-content: end;
	padding-right: 20px;
	box-shadow: 0px 0px 30px 0px #2224281A;

	@include mbDesktopBreakpoint {
		height: 84px!important;
	}

}

.row-map-cancel {
	display: flex;
	align-items: center;
	gap: 4px;
	color: #FF575F;
	font-family: Ubuntu;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.20000000298023224px;
	text-align: left;

	svg {
		path {
			fill: #FF575F;
		}
	}
}
