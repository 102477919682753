@import "styles/vars.scss";

.root {
  display: flex;
  flex-direction: row;

  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}

.rootFull {
  min-height: 100vh !important;
  max-height: 100vh !important;
}

.rootAlert {
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
}

.listWrapper {
  padding-bottom: 40px;
  overflow: auto;

  flex: 6 6 100px;

  @include lgDesktopBreakpoint() {
    flex: 8 8 100px;
  }
}

.return {
  display: flex;
  gap: 8px;
  font-family: Cera Pro;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #336BEB;
  cursor: pointer;
  margin-bottom: 32px;

  svg {
    fill: #336BEB !important;
    transform: rotate(90deg);
  }
}

.total {
  font-family: Cera Pro;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

  margin-bottom: 20px;
}

.isMobileWrapper {
  overflow-x: hidden;
}

.list {
  position: relative;
  margin: 100px auto 0 auto;

  max-width: 746px;
}

.listAtCompilation {
  margin-top: 150px !important;
}

.isMobile {
  max-width: 440px;
  margin: 40px auto 0 auto !important;
}

.listWithNoFilters {
  margin: 100px auto 0 auto;

  @include mbDesktopBreakpoint {
    margin: 40px auto 0 auto !important;
  }
}

.map {
  width: 500px;
}

.filters {
  padding: 15px 15px 15px 0;
  background: #fff;
  margin-left: 235px;

  @include mdDesktopBreakpoint() {
    margin-left: 85px;
  }
}

.filtersModal {
  overflow: hidden;
}

.filtersShorter {
  margin-right: 134px;
}

.pagination {
  justify-content: center;
  margin-top: 36px;
}
