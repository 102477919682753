@import "styles/vars.scss";

.root {
	padding: 12px 20px;
	margin-bottom: 8px;

	width: 100%;
	max-height: 140px;

	background: var(--main-blue-disabled);
	border-radius: 10px;
}

.root:hover {
	filter: drop-shadow(0px 10px 30px rgba(0, 64, 229, 0.25));
	background: #fff;
	cursor: pointer;
}

.title {
	font-weight: bold;
	line-height: 24px;
}

.description {
	font-size: 14px;
	opacity: 0.5;
}

.user {
	margin: 8px 0px;
}

.created {
	font-size: 12px;
	opacity: 0.5;
	line-height: 16px;
}

.phone {
	font-family: $ubuntu-font;
}
