.metroInputWrapper {
	display: flex;
	align-items: center;
}

.metroInput {
	width: 27px;
	margin-right: 15px;
}

.metroWrapper {
	padding-top: 10px;
}
