@import "styles/vars.scss";

.root {
  width: fit-content;
  margin: 32px auto;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 720px;
  height: 100px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #BFBFC0;

  @include mbDesktopBreakpoint {
    width: 440px !important;
    height: 96px !important;
  }
}
